import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { Service, ServiceGrouping } from 'src/app/shared/model/service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import ServiceData from 'src/assets/ocd-services.json';

@Component({
  selector: 'app-ocd-services',
  templateUrl: './ocd-services.component.html',
  styleUrls: ['./ocd-services.component.scss']
})
export class OCDServicesComponent implements OnInit {

  public serviceGroupings: ServiceGrouping[];

  public allServices = true;
  public noService = false;

  constructor(
    private viewportScroller: ViewportScroller,
    private userActivity: UserActivityService
  ) { }

  ngOnInit(): void {

    // log activity
    this.userActivity.logActivity(TYPES.services, PAGES.orangeCyberdefenseServices, ACTIONS.visit);

    this.serviceGroupings = ServiceData;
    this.serviceGroupings.forEach(group => {
      // define id for anchor
      group.id = group.categoryName.toLocaleLowerCase().replaceAll(' ', '_');
      // by default, all services are displayed
      group.active = true;
    });
  }

  public openService(service: Service) {
    window.open(service.learnMore,'_blank');
  }

  public selectedAllServices() {
    this.allServices = !this.allServices;

    //update displayed services
    this.serviceGroupings.forEach(group => group.active = this.allServices);

    // update noService value
    this.noService = !this.allServices;
  }

  public goToAnchor(name) {
    this.viewportScroller.scrollToAnchor(name);
  }

  public filterServices(service) {
    // update displayed service
    service.active = !service.active;
    const activesServices = this.serviceGroupings.filter(group => group.active).length;
    this.allServices = activesServices === this.serviceGroupings.length;
    this.noService = activesServices === 0;
    if(service.active) {
      // wait list is displayed then scroll to it
      setTimeout(() => {
        this.goToAnchor(service.id);
      });
    }
  }

}
