<div class="row justify-content-center p-5" *ngIf="loadingSignal">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
</div>

<div class="row p-2" *ngIf="!isWorldWatchUser && !loadingSignal">
    <div class="col-xl-1 col-lg-1 hidden-md"></div>
    <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12">
        <div style="padding: 1rem; border-color: #ff0000 !important; border: 0.125rem solid transparent;">
            <div>
                <h3>{{ 'pages.worldwatch.noAccess.heading' | translate }}</h3>
            </div>
            <div>
                <p [innerHTML]="'pages.worldwatch.noAccess.body' | translate"></p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="advisory">
    <div class="head">
        <span class="title" [ngClass]="{'light-theme': !isDarkTheme}">{{createBlock.title}}</span>
    </div>

    <div class="signal-content">
        <div>
            <ul role="tablist" aria-owns="analysis-tab appendices-tab comments-tab nav-tab4" class="nav nav-tabs"
                id="nav-tab-with-nested-tabs">
                <!-- analysis-->
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" aria-current="page" id="analysis-tab" href="#analysis-content"
                        data-bs-toggle="tab" data-bs-target="#analysis-content" role="tab"
                        aria-controls="analysis-content" aria-selected="true">{{
                        'pages.updates.signal.fieldTitles.analysisTab' | translate }}
                        </a>
                </li>
                <!-- comments -->
                @if(advisory && advisory.contentBlocks.length > 1) {
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="comments-tab" data-bs-toggle="tab" href="#comments-content"
                        data-bs-target="#comments-content" role="tab" aria-controls="comments-content"
                        aria-selected="false">{{ 'pages.updates.signal.fieldTitles.commentsTab' | translate }}
                        <span class="tag text-bg-primary ms-2" style="padding:0rem 0.5rem;"
                        *ngIf="updateBlocks.length > 0">{{updateBlocks.length}}</span></a>
                </li>
                }
                <!-- appendices -->
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="appendices-tab" data-bs-toggle="tab" href="#appendices-content"
                        data-bs-target="#appendices-content" role="tab" aria-controls="appendices-content"
                        aria-selected="false">{{ 'pages.updates.signal.fieldTitles.appendicesTab' | translate }}</a>
                </li>
            </ul>

            <!-- TABS -->
            <div class="tab-content" id="nav-tabs-content">

                <!-- APPENDICES -->
                <div class="tab-pane fade show active" id="analysis-content" role="tabpanel" aria-label="analysis-tab">
                    <div class="preserve-whitespace">

                        <!-- executiveSummary -->
                        @if(createBlock.executiveSummary) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.executiveSummary' | translate }}</label>
                                </div>
                                <div class="preserve-whitespace" [innerHTML]="createBlock.executiveSummary | formatHtml"></div>
                            </div>
                        }

                        <!-- whatYouWillHear -->
                        @if(createBlock.whatYouWillHear) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.whatYouWillHear' | translate }}</label>
                                </div>
                                <div class="preserve-whitespace" [innerHTML]="createBlock.whatYouWillHear | formatHtml"></div>
                            </div>
                        }

                        <!-- whatItMeans -->
                        @if(createBlock.whatItMeans) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.whatItMeans' | translate }}</label>
                                </div>
                                <div class="preserve-whitespace"
                                        [innerHTML]="createBlock.whatItMeans | formatHtml">
                                </div>
                            </div>
                        }
    
                        <!-- whatWeAreDoing -->
                        @if(createBlock.whatWeAreDoing) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.whatWeAreDoing' | translate }}</label>
                                </div>
                                <div class="preserve-whitespace"
                                        [innerHTML]="createBlock.whatWeAreDoing | formatHtml">
                                </div>
                            </div>
                        }

                        <!-- whatYouShouldDo -->
                        @if(createBlock.whatYouShouldDo) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.whatYouShouldDo' | translate }}</label>
                                </div>
                                <div class="preserve-whitespace"
                                        [innerHTML]="createBlock.whatYouShouldDo | formatHtml">
                                </div>
                            </div>
                        }

                        <!-- tags -->
                        @if(createBlock.tags && createBlock.tags.length > 0) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.tags' | translate }}</label>
                                </div>

                                <ul class="list-unstyled m-0 d-flex gap-2 flex-wrap m-0">
                                    @for(tag of createBlock.tags; track tag.index) {
                                    <li><span class="tag">{{tag}}</span></li>
                                    }
                                  </ul>
                                <div>
                                </div>
                            </div>
                        }                        
                    </div>
                </div>

                <!-- COMMNENTS -->
                <div class="tab-pane separator" id="comments-content" role="tabpanel" aria-label="comments-tab">
                    <!-- updates -->
                    <div class="preserve-whitespace ">
                        @for(block of updateBlocks; track block.id) {
                            <div class="block">
                                <div class="header">
                                    <div class="title">{{block.title}}</div>
                                    <div class="details"> {{ block.timestampUpdated | offsetDatetimePipe }} GMT</div>
                                </div>
                                <div class="ms-2">
                                    <div class="content" [innerHTML]="block.executiveSummary"></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <!-- APPENDICES -->
                <div class="tab-pane separator" id="appendices-content" role="tabpanel" aria-label="appendices-tab">
                    <div class="preserve-whitespace">
                        @for(block of advisory.contentBlocks; track block.id) {
                            <div class="block">
                                <div class="header">
                                    <div class="title">{{block.title}}</div>
                                    <div class="details">{{ block.timestampUpdated | offsetDatetimePipe }} GMT</div>
                                </div>

                                <!-- External links -->
                                 @if(getInternalLink(block).length > 0) {
                                     <div class="sources">
                                         <label>Internal Links</label>
                                         @for(source of block.sources; track source.id) {
                                             @if(source.type === internal) {
                                                 <a [href]="source.url">{{source.url}}</a>
                                             }
                                         }
                                     </div>
                                 }
    
                                <!-- External links -->
                                @if(getExternalLink(block).length > 0) {
                                    <div class="sources">
                                        <label>External Links</label>
                                        @for(source of block.sources; track source.id) {
                                            @if(source.type === external) {
                                                <a [href]="source.url">{{source.url}}</a>
                                            }
                                        }
                                    </div>
                                }
    
                                <!-- External links -->
                                @if(block.datalakeUrl) {
                                    <div class="sources">
                                        <label>Datalake</label>
                                        <a [href]="block.datalakeUrl.url">{{block.datalakeUrl.url}}</a>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                
            </div>
        </div>
        <div class="sticky">
            <div class="d-flex">

                <!-- view tickets linked to this advisory -->
                <button [disabled]="!haveTicket" class="btn btn-primary me-4"
                    (click)="viewTickets()">{{
                    'pages.generic.viewTickets' | translate
                    }}</button>

                <!-- log ticket linked to this advisory -->
                <button class="btn btn-primary me-4" [routerLink]="['/support/log/']"
                    [queryParams]="{signalRef: advisory.id}">{{ 'pages.generic.logTicket' | translate
                    }}</button>

                <!-- download advisory -->
                <button type="button" disabled class="btn btn-icon btn-primary" aria-labelledby="closeBtnLabel" (click)="downloadPDF()" ngbTooltip="{{ 'pages.updates.signal.fieldTitles.downloadPDF' | translate }}">
                    <fa-icon [icon]="['fas', 'download']" size="lg" placement="top" data-toggle="tooltip"
                        data-placement="top"></fa-icon>
                </button>
            </div>

            <!-- informations -->
            <div>
                <table class="signal table table-responsive">
                    <caption class="d-none">
                        {{ 'pages.updates.signal.fieldTitles.details' | translate }}
                    </caption>
                    <thead>
                        <tr>
                            <th style="width: 40%;"><p class="visually-hidden">{{ 'pages.generic.title' | translate }}</p></th>
                            <th style="width: 60%;"><p class="visually-hidden">{{ 'pages.generic.content' | translate }}</p></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- id -->
                        <tr>
                            <td>{{ 'pages.updates.signal.fieldTitles.id' | translate }}</td>
                            <td>{{advisory.id}}</td>
                        </tr>

                        <!-- urgency -->
                        <tr>
                            <td>{{ 'pages.updates.signal.fieldTitles.severity' | translate }}</td>
                            <td>
                                <div placement="top">
                                    <div class="sticker" [ngClass]="severityStyle">
                                        <p class="mb-0">
                                            <span class="sticker-fs-xl">{{createBlock.severity}}</span>
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- threatCategory -->
                        @if(advisory.threatCategory) {
                        <tr>
                            <td>{{ 'pages.updates.signal.fieldTitles.threatCategory' | translate }}</td>
                            <td>{{advisory.threatCategory}}</td>
                        </tr>
                        }
                         
                        <!-- created -->
                        @if(advisory.timestampCreated) {
                        <tr>
                            <td>{{ 'pages.updates.signal.fieldTitles.created' | translate }}</td>
                            <td>{{advisory.timestampCreated | offsetDatetimePipe}} GMT</td>
                        </tr>
                        }                         

                        <!-- updated -->
                        @if(advisory.timestampUpdated) {
                        <tr>
                            <td>{{ 'pages.updates.signal.fieldTitles.updated' | translate }}</td>
                            <td>{{advisory.timestampUpdated | offsetDatetimePipe}} GMT</td>
                        </tr>
                        }                         

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>