import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { constants } from 'src/app/shared/constants';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { ChartColors, ChartType, Priorities } from 'src/app/shared/model/activity';
import HPie from 'highcharts/modules/variable-pie';
import { CssColor, UserTheme } from 'src/app/shared/model/shared-items';
import { WidgetService } from 'src/app/shared/services/widget.service';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
HPie(Highcharts);

declare module 'highcharts' {
  export interface PointOptionsObject {
    priorityIdentifier?: string;
  }
}

@Component({
  selector: 'app-recent-support-activity',
  templateUrl: './recent-support-activity.component.html',
  styleUrls: ['./recent-support-activity.component.scss'],
})
export class RecentSupportActivityComponent extends WidgetBaseComponent implements OnInit {

  // default color for bg-white
  fontColor = CssColor.black;
  backgroundColor = CssColor.gray200;
  borderColor = CssColor.gray500;

  loadedOpenCases = false;
  openCases: number;

  loadedCaseStats: boolean;
  customerPending: number;

  chartOptions;

  highcharts: typeof Highcharts = Highcharts;
  catPieChartOptions: Highcharts.Options;

  globalPriorities: {
    globalPriority1: number;
    globalPriority2: number;
    globalPriority3: number;
    globalPriority4: number;
    globalPriority5: number;
  } = {
    globalPriority1: null,
    globalPriority2: null,
    globalPriority3: null,
    globalPriority4: null,
    globalPriority5: null,
  };

  constructor(
    private router: Router,
    private itsmService: ItsmService,
    private alertService: AlertService,
    private widgetService: WidgetService
  ) {
    super();
  }

  ngOnInit(): void {

    this.getOpenedCases();
    this.getCustomerPendingCases();
  }

  /** fetch opened cases */
  getOpenedCases() {
    const globalCriteria = new SupportCaseSearchCriteria();
    globalCriteria.status = constants.itsm.globalStates.open;
    globalCriteria.SNCount = true;
    globalCriteria.byPassSF = true;

    const criticalCriteria = new SupportCaseSearchCriteria();
    criticalCriteria.status = constants.itsm.globalStates.open;
    criticalCriteria.priority = '1 - ' + Priorities.critical;
    criticalCriteria.SNCount = true;
    criticalCriteria.byPassSF = true;

    const highCriteria = new SupportCaseSearchCriteria();
    highCriteria.status = constants.itsm.globalStates.open;
    highCriteria.priority = '2 - ' + Priorities.high;
    highCriteria.SNCount = true;
    highCriteria.byPassSF = true;

    const mediumCriteria = new SupportCaseSearchCriteria();
    mediumCriteria.status = constants.itsm.globalStates.open;
    mediumCriteria.priority = '3 - ' + Priorities.medium;
    mediumCriteria.SNCount = true;
    mediumCriteria.byPassSF = true;

    const lowCriteria = new SupportCaseSearchCriteria();
    lowCriteria.status = constants.itsm.globalStates.open;
    lowCriteria.priority = '4 - ' + Priorities.low;
    lowCriteria.SNCount = true;
    lowCriteria.byPassSF = true;

    const planningCriteria = new SupportCaseSearchCriteria();
    planningCriteria.status = constants.itsm.globalStates.open;
    planningCriteria.priority = '5 - ' + Priorities.planning;
    planningCriteria.SNCount = true;
    planningCriteria.byPassSF = true;

    Promise.all([
      this.itsmService.caseSearch(globalCriteria),
      this.itsmService.caseSearch(criticalCriteria),
      this.itsmService.caseSearch(highCriteria),
      this.itsmService.caseSearch(mediumCriteria),
      this.itsmService.caseSearch(lowCriteria),
      this.itsmService.caseSearch(planningCriteria),
    ])
      .then((res) => {
        this.openCases = res[0];

        this.globalPriorities.globalPriority1 = res[1];
        this.globalPriorities.globalPriority2 = res[2];
        this.globalPriorities.globalPriority3 = res[3];
        this.globalPriorities.globalPriority4 = res[4];
        this.globalPriorities.globalPriority5 = res[5];

        this.drawPriorityTable();
        this.loadedOpenCases = true;
      })
      .catch((err) => {
        delete this.openCases;
        this.loadedOpenCases = true;
        this.alertService.handlerError(err);
      });
  }

  /** fetch customer pending cases */
  getCustomerPendingCases() {
    const pendingCustomerCriteria = new SupportCaseSearchCriteria();
    pendingCustomerCriteria.status = [constants.itsm.states.pendingCustomer];
    pendingCustomerCriteria.SNCount = true;
    pendingCustomerCriteria.byPassSF = true;

    this.itsmService
      .caseSearch(pendingCustomerCriteria)
      .then((res) => {
        this.customerPending = res;
        this.loadedCaseStats = true;
      })
      .catch((err) => {
        delete this.customerPending;
        this.loadedCaseStats = true;
        this.alertService.handlerError(err);
      });
  }

  /** draw Highcharts priority chart */
  drawPriorityTable() {

    // define datas
    let datas = [
      {
        name: Priorities.critical,
        y: this.globalPriorities.globalPriority1,
        priorityIdentifier: '1',
        z: 100,
        color: ChartColors.red,
      },
      {
        name: Priorities.high,
        y: this.globalPriorities.globalPriority2,
        priorityIdentifier: '2',
        z: 80,
        color: ChartColors.orange,
      },
      {
        name: Priorities.medium,
        y: this.globalPriorities.globalPriority3,
        priorityIdentifier: '3',
        z: 60,
        color: ChartColors.yellow,
      },
      {
        name: Priorities.low,
        y: this.globalPriorities.globalPriority4,
        priorityIdentifier: '4',
        z: 40,
        color: ChartColors.blue,
      },
      {
        name: Priorities.planning,
        y: this.globalPriorities.globalPriority5,
        priorityIdentifier: '5',
        z: 20,
        color: ChartColors.green,
      },
    ];

    datas = datas.filter(data => data.y !== 0);

    // define legend
    const legend: Highcharts.LegendOptions = {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'bottom',
      itemMarginTop: 10,
      itemMarginBottom: 10,
      className: 'legend',
      itemStyle: {
        fontWeight: 'bold',
        fontSize: '18px',
        textOverflow: 'clip',
      },
      itemHoverStyle: {
        fontWeight: 'bold',
        color: '#FF7900',
      },
    };

    // define series
    const series = [
      {
        minPointSize: 10,
        innerSize: '60%',
        zMin: 0,
        showInLegend: true,
        name: 'cases',
        borderRadius: 5,
        cursor: 'pointer',
        size: '75%',
        data: datas,
      },
    ];

    // define point click function
    const pointClick: Highcharts.SeriesClickCallbackFunction = (event) => {
      const po = event.point.options;
      this.router.navigateByUrl('/support/dashboard/' + po.priorityIdentifier);
    };

    // define plot options
    const plotOptions = {
      variablepie: {
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          style: { fontSize: '15px' }
        },
        size: '100%',
      },
      series: {
        cursor: 'pointer',
        events: {
          click: pointClick,
        },
      },
    };

    // build chart
    this.chartOptions = {
      chart: {
        type: ChartType.variablePie,
        className: 'variablePie',
        borderRadius: '20px',
        height: '50%',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name} : <b>{point.y} </b><br/>',
      },
      legend,
      plotOptions,
      series,
    };

    this.widgetService.updateWidgetStyle(this.chartOptions, this.theme);
  }
}
