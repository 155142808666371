import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import { graphconstants } from '../../graphconstants';
import { DataPoint, PieFormat, SecurityCaseActivity } from '../../chartModels';
import { Case } from 'src/app/shared/model/itsm';
import { Utilities } from 'src/app/shared/utilities';
import { UserTheme } from 'src/app/shared/model/shared-items';
import { WidgetService } from 'src/app/shared/services/widget.service';


@Component({
  selector: 'app-report-base-chart',
  templateUrl: './report-base-chart.component.html',
  styleUrls: ['./report-base-chart.component.scss']
})
export class ReportBaseChartComponent implements OnInit, OnChanges {

  @Input() width = '100%';
  @Input() height = '500px';
  @Input() data!: Case[];
  @Input() monthIndex!: number;
  @Input() widgetFormat: boolean;
  @Input() theme: UserTheme;
  @Output() showData: EventEmitter<ShowDataParameters> = new EventEmitter();

  public caseMap: Map<string, SecurityCaseActivity>;
  // defines specific child chart
  public chartName: string;
  public chartsDesc: ChartDesc[] = [];
  public attributeName: string;
  public secondaryAttribute: string;
  public isTruePositive: boolean;
  public chartViews = ChartView;
  public selectedView: ChartView = ChartView.ring;
  public multipleViews = false;
  public title: string;
  public helpTip: string;
  public parseCaseContentOverload: (caseContent: Case) => void;

  public updateDonutFlag = false;
  public highcharts: typeof Highcharts = Highcharts;
  public donutChartOptions = JSON.parse(JSON.stringify(graphconstants.pieChartOption));
  public barChartOptions = JSON.parse(JSON.stringify(graphconstants.columnChartOptions));

  private items = [];
  private allChartsOptions = [this.donutChartOptions, this.barChartOptions];
  private commonTranslatePath = 'pages.securitycase';

  constructor(
    public widgetService: WidgetService
  ) {
    if (this.chartsDesc.length > 1) {
      this.multipleViews = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.monthIndex) {
      this.loadData();
    }
  }

  ngOnInit() {
    // setup chart's title and helpTip
    this.buildTitle();
    this.buildHelpTip();

    // setup charts drilldowwn
    this.chartsDesc.forEach( (chartDesc) => {

      const chartSeries = {
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.showData.emit({
              series: chartDesc.series ?? chartDesc.attrName,
              seriesName: event.point.name,
              posi: this.isTruePositive
            });
          }
        }
      };

      if (chartDesc.type === ChartView.ring) {
        this.donutChartOptions.plotOptions.series = chartSeries;
        this.widgetService.updateWidgetStyle(this.donutChartOptions, this.theme);
      } else if ([ChartView.bar, ChartView.assetBar].includes(chartDesc.type)) {
        this.barChartOptions.plotOptions.series = chartSeries;
        this.widgetService.updateWidgetStyle(this.barChartOptions, this.theme);
      }
    });
  }

  buildTitle() {
    this.title = `${this.commonTranslatePath}.${this.chartName}Desc`;
  }

  buildHelpTip() {
    this.helpTip = `${this.commonTranslatePath}.${this.chartName}DescD`;
  }

  changeView(view: string) {
    this.selectedView = view as ChartView;
  }

  /** Build graph data from data input */
  loadData() {
    if (this.data && this.data.length > 0) {
      this.caseMap = new Map();
      this.data.forEach(c => {
        if (this.parseCaseContentOverload) {
          this.parseCaseContentOverload(c);
        } else {
          this.parseCaseContent(c);
        }
      });
      const attr = this.secondaryAttribute ?? this.attributeName;
      this.caseMap = new Map([...this.caseMap.entries()].reverse());
      this.caseMap.forEach(cm => {
        cm[attr].forEach((k, y) => {
          if (!this.items.includes(y)) {
            this.items.push(y);
          }
        });
        this.items.sort();
      });
      this.formatChartsData(this.monthIndex);
    }
  }

  /** Feed caseMap attribute with parsed data from data attribute */
  parseCaseContent(caseContent: Case) {
    const cd = new Date(caseContent.createdDate);
    const actualMonth = cd.getMonth() + 1;
    const dateStr = cd.getFullYear() + '-' + actualMonth;

    if (!this.caseMap.get(dateStr)) {
      this.caseMap.set(dateStr, new SecurityCaseActivity(dateStr));
    }

    if (Utilities.checkTruePositive(caseContent.caseNumber, caseContent.status, caseContent.closureCode)) {
      // case is truePositive
      const caseM = this.caseMap.get(dateStr);
      caseM.truePositives.push(caseContent);

      if (caseContent[this.attributeName]) {
        const attrM = this.caseMap.get(dateStr);
        if (attrM[this.attributeName].get(caseContent[this.attributeName])) {
          attrM[this.attributeName].set(
            caseContent[this.attributeName],
            attrM[this.attributeName].get(caseContent[this.attributeName]) + 1
          );
        } else {
          attrM[this.attributeName].set(caseContent[this.attributeName], 1);
        }
        this.caseMap.set(dateStr, attrM);
      }
    }

    if (Utilities.checkFalsePositive(caseContent.status, caseContent.closureCode)) {
      // case is falsePositive
      const caseM = this.caseMap.get(dateStr);
      caseM.falsePositives.push(caseContent);
      if (caseContent[this.attributeName]) {
        const attrM = this.caseMap.get(dateStr);
        if (attrM[this.attributeName].get(caseContent[this.attributeName])) {
          attrM[this.attributeName].set(
            caseContent[this.attributeName],
            attrM[this.attributeName].get(caseContent[this.attributeName]) + 1
          );
        } else {
          attrM[this.attributeName].set(caseContent[this.attributeName], 1);
        }
        this.caseMap.set(dateStr, attrM);
      }
    }
  }

  /** format all pie charts */
  formatChartsData(monthOffset: number) {
    const latestMonth = Array.from(this.caseMap)[monthOffset][1];
    this.chartsDesc.forEach( (chartDesc, index) => {
      if (chartDesc.type === ChartView.ring) {
        this.formatPieData(this.allChartsOptions[index], latestMonth[chartDesc.attrName], chartDesc.attrName, this.items);
      } else if (chartDesc.type === ChartView.bar) {
        this.formatColumnData(this.allChartsOptions[index], latestMonth[chartDesc.attrName], chartDesc.attrName, this.items);
      } else if (chartDesc.type === ChartView.assetBar) {
        const attr = this.secondaryAttribute ?? this.attributeName;
        this.formatAssetColumnData(
          this.allChartsOptions[index],
          latestMonth[attr],
          chartDesc.attrName,
          this.items,
          chartDesc.initialCategoryList ?? []
        );
      }
    });
    this.updateDonutFlag = true;
  }

  parseChartValues(
    allValues: string[],
    objectMap: Map<string, number>,
    totalCount: number,
    categories: object = {},
    categoriesList = []): [object, number] {
      allValues.forEach((cat) => {
        if (cat.split(',').length === 1) {
          if (categoriesList.indexOf(cat) === -1) {
            categoriesList.push(cat);
            categories[cat] = 0;
          }
        } else {
          const tempCat = cat.split(',');
          tempCat.forEach((item) => {
            if (categoriesList.indexOf(item) === -1) {
              categoriesList.push(item);
              categories[item] = 0;
            }
          });
        }
      });
      allValues.forEach(v => {
        const keys = v.split(',');
        keys.forEach((key, index) => {
          if (objectMap.get(v)) {
            categories[key] += objectMap.get(v);
            if (index === 0) {
              totalCount += objectMap.get(v);
            }
          }
        });
      });
      return [categories, totalCount];
  }

  /** Construct data for a pie chart for a given datapoint */
  formatPieData(
    donutChartOptions: PieFormat,
    objectMap: Map<string, number>,
    objectType: string,
    allValues: string[],
    totalCountArg?: number) {
      let totalCount = 0;
      let dataItems = [];
      let categories = {};
      [categories, totalCount] = this.parseChartValues(allValues, objectMap, totalCount);
      const pieSeries = new DataPoint();

      pieSeries.type = 'pie';
      pieSeries.innerSize = '50%';
      pieSeries.name = objectType;

      dataItems = Object.entries(categories).map(([name, cases]) => ({
        name, cases, y: +((Number(cases) / (totalCount)) * 100).toFixed(1)
      }));
      pieSeries.data = dataItems;
      const series = [];
      series.push(pieSeries);
      donutChartOptions.series = series;
      donutChartOptions.title.text = '<strong>' + (totalCountArg ? totalCountArg : totalCount) + '<br/>cases</strong>';
  };

  /** Construct data for a bar chart for a given datapoint */
  formatColumnData(barChartOptions: PieFormat, objectMap: Map<string, number>, objectType: string, allvalues: string[]) {
    let totalCount = 0;
    let dataItems = [];
    const categories = {
      no_impact: 0,
      confidentiality: 0,
      integrity: 0,
      availability: 0,
    };
    const barSeries = new DataPoint();
    barSeries.innerSize = '50%';
    barSeries.name = objectType;
    barSeries.showInLegend = false;

    allvalues.forEach(v => {
      const keys = v.split(',');
      keys.forEach((key, index) => {
        if (objectMap.get(v)) {
          categories[key] += objectMap.get(v);
          if (index === 0) {
            totalCount += objectMap.get(v);
          }
        }
      });
    });
    dataItems = Object.entries(categories).map(([name, cases]) => ({ name, cases, y: +((cases / totalCount) * 100).toFixed(1) }));

    // set chart data
    barSeries.data = dataItems;
    const series = [];
    series.push(barSeries);
    barChartOptions.series = series;
  };

  /** Construct data for a bar chart for a given datapoint and a given category list */
  formatAssetColumnData(
    barChartOptions: any,
    objectMap: Map<string, number>,
    objectType: string,
    allValues: string[],
    initialCategoryList?: string[],
    orderHighestToLowest = false) {
      let totalCount = 0;
      let dataItems = [];
      let categories = {};
      const categoriesList = initialCategoryList;
      initialCategoryList?.forEach(cat => {
        categories[cat] = 0;
      });
      [categories, totalCount] = this.parseChartValues(allValues, objectMap, totalCount, categories, categoriesList);
      barChartOptions.xAxis.categories = Object.keys(categories);

      const barSeries = new DataPoint();
      barSeries.innerSize = '50%';
      barSeries.name = objectType;
      barSeries.showInLegend = false;
      dataItems = Object.entries(categories).map(([name, cases]) => ({
        name, cases, y: +((Number(cases) / (totalCount)) * 100).toFixed(1)
      }));
      if (orderHighestToLowest) {
        dataItems.sort((i1, i2) => (i2.y - i1.y));
      }

      // set chart data
      barSeries.data = dataItems;
      const series = [];
      series.push(barSeries);
      barChartOptions.series = series;
  };

}

export type ShowDataParameters = {
  series: string;
  seriesName: string;
  posi: boolean;
};

export type ChartDesc = {
  type: ChartView;
  attrName: string;
  series?: string;
  initialCategoryList?: string[];
};

export enum ChartView {
  ring = 'ring',
  bar = 'bar',
  assetBar = 'assetBar'
}

export enum FalsePositiveCategory {
  what = 'what',
  why = 'why',
  who = 'who'
};
