import { Component } from '@angular/core';
import {OktaAuthService} from '../app.service';
import { LocalStorageItem } from '../shared/model/shared-items';

@Component({
  selector: 'app-not-assigned',
  templateUrl: './not-assigned.component.html',
  styleUrls: ['./not-assigned.component.scss']
})
export class NotAssignedComponent {

  constructor(
    private oktaAuthService: OktaAuthService
  ) { }

  logout() {
    this.oktaAuthService.logout();
  }

  /**
   * sometime selectedTenant can be desynchronized when selected tenant has been removed
   * remove seletectedTenant in local storage then reload app
   */
  refreshSession() {
    localStorage.removeItem(LocalStorageItem.oktaSelectedTenant);
    window.location.reload();
  }
}
