import { Injectable } from '@angular/core';
import { PieDrillDownSerie, RepartitionChartItem } from '../shared/model/cybersocxdr/charts/common';
import { COLOR_CRITICAL, COLOR_HIGH, COLOR_LOW, COLOR_MEDIUM } from '../shared/model/cybersocxdr/charts/colors';
import { XtendedFacet, XtendedGroupbyFacet } from '../shared/model/cybersocxdr/facets';
import Highcharts, { PointOptionsObject } from 'highcharts';
import { getDefaultHighchartOptions } from './utils';

@Injectable({
  providedIn: 'root',
})
export class XtendedHighchartService {
  private firstLetterToUpperCase(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  formatDataName(name: string, groupBy: XtendedGroupbyFacet): string {
    if (groupBy === XtendedFacet.OWNER) {
      return this.firstLetterToUpperCase(name) || 'Unassigned';
    } else {
      return this.firstLetterToUpperCase(name);
    }
  }

  formatDataColor(name: string, groupBy: XtendedGroupbyFacet): string | undefined {
    switch (groupBy) {
      case XtendedFacet.OWNER:
        return name ? undefined : '#085EBD';
      case XtendedFacet.SEVERITY:
        return this.severityColor(name);
      default:
        return undefined;
    }
  }

  //for trimap
  drillPointMapper(items: RepartitionChartItem[], groupBy: XtendedGroupbyFacet): PointOptionsObject[] {
    return items
      .map((item, i) => [
        {
          name: this.formatDataName(item.name, groupBy),
          id: item.name,
          y: item.volume,
          value: item.volume,
          color:
            this.formatDataColor(item.name, groupBy) ??
            getDefaultHighchartOptions().colors[i % getDefaultHighchartOptions().colors.length],
        },
        ...(item.detail?.map((subItem) => {
          return {
            parent: item.name,
            name: this.formatDataName(subItem.name, groupBy),
            id: `${item.name}-${subItem.name}`,
            y: subItem.volume,
            value: subItem.volume,
          };
        }) ?? []),
      ])
      .flat();
  }

  pieChartSerie(data: RepartitionChartItem[], groupBy: XtendedGroupbyFacet): Highcharts.SeriesPieOptions {
    return {
      name: groupBy,
      type: 'pie',
      data: data.map((item, i) => {
        return {
          name: this.formatDataName(item.name, groupBy),
          y: item.volume,
          drilldown: item?.detail ? item.name : null,
          color:
            this.formatDataColor(item.name, groupBy) ??
            getDefaultHighchartOptions().colors[i % getDefaultHighchartOptions().colors.length],
        };
      }),
    };
  }

  pieChartDrilldownSerie(data: RepartitionChartItem[]): PieDrillDownSerie[] {
    return data
      .filter((d) => d.detail)
      .map((d) => {
        return { name: d.name, id: d.name, data: d.detail.map((x) => [x.name, x.volume]) };
      });
  }

  severityColor(severity: string): string | undefined {
    switch (severity) {
      case 'critical':
        return COLOR_CRITICAL;
      case 'high':
        return COLOR_HIGH;
      case 'medium':
        return COLOR_MEDIUM;
      case 'low':
        return COLOR_LOW;
      default:
        return undefined;
    }
  }

  severityFontWhite(severity: string): boolean {
    switch (severity) {
      case 'critical':
      case 'high':
        return true;
      default:
        return false;
    }
  }
}
