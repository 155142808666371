/* eslint-disable @typescript-eslint/member-ordering */
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Asset, Case, Contact, Milestone } from '../model/itsm';
import { UpdateCase, UpdateCaseFields } from '../model/persistCriteria';
import { SupportCaseSearchCriteria } from '../model/searchCriteria';
import { Utilities } from '../utilities';
import { constants } from '../constants';
import { HeaderProperties, SessionStorageItem } from '../model/shared-items';
import { TranslateService } from '@ngx-translate/core';
import { Schedule } from '../model/schedule';
import { AlertService } from './alert.service';
import { DataShareService } from './data-share.service';
import { Observable, firstValueFrom } from 'rxjs';
import { QueryStatus } from 'src/app/support/supportlog/filters/asset-and-service/asset-and-service.component';
import { HttpItsmService } from '../httpServices/http-itsm.service';

@Injectable()
export class ItsmService {
    private caseURL = environment.endpoints.itsm + 'case/';
    private caseCreateURL = environment.endpoints.itsm + 'case';
    private caseUpdateURL = environment.endpoints.itsm + 'case';
    private caseUpdateApprovalURL = environment.endpoints.itsm + 'case/';
    private caseSearchURL = environment.endpoints.itsm + 'search';
    private secuityIncidentBenchmarking = environment.endpoints.itsm + 'secib';
    private assetsURL = environment.endpoints.itsm + 'assets';
    private assetsCount = environment.endpoints.itsm + 'assetsCount';
    private assetURL = environment.endpoints.itsm + 'asset/';
    private caseSearchCountURL = environment.endpoints.itsm + 'searchSNCount';
    private caseSNSearchCountURL = environment.endpoints.itsm + 'searchSNCount';
    private contactsURL = environment.endpoints.itsm + 'contacts';
    private deleteContactURL = environment.endpoints.itsm + 'deletecontact';
    private addContactURL = environment.endpoints.itsm + 'contact';     // For Adding Contact
    private editContactURL = environment.endpoints.itsm + 'editcontact';     // For Editing Contact
    private caseCommentURL = environment.endpoints.itsm + 'case/comment';
    private mileStoneURL = environment.endpoints.itsm + 'milestones';
    private services = environment.endpoints.itsm + 'services';
    private servicesCount = environment.endpoints.itsm + 'servicesCount';
    private assetUpdateURL = environment.endpoints.itsm + 'updateci';
    private scheduleReportURL = environment.endpoints.itsm + 'schedule';
    private deleteScheduleURL = environment.endpoints.itsm + 'deleteSchedule';

    datepipe: DatePipe = new DatePipe(constants.languages.enGB);

    private _contacts: Contact[] | null = null;
    public get contacts() { return this._contacts};
    public set contacts(values: Contact[] | null) { this._contacts = values }

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private alertService: AlertService,
        private dataService: DataShareService,
        private httpItsmService: HttpItsmService
    ) { }

    case(caseRef: string): Promise<any>{
        return this.http
            .get(this.caseURL + caseRef)
            .toPromise()
            .then(response => response as any)
            .catch(this.handleError);
    }

    caseComment(commentPostObj: {parentId; commentBody; user; silentUpdate}): Promise<any>{
        return this.http
            .post(this.caseCommentURL, JSON.stringify(commentPostObj))
            .toPromise()
            .then(response => response as Case)
            .catch(this.handleError);
    }

    caseCreate(caseObj: Case): Promise<Case>{
        return this.http
            .post(this.caseCreateURL, JSON.stringify(caseObj))
            .toPromise()
            .then(response => response as Case)
            .catch(this.handleError);
    }

    caseUpdate(caseObj: Case): Promise<Case>{

        const crit = new UpdateCase();
        crit.update = new UpdateCaseFields();
        crit.id = caseObj.id;
        crit.update.subject = caseObj.subject;
        crit.update.description = caseObj.description;
        crit.update.priority = caseObj.priority;
        crit.update.assetId = caseObj.assetId;
        crit.update.contactId = caseObj.contactId;
        crit.update.secondContactId = caseObj.secondContactId;
        crit.update.customerReference = caseObj.customerReference;
        crit.update.worldWatchReference = caseObj.worldWatchReference;

        return this.http
            .patch(this.caseUpdateURL, JSON.stringify(crit))
            .toPromise()
            .then(response => response as Case)
            .catch(this.handleError);
    }

    /**
     * PATCH /case/:caseId/:state
    */
    async updateCaseApproval(ticketId: string, approvalState: string): Promise<Object> {
       const url = this.caseUpdateApprovalURL + ticketId + '/' + approvalState;
       const $update = this.http.patch(url, {
       reportProgress: true,
       responseType: 'json',
       });
       return await firstValueFrom($update);
    }


    caseSearch(searchCriteria: SupportCaseSearchCriteria): Promise<Case[] | any>{
        let query = this.caseSearchURL + '?';
        if(searchCriteria.count){
            query = this.caseSearchCountURL + '?';
        }
        if(searchCriteria.SNCount){
            query = this.caseSNSearchCountURL + '?';
        }

        if(searchCriteria.caseNumber){
            query += 'caseNumber=' + encodeURIComponent(searchCriteria.caseNumber);
        }
        if(searchCriteria.export){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'export=true';
            query += '&reportName=' + searchCriteria.pdfName;
            query += '&pdfAccessLevel=' + searchCriteria.pdfAccessLevel;
            if (searchCriteria.exportColumns) {
                let columns: string = '';
                searchCriteria.exportColumns.forEach( (columnName, index) => {
                    columns += index < searchCriteria.exportColumns.length -1 ? `${columnName},` : columnName;
                });
                query += '&exportColumns=' + columns;
            }
        }
        if(searchCriteria.securityAnaltyics){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'securityAnalytics=true&FromCreatedDate=' + Utilities.get6MonthsAgo();
        }

        if(searchCriteria.securityAnalyt){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'securityAnalytics=true';
        }

        if(searchCriteria.createdFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromCreatedDate=' + encodeURIComponent(searchCriteria.createdFrom);
        }
        if(searchCriteria.createdTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toCreatedDate=' + encodeURIComponent(searchCriteria.createdTo);
        }
        if(searchCriteria.closedDateFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromClosedDate=' + encodeURIComponent(searchCriteria.closedDateFrom);
        }
        if(searchCriteria.closedDateTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toClosedDate=' + encodeURIComponent(searchCriteria.closedDateTo);
        }
        if(searchCriteria.csocCases != undefined){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'csocCases=' + encodeURIComponent(searchCriteria.csocCases);
        }
        if(searchCriteria.service){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'service=' + encodeURIComponent(searchCriteria.service);
        }
        if(searchCriteria.hideChildCases){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'hideChildCases=' + encodeURIComponent(searchCriteria.hideChildCases);
        }
        if(searchCriteria.byPassSF){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'byPassSF=' + encodeURIComponent(searchCriteria.byPassSF);
        }
        if(searchCriteria.forceTMLimit){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'forceTMLimit=' + encodeURIComponent(searchCriteria.forceTMLimit);
        }
        if(searchCriteria.updatedFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromLastModifiedDate=' + encodeURIComponent(searchCriteria.updatedFrom);
        }
        if(searchCriteria.updatedTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toLastModifiedDate=' + encodeURIComponent(searchCriteria.updatedTo);
        }
        if(searchCriteria.type){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'type=' + encodeURIComponent(searchCriteria.type);
        }
        if(searchCriteria.status){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'status=' + encodeURIComponent(searchCriteria.status.toString());
        }
        if(searchCriteria.contact){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'contact=' + encodeURIComponent(searchCriteria.contact);
        }
        if(searchCriteria.contactEmail){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'contactmail=' + encodeURIComponent(searchCriteria.contactEmail);
        }
        if(searchCriteria.contactEmailUnassigned){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'contactemailunassigned=' + encodeURIComponent(searchCriteria.contactEmailUnassigned);
        }
        if(searchCriteria.supportOverViewCacheByPass){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'supportOverViewCacheByPass=' + encodeURIComponent('true');
        }
        if(searchCriteria.createdByEmail){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'createdByEmail=' + encodeURIComponent(searchCriteria.createdByEmail);
        }
        if(searchCriteria.category){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'category=' + encodeURIComponent(searchCriteria.category);
        }
        if(searchCriteria.priority){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'priority=' + encodeURIComponent(searchCriteria.priority);
        }
        if(searchCriteria.subject){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'subject=' + encodeURIComponent(searchCriteria.subject);
        }
        if(searchCriteria.description){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'description=' + encodeURIComponent(searchCriteria.description);
        }
        if(searchCriteria.assetId && !searchCriteria.asset){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'assetId=' + encodeURIComponent(searchCriteria.assetId);
        }
        if(searchCriteria.asset){
            if(!query.endsWith('?')){
                query += '&';
            }
            // remove possibly blank serialNumber/name from search values
            const value = searchCriteria.asset.split('||').filter(s => (s && s !== '' && s !== 'n/a')).join('||');
            query += 'asset=' + encodeURIComponent(value);
        }
        if(searchCriteria.escalation){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'escalation=' + encodeURIComponent(searchCriteria.escalation);
        }
        if(searchCriteria.sort){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'sort=' + encodeURIComponent(searchCriteria.sort);
        }
        if(searchCriteria.sortBy){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'sortBy=' + encodeURIComponent(searchCriteria.sortBy);
        }

        if(searchCriteria.limit){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'limit=' + encodeURIComponent(searchCriteria.limit);
        }

        if(searchCriteria.skip){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'offset=' + encodeURIComponent(searchCriteria.skip);
        }

        if(searchCriteria.extended) {
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'extended=true';
        }

        if(searchCriteria.resolutionReport) {
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'resolutionReport=true';
        }

        if(searchCriteria.benchmarking) {
            if(!query.endsWith('?')) {
                query += '&';
            }
            query += 'benchmarking=true';
        }

        if(searchCriteria.currentPeriod) {
            if(!query.endsWith('?')) {
                query += '&';
            }
            query += 'currentPeriod=' + encodeURIComponent(searchCriteria.currentPeriod);
        }

        if(searchCriteria.comparisonPeriod) {
            if(!query.endsWith('?')) {
                query += '&';
            }
            query += 'comparisonPeriod=' + encodeURIComponent(searchCriteria.comparisonPeriod);
        }
        if(searchCriteria.worldWatchSignal) {
            if(!query.endsWith('?')) {
                query += '&';
            }
            query += 'worldWatchSignal=' + encodeURIComponent(searchCriteria.worldWatchSignal);
        }

        //For search functionality
        if(searchCriteria.likeSearch){
            if(!query.endsWith('?')) {
                query += '&';
            }
            query += 'likeSearch=' + encodeURIComponent(searchCriteria.likeSearch);
        }

        return this.http
            .get(query, {responseType: 'text'})
            .toPromise()
            .then(response => {
                if(searchCriteria.export) {
                    return response;
                } else {
                    return JSON.parse(response) as Case[];
                }
            })
            .then(res => {
               let returnObj: any = {};
                if(searchCriteria.requestTracker.requestTracker){
                    returnObj = {
                        requestTracker:searchCriteria.requestTracker.count,
                        data: res
                    };
                }
                else {
                    returnObj = res;
                }

                return returnObj;
            })
            .catch((err) => this.handleError(err));
    }
    
    caseLoadList(searchCriteria: SupportCaseSearchCriteria): Promise<Case[] | any>{
        const caseIds = searchCriteria.caseRefs.join(',');
        return this.http
            .get(this.caseSearchURL + '?numbers='+caseIds)
            .toPromise()
            .then(response => response as Case[])
            .catch(this.handleError);
    }

    securityIncidentReport(date: string): Promise<any> {
        const query = this.caseSearchURL
            + '?' + 'status=Closed&escalation=To Customer,from Customer&securityAnalytics=true&FromCreatedDate=' + date;
        return this.http
            .get(query)
            .toPromise()
            .then(response => response as Case[])
            .catch(this.handleError);
    }


    /**
     * Filter all assets to a single asset
     * TODO may need a getAsset method
     *
     * @param assetRef
     */
    getAsset(assetRef: string): Promise<Asset>{
        return this.http
            .get(this.assetURL + assetRef)
            .toPromise()
            .then(response => response as Asset)
            .catch(this.handleError);
    }

    /**
     * Update asset
     *
     * @param assetObject
     */
     updateAsset(assetObject: Record<string, string>): Promise<void>{
        return this.http
            .post(this.assetUpdateURL, assetObject)
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Get assets from local storage if they are cached
     * else get them from the backend service
     */
    getCachedAssets(exportPDF: boolean=false, reportName: string = '', reportAccessLevel: string = ''): Promise<Asset[]>{
        return new Promise((resolve, reject) => {
          const itsmAssets = this.dataService.assets;
            if(itsmAssets.length > 0 && !exportPDF){
                resolve(itsmAssets);
            }else{
                this.getAssets(exportPDF, reportName, reportAccessLevel)
                    .then(res => {
                        if (exportPDF){resolve([]);}
                        let assets = res;

                        // improve names and sort assets
                        assets.forEach(a => {
                            a.fullName = this.getAssetFullname(a);
                        });
                        assets = assets.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1);

                        // add details of source for asset dashboard
                        assets.forEach(a => {
                            a.source = 'Support';
                        });

                        this.dataService.assets = assets;
                        resolve(assets);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    }

    public getAssetFullname(asset:Asset) {
        let label = asset.name;
        
        if (asset.model && (asset.model !== '') && (asset.model !== asset.name)) {
            label = asset.model + ' | ' + label;
        }
        if (asset.serialNumber && (asset.serialNumber !== '')) {
            label = label + ' | ' + asset.serialNumber;
        }
        else {
            label = label + ' | n/a';
        }
        return label;
    }


    /**
     * Get all assets from backend service
     */
    private getAssets(exportPDF: boolean, reportName: string, reportAccessLevel: string): Promise<Asset[]>{

        if(exportPDF){
            return this.http
            .get(this.assetsURL+ '?limit=10000&offset=0&export=' + exportPDF + '&reportName='
                + reportName + '&pdfAccessLevel=' + reportAccessLevel)
            .toPromise()
            .then(response => response as Asset[])
            .catch(this.handleError);
        }
        else{
            return new Promise((resolve,reject) => {
                this.http
                .get(this.assetsCount)
                .toPromise()
                .then((response: any) => {
                    if(response['error']) {
                        throw response;
                    } else {
                        let totalCount = response.map.count;
                        const promiseList = [];
                        const limit = 1000;
                        let offset = 0;
                        while(totalCount>0){
                            promiseList.push(this.http
                                .get(this.assetsURL + '?limit=' + limit + '&offset=' + offset).toPromise());
                                offset = offset + 1000;
                                totalCount = totalCount - 1000;
                        }
                        Promise.all(promiseList).then((res: []) => {
                            let finalRes = [];
                            for (const arr of res){
                                finalRes = finalRes.concat(arr);
                            }
                            resolve(finalRes as Asset[]);
                        });
                    }
                })
                .catch(this.handleError);
            });
        }
    }


    /**
     * Get contacts from local storage if they are cached
     * else get them from the backend service
     */
    getCachedContacts(): Promise<Contact[]>{
        return new Promise((resolve, reject) => {
            if(this.contacts){
                resolve(this.contacts);
            } else {
                this.getContacts()
                .then(res => {
                    const contacts = res.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1);
                    this.contacts = contacts;
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
            }
        });
    }

    /**
     * Get all contacts from backend service
     */
    private getContacts(): Promise<Contact[]>{
        return this.http
            .get(this.contactsURL)
            .toPromise()
            .then(response => response as Contact[])
            .catch(this.handleError);
    }

    /**
     * Handling Contacts
     */
    deleteContact(id: string): Promise<any>{
        return this.http
            .get(this.deleteContactURL + '/' + id)
            .toPromise()
            .then((res: any) => res as JSON)
            .catch(this.handleError);
    }
    addContact(object: Record<string, string>): Promise<any>{
        return this.http
            .post(this.addContactURL, object)
            .toPromise()
            .then((res: any) => res as JSON)
            .catch(this.handleError);
    }
    editContact(object: Record<string, string>): Promise<any>{
        return this.http
            .post(this.editContactURL, object)
            .toPromise()
            .then((res: any) => res as JSON)
            .catch(this.handleError);
    }
    /**
     * Get milestones from local storage if they are cached
     * else get them from the backend service
     */
     getCachedMilestones(): Promise<Milestone[]>{
        return new Promise((resolve, reject) => {
          let milestones = JSON.parse(sessionStorage.getItem(SessionStorageItem.milestones));
            if(milestones){
                resolve(milestones);
            }else{
                this.getMilestones()
                    .then(res => {
                        milestones = res;

                        sessionStorage.setItem(SessionStorageItem.milestones, JSON.stringify(milestones));
                        resolve(milestones);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
     }

    /**
     * Get all assets from backend service
     */
    private getMilestones(): Promise<Milestone[]>{
        return this.http
            .get(this.mileStoneURL)
            .toPromise()
            .then(response => response as Milestone[])
            .catch(this.handleError);
    }

    /**
     * Gets CV objects from session storage if they exist, if not
     * then get them from webservice
     *
     * @param object
     * @param type
     */
    getCachedCV(type: string): Promise<string[]>{
        return new Promise((resolve, reject) => {
          const cvItem = this.dataService.getCaseValue(type);
            if(this.dataService.getCaseValue(type)){
                resolve(cvItem);
            }else{
                this.httpItsmService.getCV(type).subscribe({
                    next:(res) => {
                        this.dataService.setCaseValue(type, res);
                        resolve(res);
                    },
                    error:(error) => {
                        this.alertService.handlerError(error);
                        reject(error);
                    }
                })
            }
        });
    }

    async getServiceCount() {
        const $count = this.http.get(this.servicesCount);
        return await firstValueFrom($count) as {map: {count: number}};
    }

    async getAssetCount() {
        const $count: Observable<Object> = this.http.get(this.assetsCount);
        return await firstValueFrom($count) as {map: {count: number}};
    }

    public getLazyloadedServices(queryStatus: QueryStatus):Observable<any[]> {
  
        let url = this.services + '?limit=' + queryStatus.limit + '&offset=' + queryStatus.offset;
        if(queryStatus.search) {
            url += '&serviceName=' + queryStatus.search;
        }
        return this.http.get<any[]>(url);
    }

    public getLazyloadedAssets(queryStatus: QueryStatus):Observable<any[]> {
  
        let url = this.assetsURL + '?limit=' + queryStatus.limit + '&offset=' + queryStatus.offset;
        if(queryStatus.search) {
            url += '&search=' + queryStatus.search;
        }

        return this.http.get<any[]>(url);
    }

    public getAssetsByServiceId(serviceId: string):Observable<any[]> {
        let url = environment.endpoints.itsm + 'assetbyservice?serviceid=' + serviceId;
        return this.http.get<any[]>(url);
    }

    public getAssetsByAssetId(assetId: string):Observable<Asset[]> {
        let url = environment.endpoints.itsm + 'asset/' + assetId ;
        return this.http.get<Asset[]>(url);
    }

    public getServiceByServiceId(serviceId: string):Observable<any[]> {
        let url = environment.endpoints.itsm + 'service' + '?serviceid=' + serviceId;
        return this.http.get<any[]>(url);
    }

    cacheCasesCountUpdatedSinceLastLogin(date: Date): Promise<number> {
        return new Promise((resolve,reject) => {
          const itsmCasesUpdatedLastLoginCount = sessionStorage.getItem(SessionStorageItem.itsmCasesUpdatedLastLoginCount);
            if(itsmCasesUpdatedLastLoginCount){
                resolve(Number(itsmCasesUpdatedLastLoginCount));
            }else{
                const tempSearchCriteria = new SupportCaseSearchCriteria();
                tempSearchCriteria.updatedFrom = Utilities.getDateAsString(date);
                tempSearchCriteria.updatedTo = Utilities.getTodayPlusDays(1);
                tempSearchCriteria.SNCount = true;
                tempSearchCriteria.byPassSF = true;
                this.caseSearch(tempSearchCriteria)
                    .then(res => {
                        sessionStorage.setItem(SessionStorageItem.itsmCasesUpdatedLastLoginCount, String(res));
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    }

    cacheCasesCountCreatedSinceLastLogin(date: Date): Promise<number> {
        return new Promise((resolve,reject) => {
          const itsmCasesUpdatedLastLoginCount = sessionStorage.getItem(SessionStorageItem.itsmCasesCreatedLastLoginCount);
            if(itsmCasesUpdatedLastLoginCount){
                resolve(Number(itsmCasesUpdatedLastLoginCount));
            }else{
                const tempSearchCriteria = new SupportCaseSearchCriteria();
                tempSearchCriteria.createdFrom = Utilities.getDateAsString(date);
                tempSearchCriteria.createdTo = Utilities.getTodayPlusDays(1);
                tempSearchCriteria.SNCount = true;
                tempSearchCriteria.byPassSF = true;
                this.caseSearch(tempSearchCriteria)
                    .then(res => {
                        sessionStorage.setItem(SessionStorageItem.itsmCasesCreatedLastLoginCount, String(res));
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    }

    getAttachment(id: string, caseId: string): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.set(HeaderProperties.contentEncoding, 'gzip');

        return this.http.get(environment.endpoints.itsm + 'attachment/' + caseId + '/' + id, {headers, responseType: 'blob'})
          .toPromise()
          .then(response => response as any)
          .catch((error) => this.handleError(error));
    }
    getLiveReportSecIb(searchCriteria: SupportCaseSearchCriteria): Promise<Case[] | any>{
        let query = this.secuityIncidentBenchmarking + '?';
        if(searchCriteria.count){
            query = this.caseSearchCountURL + '?';
        }



        if(searchCriteria.caseNumber){
            query += 'caseNumber=' + encodeURIComponent(searchCriteria.caseNumber);
        }
        if(searchCriteria.createdFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromCreatedDate=' + encodeURIComponent(searchCriteria.createdFrom);
        }
        if(searchCriteria.createdTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toCreatedDate=' + encodeURIComponent(searchCriteria.createdTo);
        }
        if(searchCriteria.action){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'action=true';
       }
       if(searchCriteria.actor){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'actor=true';
       }
       if(searchCriteria.attributes){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'attributes=true';
       }
       if(searchCriteria.who){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'who=true';
       }
       if(searchCriteria.what){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'what=true';
       }
       if(searchCriteria.why){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'why=true';
       }
        if(searchCriteria.closedDateFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromClosedDate=' + encodeURIComponent(searchCriteria.closedDateFrom);
        }
        if(searchCriteria.closedDateTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toClosedDate=' + encodeURIComponent(searchCriteria.closedDateTo);
        }
        if(searchCriteria.updatedFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromLastModifiedDate=' + encodeURIComponent(searchCriteria.updatedFrom);
        }
        if(searchCriteria.updatedTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toLastModifiedDate=' + encodeURIComponent(searchCriteria.updatedTo);
        }
        if(searchCriteria.type){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'type=' + encodeURIComponent(searchCriteria.type);
        }
        if(searchCriteria.status){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'status=' + encodeURIComponent(searchCriteria.status.toString());
        }
        if(searchCriteria.contact){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'contact=' + encodeURIComponent(searchCriteria.contact);
        }
        if(searchCriteria.contactEmail){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'contactmail=' + encodeURIComponent(searchCriteria.contactEmail);
        }
        if(searchCriteria.category){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'category=' + encodeURIComponent(searchCriteria.category);
        }
        if(searchCriteria.priority){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'priority=' + encodeURIComponent(searchCriteria.priority);
        }
        if(searchCriteria.subject){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'subject=' + encodeURIComponent(searchCriteria.subject);
        }
        if(searchCriteria.description){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'description=' + encodeURIComponent(searchCriteria.description);
        }
        if(searchCriteria.assetId){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'assetId=' + encodeURIComponent(searchCriteria.assetId);
        }
        if(searchCriteria.asset){
            if(!query.endsWith('?')){
                query += '&';
            }
            // remove possibly blank serialNumber/name from search values
            const value = searchCriteria.asset.split('||').filter(s => (s && s !== '' && s !== 'n/a')).join('||');
            query += 'asset=' + encodeURIComponent(value);
        }
        if(searchCriteria.escalation){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'escalation=' + encodeURIComponent(searchCriteria.escalation);
        }
        if(searchCriteria.sort){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'sort=' + encodeURIComponent(searchCriteria.sort);
        }
        if(searchCriteria.sortBy){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'sortBy=' + encodeURIComponent(searchCriteria.sortBy);
        }

        if(searchCriteria.limit){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'limit=' + encodeURIComponent(searchCriteria.limit);
        }

        if(searchCriteria.skip){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'offset=' + encodeURIComponent(searchCriteria.skip);
        }
        if (searchCriteria.securityAnaltyics){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'SecurityAnalytics=true';
        }

        return this.http
            .get(query)
            .toPromise()
            .then(response => response as Case[])
            .then(res => {
               let returnObj: any = {};
                if(searchCriteria.requestTracker.requestTracker){
                    returnObj = {
                        requestTracker:searchCriteria.requestTracker.count,
                        data: res
                    };
                }
                else {
                    returnObj = res;
                }

                return returnObj;
            })
            .catch(this.handleError);
    }
    getLiveReport(searchCriteria: SupportCaseSearchCriteria): Promise<Case[] | any>{
        let query = this.caseSearchURL + '?';
        if(searchCriteria.count){
            query = this.caseSearchCountURL + '?';
        }



        if(searchCriteria.caseNumber){
            query += 'caseNumber=' + encodeURIComponent(searchCriteria.caseNumber);
        }
        if(searchCriteria.createdFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromCreatedDate=' + encodeURIComponent(searchCriteria.createdFrom);
        }
        if(searchCriteria.createdTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toCreatedDate=' + encodeURIComponent(searchCriteria.createdTo);
        }
        if(searchCriteria.action){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'action=true';
       }
       if(searchCriteria.actor){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'actor=true';
       }
       if(searchCriteria.attributes){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'attributes=true';
       }
       if(searchCriteria.who){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'who=true';
       }
       if(searchCriteria.what){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'what=true';
       }
       if(searchCriteria.why){
            if(!query.endsWith('?')){
               query += '&';
           }
           query += 'why=true';
       }
        if(searchCriteria.closedDateFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromClosedDate=' + encodeURIComponent(searchCriteria.closedDateFrom);
        }
        if(searchCriteria.closedDateTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toClosedDate=' + encodeURIComponent(searchCriteria.closedDateTo);
        }
        if(searchCriteria.updatedFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromLastModifiedDate=' + encodeURIComponent(searchCriteria.updatedFrom);
        }
        if(searchCriteria.updatedTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toLastModifiedDate=' + encodeURIComponent(searchCriteria.updatedTo);
        }
        if(searchCriteria.type){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'type=' + encodeURIComponent(searchCriteria.type);
        }
        if(searchCriteria.status){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'status=' + encodeURIComponent(searchCriteria.status.toString());
        }
        if(searchCriteria.contact){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'contact=' + encodeURIComponent(searchCriteria.contact);
        }
        if(searchCriteria.contactEmail){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'contactmail=' + encodeURIComponent(searchCriteria.contactEmail);
        }
        if(searchCriteria.category){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'category=' + encodeURIComponent(searchCriteria.category);
        }
        if(searchCriteria.priority){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'priority=' + encodeURIComponent(searchCriteria.priority);
        }
        if(searchCriteria.subject){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'subject=' + encodeURIComponent(searchCriteria.subject);
        }
        if(searchCriteria.description){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'description=' + encodeURIComponent(searchCriteria.description);
        }
        if(searchCriteria.assetId){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'assetId=' + encodeURIComponent(searchCriteria.assetId);
        }
        if(searchCriteria.asset){
            if(!query.endsWith('?')){
                query += '&';
            }
            // remove possibly blank serialNumber/name from search values
            const value = searchCriteria.asset.split('||').filter(s => (s && s !== '' && s !== 'n/a')).join('||');
            query += 'asset=' + encodeURIComponent(value);
        }
        if(searchCriteria.escalation){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'escalation=' + encodeURIComponent(searchCriteria.escalation);
        }
        if(searchCriteria.sort){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'sort=' + encodeURIComponent(searchCriteria.sort);
        }
        if(searchCriteria.sortBy){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'sortBy=' + encodeURIComponent(searchCriteria.sortBy);
        }

        if(searchCriteria.limit){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'limit=' + encodeURIComponent(searchCriteria.limit);
        }

        if(searchCriteria.skip){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'offset=' + encodeURIComponent(searchCriteria.skip);
        }
        if (searchCriteria.securityAnaltyics){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'SecurityAnalytics=true';
        }

        return this.http
            .get(query)
            .toPromise()
            .then(response => response as Case[])
            .then(res => {
               let returnObj: any = {};
                if(searchCriteria.requestTracker.requestTracker){
                    returnObj = {
                        requestTracker:searchCriteria.requestTracker.count,
                        data: res
                    };
                }
                else {
                    returnObj = res;
                }

                return returnObj;
            })
            .catch(this.handleError);
    }

    getLivePDFReport(searchCriteria: SupportCaseSearchCriteria){

        let headers = new HttpHeaders();
        headers = headers.set(HeaderProperties.contentEncoding, 'gzip');
        headers = headers.set(HeaderProperties.contentType, 'application/pdf');
        headers = headers.set(HeaderProperties.accept, 'application/pdf');

        let query = this.caseSearchURL + '?';

        query += 'downloadPDF=true';

        if(searchCriteria.createdFrom){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'fromCreatedDate=' + encodeURIComponent(searchCriteria.createdFrom);
        }
        if(searchCriteria.createdTo){
            if(!query.endsWith('?')){
                query += '&';
            }
            query += 'toCreatedDate=' + encodeURIComponent(searchCriteria.createdTo);
        }

        return this.http.get(query,
                              {headers, responseType: 'blob'})
      .toPromise()
      .then(response => response as any)
      .catch(this.handleError);
    }

    /**
     * * Schedule a report*/
    scheduleReport(reportName: string, accessLevel: string, extension: string, scheduleDate: string, filterParameters: any) {
        if(!reportName || !accessLevel || !extension || !filterParameters || !scheduleDate){
            return this.handleError('provided input fields are incomplete');
        }

        const time = new Date(scheduleDate).getTime() / 1000;
        return this.http
            .post(this.scheduleReportURL, {
                reportName,
                accessLevel,
                extension,
                time,
                filterParameters
            })
            .toPromise()
            .then(r => r as any)
            .catch(this.handleError);
    }

    /**
     * get schedules of reports
     */
    getReportSchedules(): Promise<Schedule[]> {
        return this.http
            .get<Schedule[]>(this.scheduleReportURL)
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * delete a given schedule
     * @param scheduleId
     * @returns
     */
    deleteSchedule(scheduleId: string) {
        return this.http
            .get(this.deleteScheduleURL, {params: {_id : scheduleId}})
            .toPromise()
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        if (error.status === 521){
            error.message = this.translateService.instant('error.521.label');
        }
        if (error.status === 410){
            error.message = this.translateService.instant('error.410.label');
        }
        if (error.status === 431){
            error.message = this.translateService.instant('error.431.label');
        }
        return Promise.reject(error.message || error);
    }

}
