/* eslint-disable @typescript-eslint/member-ordering */
import { Component } from '@angular/core';
import { SeriesOptionsType } from 'highcharts';
import { ChartType } from 'src/app/shared/model/activity';
import { ChartService } from 'src/app/shared/services/chart.service';
import { Action, CountResponse, UserActivityService } from 'src/app/shared/services/user-activity.service';
import { TranslateService } from '@ngx-translate/core';
import { clone } from '@okta/okta-auth-js';
import { ContextService } from 'src/app/shared/services/context-service';
import { WidgetService } from 'src/app/shared/services/widget.service';
import { ACTIONS, constants } from 'src/app/shared/constants';
import { BaseAnalyticsComponent } from '../base-analytics/base-analytics.component';

@Component({
  selector: 'app-actions-analytics',
  templateUrl: '../base-analytics/base-analytics.component.html',
  styleUrls: ['../base-analytics/base-analytics.component.scss'],
})
export class ActionsAnalyticsComponent extends BaseAnalyticsComponent {

  private userActionsData: { name: string; drilldown: string; y: number }[] = [];
  private actionsActivityData: number[][];
  private userActionsDrilldownSeries: SeriesOptionsType[];
  private widgetActionsCategories: string[] = [];
  private widgetActionsSeries: SeriesOptionsType[] = [];

  constructor(
    private chartService: ChartService,
    private translateService: TranslateService,
    public userActivityService: UserActivityService,
    public widgetService: WidgetService,
    public contextService: ContextService
  ) {
    super(userActivityService, widgetService, contextService);
  }

  /**
   * Map count data to graph data
   * @param data 
   */
  public filterCallBack(res: CountResponse) {
    // Map user actions data
    this.mapUserActionsData(res.actions);

    // Map actions per day data
    this.actionsActivityData = res.actionsPerDay.map(item => ([new Date(item.id).getTime(), item.count]));

    // Map user widget actions data
    this.mapWidgetActionsData(res.widgets);

    // Load widgets
    this.loadWidgets(this.generateChartsOptions());
    this.pageLoading = false;
  }

  public generateChartsOptions(): Highcharts.Options[] {
    // Define user activities widget
    const actionsActivitySeries: SeriesOptionsType[] = [
      {
        name: this.translateService.instant('pages.dashboard.analytics.dailyActions'),
        data: this.actionsActivityData,
        type: ChartType.line
      },
    ];
    const options = [
      this.chartService.initBarChartOptions(
        this.translateService.instant('pages.dashboard.analytics.dashboardWidgetsActions'),
        this.translateService.instant('pages.dashboard.widgets'),
        this.widgetActionsCategories,
        this.translateService.instant('pages.dashboard.analytics.actions'),
        this.widgetActionsSeries,
        [],
        true,
        constants.graphCustomColors1
      ),
      this.chartService.initColumnOptions(
        this.translateService.instant('pages.dashboard.analytics.userActions'),
        this.translateService.instant('pages.dashboard.analytics.occurrences'),
        this.translateService.instant('pages.dashboard.analytics.userActions'),
        this.userActionsData,
        this.userActionsDrilldownSeries,
        this.translateService.instant('pages.dashboard.analytics.occurrences')
      ),
      this.chartService.initLineChartOptions(
        this.translateService.instant('pages.dashboard.analytics.actionsActivity'),
        this.translateService.instant('pages.dashboard.analytics.userActions'),
        actionsActivitySeries
      )
    ];
    return options;
  }

  /**
   * Map userActions data
   * @param actions 
   */
  private mapUserActionsData(actions: Action[]) {
    this.userActionsData = this.mergeActionDuplicates(clone(actions))
      .map(item => ({name:item.action, drilldown: item.action, y:item.count}))
      .sort((c1, c2) => c2.y - c1.y);
    const drilldownSeriesNames = actions.map(item => item.action).reduce(
      (previous, current)=> previous.includes(current) ? previous : previous.concat([current]),
      []
    );
    this.userActionsDrilldownSeries = drilldownSeriesNames.map((name) => ({
      name,
      id: name,
      data: actions.filter(item => item.action === name).map(item => [item.page, item.count]),
      type: null
    }));
  }

  /**
   * Map widgets data
   * @param widgets 
   */
  private mapWidgetActionsData(widgets: Action[]) {
    let addedData = [];
    let removedData = [];
    let widgetsFilteredByName: Action[];

    widgets.sort((a, b) => a.widget.localeCompare(b.widget));
    this.widgetActionsCategories = [...new Set(widgets.map( widget => widget.widget))];

    this.widgetActionsCategories.forEach( category => {
      widgetsFilteredByName = widgets.filter( widget => widget.widget === category);
      addedData.push(widgetsFilteredByName.find( action => action.action === ACTIONS.WidgetAdd )?.count || 0);
      removedData.push(widgetsFilteredByName.find( action => action.action === ACTIONS.WidgetRemoval )?.count || 0);
    });

    this.widgetActionsSeries = [
      { name: ACTIONS.WidgetAdd, data: addedData, type: ChartType.bar },
      { name: ACTIONS.WidgetRemoval, data: removedData, type: ChartType.bar }
    ];
  }

  /**
   * Some actions can be duplicated. Add their counts in a single action
   * @param actions 
   * @returns merged actions
   */
  private mergeActionDuplicates(actions: Action[]) {
    const actionNames: string[] = [];
    const mergedDuplicates: Action[] = [];
    actions.forEach( (item: Action) => {
      if (!actionNames.includes(item.action)) {
        actionNames.push(item.action);
        mergedDuplicates.push(item);
      } else {
        mergedDuplicates.forEach( action => {
          if (action.action === item.action) {
            action.count += item.count;
          }
        });
      }
    });
    return mergedDuplicates;
  }

}
