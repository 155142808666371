<app-card
  [title]="displayHeader ? 'pages.cybersocxdr.insight.insight' : undefined"
>
  @if(displayHeader){
    <div class="d-flex gap-2" action>
      <select
        class="form-select rounded"
        (change)="onSelectPeriod($event.target.value)"
      >
        <option
          *ngFor="let periodOption of periodOptions"
          [value]="periodOption"
          [selected]="periodOption === period"
        >
          {{ 'pages.cybersocxdr.filters.' + periodOption | translate }}
        </option>
      </select>
      <select
        class="form-select rounded"
        (change)="onFilterSelect($event.target.value)"
      >
        <option value="">
          {{ 'pages.cybersocxdr.insight.allCategories' | translate }}
        </option>
        <option
          *ngFor="let category of InsightCategory | keyvalue"
          [value]="category.value"
        >
          {{ 'pages.cybersocxdr.insight.' + category.value | translate }}
        </option>
      </select>
      <div class="dropdown">
        <button
          class="btn btn-secondary rounded dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ 'pages.cybersocxdr.insight.sortBy' | translate }}
        </button>
        <ul class="dropdown-menu">
          <li *ngFor="let category of InsightCategory | keyvalue">
            <a class="dropdown-item" (click)="onSortSelect(category.value)">{{
              'pages.cybersocxdr.insight.' + category.value | translate
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  }
    
    <div class="row">
      <div id="carouselExample" class="carousel slide">
        <div class="carousel-inner">
          <div
            *ngFor="let carrouselInsight of carrouselInsights; let i = index"
            class="carousel-item {{ i === 0 ? 'active' : '' }}"
          >
            <div class="row">
              <div class="col-6" *ngFor="let insight of carrouselInsight">
                <app-insight [insight]="insight"></app-insight>
              </div>
            </div>
          </div>
        </div>
        @if(carrouselInsights.length > 1){
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
        }
      </div>
    </div>
    
</app-card>
