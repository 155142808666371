<!-- content -->
<div class="content">

  <!-- filters -->
  <div class="card" data-toggle="tooltip" data-placement="top"
    title="{{ 'pages.assets.dashboard.searchfldDesc' | translate }}">
    <div class="card-body">
      <form>
        <div class="row">

           <!-- search -->
           <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9">
            <input type="text" class="form-control" id="globalSearch" name="globalSearch" placeholder="{{ 'pages.assets.dashboard.globalSearch' | translate }}"
            [(ngModel)]="globalSearch">
          </div>

          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <div class="search-area">
              <button class="btn btn-primary" (click)="search();">{{ 'pages.generic.filter' | translate }}</button>
              <button class="btn btn-primary" (click)="reset();">{{ 'pages.generic.reset' | translate }}</button>
              <button class="btn btn-primary" (click)="openModal(content);">{{ 'buttons.action.generateReport' |
                translate }}</button>
              </div>
            </div>

        </div>
      </form>
    </div>
  </div>

  <!-- assets list -->
  <div class="rowfiller" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    (scrolled)="loadAssets()">

    <ul class="nav nav-tabs">
      <li class="nav-item" title="{{ 'pages.assets.dashboard.assetContractDesc' | translate }}">
        <a class="nav-link" [ngClass]="{ 'active' : page === assetsPage }" aria-current="page" (click)="page = assetsPage; loadAssets();">{{
          'pages.assets.dashboard.assets' | translate }}
          <span class="tag text-bg-primary ms-2" style="padding:0.1rem 0.5rem;">
            {{assetFilter.totalCount}} </span>  
        </a>
      </li>

      <!-- 
        <li class="nav-item" [ngClass]="{ 'active' : page === flaggedPage }" title="{{ 'pages.assets.dashboard.assetFlagDesc' | translate }}">
          <a class="nav-link" (click)="loadFlagged();">{{
            'pages.generic.flagged' | translate }}
            <span class="tag text-bg-primary ms-2"
            style="padding:0.1rem 0.5rem;">{{flagRefs.length}}</span>
          </a>
        </li>
      -->
    </ul>

    <div class="tools">
      <fa-icon [icon]="['fas', 'th-list']" size="2x" class="p-1 click" (click)="changeViewType('list')"></fa-icon>
      <fa-icon [icon]="['fas', 'th-large']" size="2x" class="p-1 click" (click)="changeViewType('grid')"></fa-icon>
    </div>
    <div class="card-body" *ngIf="userPreferenceService.userPreferences?.assetViewType === 'list' && (page === assetsPage || page === flaggedPage)">
      <app-asset-list *ngIf="assets" [assets]="page === assetsPage ? assets : flaggedAssets" [flagRefs]="flagRefs"
        [flaggingRef]="flaggingRef" [loading]="assetFilter.loading"
        (flagEmitter)="flag($event);" (sortEmitter)="sortAssets($event);">
      </app-asset-list>
    </div>
    <div class="card-body mt-4" *ngIf="userPreferenceService.userPreferences?.assetViewType === 'grid' && (page === assetsPage || page === flaggedPage)">
      <app-asset-grid [assets]="page === assetsPage ? assets : flaggedAssets" [flagRefs]="flagRefs"
        [flaggingRef]="flaggingRef" [loaded]="page === assetsPage ? assetsLoaded : flaggedAssetsLoaded"
        (flagEmitter)="flag($event);">
      </app-asset-grid>
    </div>
  </div>
</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ 'modals.export.title' | translate }}</h5>
  </div>
  <div class="modal-body">
    <div class="form-group col-12 pt-2">
      <label for="contact">{{ 'modals.export.reportName' | translate }}</label>
      <input type="text" class="form-control" name="PDFName" aria-describedby="PDFName" [(ngModel)]="PDFName">
    </div>
    <div class="row">
      <div class="col-6">
        <select class="form-control" name="reportOpt" aria-describedby="reportOpt" [(ngModel)]="reportOption">
          <option value="pdf">{{ pdfLabel }}</option>
          <option value="csv">{{ csvLabel }}</option>
        </select>
      </div>
      <div class="col-6">
        <select class="form-control" name="reportLevel" aria-describedby="reportLevel" [(ngModel)]="pdfAccessLevel">
          <option title="{{ 'pages.generic.ACCESS_INTERNAL_REPORTS' | translate }}" value="0">{{
            'pages.generic.internal' | translate }}</option>
          <option title="{{ 'pages.generic.ACCESS_CONFIDENTIAL_REPORTS' | translate }}" value="1">{{
            'pages.generic.confidential' | translate }}</option>
          <option title="{{ 'pages.generic.ACCESS_RESTRICTED_REPORTS' | translate }}" value="2">{{
            'pages.generic.restricted' | translate }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">{{ 'pages.generic.close' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('save')">{{ 'pages.generic.save' | translate
      }}</button>
  </div>
</ng-template>