  <div class="d-flex faketableheaderrow" *ngIf="cases && loaded">
    <ng-container *ngFor="let column of selectedColumns">
      <div *ngIf="column.field==='caseNumber'" class="col-xl-2 col-lg-1 col-md-2 col-sm-3 d-none d-sm-block faketableheader click" (click)="sortResults($event, 'caseNumber');" style="white-space: nowrap;">{{ 'pages.support.dashboard.fieldTitles.reference' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
      <div *ngIf="column.field==='subject'" class="col-xl-3 col-lg-2 col-md-4 col-sm-9 faketableheader" style="text-align: left;">{{ 'pages.support.dashboard.fieldTitles.subject' | translate }}</div>
      <div *ngIf="column.field==='priority'" class="col-xl-1 col-lg-2 col-md-3 d-none d-md-block faketableheader click" (click)="sortResults($event, 'priority');" style="white-space: nowrap;">{{ 'pages.support.dashboard.fieldTitles.priority' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
      <div *ngIf="column.field==='status'" class="col-xl-1 col-lg-1 col-md-3 d-none d-md-block faketableheader click" (click)="sortResults($event, 'status');" style="white-space: nowrap;">{{ 'pages.support.dashboard.fieldTitles.status' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
      <div *ngIf="column.field==='type'" class="col-xl-1 col-lg-2 d-none d-lg-block faketableheader click" (click)="sortResults($event, 'type');" style="white-space: nowrap;">{{ 'pages.support.dashboard.fieldTitles.type' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
      <div *ngIf="column.field==='createdDate'" class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader click" (click)="sortResults($event, 'createdDate');" style="white-space: nowrap;">{{ 'pages.support.dashboard.fieldTitles.created' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
      <div *ngIf="column.field==='lastModifiedDate'" class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader click" (click)="sortResults($event, 'lastModifiedDate');" style="white-space: nowrap;">{{ 'pages.support.dashboard.fieldTitles.updated' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
      <div *ngIf="column.field==='escalation'" class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader">{{ 'pages.support.dashboard.fieldTitles.escalation' | translate }}</div>
      <div *ngIf="column.field==='serviceContract'" class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader">{{ 'pages.support.dashboard.fieldTitles.serviceContrat' | translate }}</div>
      <div *ngIf="column.field==='openedBy'" class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader">{{ 'pages.support.dashboard.fieldTitles.openedBy' | translate }}</div>
      <div *ngIf="column.field==='updatedBy'" class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader">{{ 'pages.support.dashboard.fieldTitles.updated' | translate }}</div>
      <!-- <div *ngIf="column.field==='madeSLA'" class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader">{{ 'pages.support.dashboard.fieldTitles.madeSLA' | translate }}</div> -->
      <div *ngIf="column.field==='contact'" class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader">{{ 'pages.support.dashboard.fieldTitles.contact' | translate }}</div>
      <div *ngIf="column.field==='parentId'" class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader">{{ 'pages.support.dashboard.fieldTitles.parentId' | translate }}</div>
    </ng-container>


    <div class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader">{{ 'pages.generic.flag' | translate }}</div>
  </div>

  <div *ngIf="cases && loaded">

    <ng-container *ngFor="let cc of cases" >
      <a [routerLink]="['/support/view/' + cc.id]" routerLinkActive="activeLink" style="text-decoration: none;">

        <div class="d-flex faketablerow">

          <ng-container *ngFor="let column of selectedColumns">
            <div *ngIf="column.field==='caseNumber'" class="col-xl-2 col-lg-1 col-md-2 col-sm-3 d-none d-sm-block faketablecell" >
              {{cc.caseNumber}}
            </div>
            <div *ngIf="column.field==='subject'" class="col-xl-3 col-lg-2 col-md-4 col-sm-9 col-xs-12 faketablecell" style="text-align: left;">{{cc.subject}}</div>
            <div *ngIf="column.field==='priority'" class="col-xl-1 col-lg-2 col-md-3 d-none d-md-block faketablecell">
              <span class="priority tag p-2 w-100 text-black" [ngClass]="'P' + cc.priority | lowercase | removeWhiteSpace">{{cc.priority}}</span>
            </div>
            <div *ngIf="column.field==='status'" class="col-xl-1 col-lg-1 col-md-3 d-none d-md-block faketablecell">{{cc.status}}</div>
            <div *ngIf="column.field==='type'" class="col-xl-1 col-lg-2 d-none d-lg-block faketablecell">{{cc.type}}</div>
            <div *ngIf="column.field==='createdDate'" class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">{{cc.createdDate | date: 'dd/MM/yyyy HH:mm'}}</div>
            <div *ngIf="column.field==='lastModifiedDate'" class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">{{cc.lastModifiedDate | date: 'dd/MM/yyyy HH:mm'}}</div>
            <div *ngIf="column.field==='escalation'" class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">{{cc.escalation}}</div>
            <div *ngIf="column.field==='serviceContract'" class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">{{cc.serviceContract}}</div>
            <div *ngIf="column.field==='openedBy'" class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">{{cc.openedBy ? cc.openedBy.substring(0,10) + '...' : ''}}</div>
            <div *ngIf="column.field==='updatedBy'" class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">{{cc.updatedBy ? cc.updatedBy.substring(0,10) + '...' : ''}}</div>
            <!-- <div *ngIf="column.field==='madeSLA'" class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">{{cc.madeSla}}</div> -->
            <div *ngIf="column.field==='contact'" class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">{{cc.contact?.fullName}}</div>
            <div *ngIf="column.field==='parentId'" class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">{{cc.parentCaseNumber}}</div>

          </ng-container>
            <div class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">
            <div class="spinner-grow spinner-grow-sm text-primary" *ngIf="flaggingRef && flaggingRef === cc.caseNumber" data-toggle="tooltip" data-placement="top" title="{{ 'pages.support.dashboard.flagDesc' | translate }}">
              <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
            </div>
            <fa-icon [icon]="['far', 'flag']" class="click" (click)="flagCase($event, cc.caseNumber)" *ngIf="(!flagRefs.includes(cc.caseNumber)) && (!flaggingRef || (flaggingRef && flaggingRef !== cc.caseNumber))"></fa-icon>
            <fa-icon [icon]="['fas', 'flag']" class="click" (click)="flagCase($event, cc.caseNumber)" *ngIf="(flagRefs.includes(cc.caseNumber)) && (!flaggingRef || (flaggingRef && flaggingRef !== cc.caseNumber))"></fa-icon>
          </div>
        </div>
    </a>

    </ng-container>


  </div>

  <div class="row justify-content-center p-5" *ngIf="!loaded || scrollLoading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  </div>

  <div class="row p-3 justify-content-center redborder-sides" *ngIf="loaded && cases && cases.length === 0">
    <div class="col-md-12 table-border justify-content-center">
      {{ 'pages.support.dashboard.noResults' | translate }}
    </div>
  </div>
