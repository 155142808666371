<div >

    <div class="row">
      <div class="col-12">

        <div class="card" data-toggle="tooltip" data-placement="top"
          title="{{ 'pages.activity.searchActivityDesc' | translate }}">
          <div class="card-body">

            <form>
              <div class="row ms-1">
                <!-- type -->
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <label for="typeSelect">{{ 'pages.generic.type' | translate }}</label>
                  <select class="form-select" id="typeSelect" name="type" [(ngModel)]="type">
                    <option></option>
                    <option value="Cases">{{'pages.dashboard.activity.options.cases' | translate }}</option>
                    <option value="World Watch">{{'pages.dashboard.activity.options.worldWatch' | translate }}</option>
                    <option value="Vulnerabilities">{{'pages.dashboard.activity.options.vulnerabilities'| translate }}
                    </option>
                  </select>
                </div>

                <!-- from -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 mb-3">
                  <div class="custom-label" id="dateFromLabel" >{{ 'pages.generic.dateFrom' | translate }}
                    <app-help message="{{ 'pages.generic.dateRangeHelpDesc' | translate }}"></app-help>
                  </div>
                  <input type="date" class="form-control" name="dateFrom" aria-labelledby="dateFromLabel"
                    [(ngModel)]="dateFrom" (change)="validateDateRanges()" [ngClass]="{'is-invalid': !validDateRanges}">
                </div>

                <!-- to -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 mb-3">
                  <div class="custom-label" id="dateToLabel" >{{ 'pages.generic.dateTo' | translate }}
                    <app-help message="{{ 'pages.generic.dateRangeHelpDesc' | translate }}"></app-help>
                  </div>
                  <input type="date" class="form-control" name="dateTo" aria-labelledby="dateToLabel" [(ngModel)]="dateTo" (change)="validateDateRanges()" [ngClass]="{'is-invalid': !validDateRanges}">
                </div>
              </div>

              <div class="ms-1">
                <button class="btn btn-primary" (click)="search();" [disabled]="!validDateRanges">{{ 'pages.generic.filter' | translate }}</button>
                <button class="btn btn-primary" (click)="reset();">{{ 'pages.generic.reset' | translate }}</button>
              </div>
            </form>

          </div>
        </div>

      </div>
    </div>

</div>

<div class="rowfiller">

    <div class="row pt-3">
      <div class="col-lg-12">

        <div *ngIf="subsetActivities && loadedActivities">
          <div class="row pb-1 faketablerow" *ngFor="let act of subsetActivities" [routerLink]="act.url"
            routerLinkActive="router-link-active">

            <ng-template #list>
              <div [innerHTML]="act.iconAlt"></div>
            </ng-template>

            <div class="col-xl-1 col-lg-1 col-sm-1 col-xs-1 faketablecell">
              <div title="{{ 'pages.dashboard.created30days' | translate }}">
                <fa-icon [icon]="['fas', 'briefcase']" size="lg" *ngIf="act.type === 'Case'" data-toggle="tooltip"
                data-placement="top" aria-labelledby="caseIconDesc"></fa-icon>
              </div>
              <div title="{{ 'menu.updates.vulnerabilities' | translate }}">
                <fa-icon [icon]="['fas', 'radiation']" size="lg" *ngIf="act.type === 'Vulnerabilities'"
                data-placement="top" aria-labelledby="vulnIconDesc"></fa-icon>
              </div>
              <div title="{{ 'pages.updates.signal.signals' | translate }}">
                <fa-icon [icon]="['fas', 'broadcast-tower']" size="lg" *ngIf="act.type === 'Signals'" data-placement="top"
                aria-labelledby="signalIconDesc"></fa-icon>
              </div>
              
            </div>

            <div id="iconsDescription" class="visually-hidden">
              <span id="caseIconDesc">{{ 'pages.dashboard.created30days' | translate }}</span>
              <span id="vulnIconDesc">{{ 'menu.updates.vulnerabilities' | translate }}</span>
              <span id="signalIconDesc">{{ 'pages.updates.signal.signals' | translate }}</span>
            </div>

            <div class="col-xl-7 col-lg-7 col-md-9 col-sm-10 col-xs-10 faketablecell" style="text-align: left;">
              {{act.count}} {{act.description}}</div>
            <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketablecell"
              [ngClass]="act.iconStyle ?? '' | lowercase | removeWhiteSpace" *ngIf="!act.iconAlt"> <span
                class="tag p-1 w-100 text-black"
                [ngClass]="'P' + act.priority | lowercase | removeWhiteSpace">{{act.priority}}</span></div>
            <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketablecell"
              [ngClass]="act.iconStyle ?? '' | lowercase | removeWhiteSpace" placement="top" [ngbTooltip]="list"
              *ngIf="act.iconAlt"></div>
            <div class="col-xl-2 col-lg-2 d-none d-lg-block faketablecell">{{act.date | date: 'dd/MM/yyyy HH:mm'}}</div>
          </div>
        </div>

        <div class="card-body justify-content-center p-t-1 p-3" *ngIf="subsetActivities && loadedActivities">
          <ngb-pagination [collectionSize]="activities.length" pageSize="12" page="currentPage" [(page)]="currentPage"
            [maxSize]="10" aria-label="Pagination" (pageChange)="pageActivity($event)">
            <ng-template ngbPaginationPrevious></ng-template>
            <ng-template ngbPaginationNext></ng-template>
          </ngb-pagination>
        </div>

        <div class="row justify-content-center p-5" *ngIf="!loadedActivities">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
          </div>
        </div>

        <div class="row p-3 justify-content-center redborder-sides"
          *ngIf="subsetActivities && subsetActivities.length === 0">
          <div class="col-md-12 table-border justify-content-center">
            {{ 'pages.generic.noResults' | translate }}
          </div>
        </div>

      </div>
    </div>

</div>
