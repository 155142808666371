import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BacklogStatus } from 'src/app/shared/model/cybersocxdr/backlogStatus';

@Component({
  selector: 'app-backlog-sanity-score',
  templateUrl: './backlog-sanity-score.component.html',
})
export class BacklogSanityScoreComponent implements OnChanges {
  @Input()
  backlogStatus: BacklogStatus;
  actionTitleWithTotal: string;

  getBarStyle() {
    return { width: `${this.backlogStatus?.sanityScore ?? 0}%` };
  }

  ngOnChanges(changes: SimpleChanges) {
    // Vérifiez si backlogStatus a changé
    if (changes.backlogStatus) {
      this.updateActionTitle();
    }
  }

  updateActionTitle() {
    const olderThanAMonth = this.backlogStatus?.olderThanAMonth ?? 0;
    this.actionTitleWithTotal = `${olderThanAMonth} ` + `pages.cybersocxdr.backlog.olderThanAMonth`;
  }
}
