import { Component, OnInit, ViewChild } from '@angular/core';
import { DefaultLayoutComponent } from '../default-layout/default-layout.component';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { isAnonymousEnabled, setAnonymousEnabled } from 'src/app/cybersocxdr/utils';
import { environment } from 'src/environments/environment';
import { CybersocXDRDashboardSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { MenuLink } from 'src/app/cybersocxdr/components/sidemenu/sidemenu.component';
import { XtendedFiltersService } from 'src/app/cybersocxdr/xtendedfilters.service';
import { XtendedAnalyticsService } from 'src/app/cybersocxdr/xtended-analytics.service';
import { OktaAuthService } from 'src/app/app.service';
import { ContextService } from 'src/app/shared/services/context-service';
import { CybersocxdrService } from 'src/app/shared/services/xtendedsoc/cybersocxdr.service';
import { ContractService } from 'src/app/shared/model/cybersocxdr/incidentDataSourceEnum';

declare global {
  interface Window {
    umami: any;
  }
}

@Component({
  selector: 'app-xtended-layout',
  templateUrl: './xtended-layout.component.html',
  styleUrls: ['./xtended-layout.component.scss', './../default-layout/default-layout.component.scss'],
})
export class XtendedLayoutComponent extends DefaultLayoutComponent implements OnInit {
  anonymousEnabled: boolean;
  private umamiRouterEventSubscritpion: Subscription;
  private contractRouterEventSubscritpion: Subscription;
  private globalFilterSubscription: Subscription;
  isDarkThemeEnabled: boolean;

  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  constructor(
    private router: Router,
    public filterService: XtendedFiltersService,
    private xtendedAnalyticsService: XtendedAnalyticsService,
    private oktaAuthService: OktaAuthService,
    private cybersocxdrService: CybersocxdrService,
    contextService: ContextService
  ) {
    super();
    this.anonymousEnabled = isAnonymousEnabled();
    this.isDarkThemeEnabled = contextService.isDarkTheme();
  }

  menuLinks: MenuLink[] = [
    {
      name: 'pages.cybersocxdr.menu.home',
      route: '/xtended-soc',
      saveSearchDisplay: true,
      filterDisplay: false,
      haveTabs: false,
      sourceDisplay: true,
      sourceSelectable: false,
    },
    {
      name: 'pages.cybersocxdr.menu.incidents',
      route: '/xtended-soc/incidents',
      saveSearchDisplay: true,
      filterDisplay: true,
      haveTabs: false,
      sourceDisplay: true,
      sourceSelectable: true,
    },
    {
      name: 'pages.cybersocxdr.menu.dashboard',
      route: '/xtended-soc/dashboard',
      saveSearchDisplay: true,
      filterDisplay: true,
      haveTabs: true,
      sourceDisplay: true,
      sourceSelectable: false,
    },
    {
      name: 'pages.cybersocxdr.menu.4A3W',
      route: '/xtended-soc/4A3W',
      saveSearchDisplay: true,
      filterDisplay: true,
      haveTabs: true,
      sourceDisplay: true,
      sourceSelectable: true,
    },
    {
      name: 'pages.cybersocxdr.menu.sla',
      route: '/xtended-soc/sla',
      saveSearchDisplay: true,
      filterDisplay: true,
      haveTabs: true,
      sourceDisplay: true,
      sourceSelectable: false,
    },
    {
      name: 'pages.cybersocxdr.menu.contract',
      route: '/xtended-soc/contract',
      saveSearchDisplay: false,
      filterDisplay: false,
      haveTabs: false,
      sourceDisplay: true,
      sourceSelectable: true,
    },
  ];

  selectedContractId: number | null = null;

  handleAnonymousToggleChange() {
    setAnonymousEnabled(!this.anonymousEnabled);
    this.xtendedAnalyticsService.trackEvent('toogle anonymous mode', {
      anonymousEnabled: !this.anonymousEnabled,
    });
  }

  async ngOnInit(): Promise<void> {
    const userEmail = this.oktaAuthService.userDetails?.email;
    const isOcdUser = ['@orangecyberdefense.com', '@orange.com'].some((domain) => userEmail?.endsWith(domain));

    const umamiScript = document.getElementById('xtended-umami-tracking-script');
    if (!umamiScript) {
      let script = document.createElement('script');
      script.setAttribute('id', 'xtended-umami-tracking-script');
      script.setAttribute('src', 'https://umami-admin-portal.cybersocxdr.fr/script.js');
      script.setAttribute('data-website-id', environment?.xtended_analytic_website_id ?? '');
      script.setAttribute('data-auto-track', 'false');

      script.onload = () => {
        window.umami?.track((props) => ({
          ...props,
          url: this.router.url,
          referrer: `https://${this.oktaAuthService.selectedTenant}${isOcdUser ? '-ocd' : ''}`,
        }));

        this.umamiRouterEventSubscritpion = this.router.events
          .pipe(
            filter((event) => event instanceof NavigationEnd),
            filter((event: NavigationEnd) => event.urlAfterRedirects.startsWith('/xtended-soc'))
          )
          .subscribe((event) => {
            window.umami?.track((props) => ({
              ...props,
              url: event.urlAfterRedirects,
              referrer: `https://${this.oktaAuthService.selectedTenant}${isOcdUser ? '-ocd' : ''}`,
            }));
          });
      };

      document.head.appendChild(script);
    }

    this.globalFilterSubscription = this.filterService.filterEvent.subscribe((searchCriteria) => {
      this.search(searchCriteria);
    });

    //Fetch client info
    this.filterService.clientInfo = await this.cybersocxdrService.getClientInfo();

    this.selectedContractId = this.filterService.selectedContract?.contractId ?? null;

    this.contractRouterEventSubscritpion = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => event.urlAfterRedirects.startsWith('/xtended-soc'))
      )
      .subscribe((event) => {
        const currentPage = this.getCurrentPage();
        if (currentPage && !currentPage.sourceSelectable) {
          // If the current page does not have source selectable, we set the selcted contract to Xtended contract
          const xtendedContract = this.filterService.clientInfo?.contracts.find(
            (c) => c.service === ContractService.XTENDED
          );
          if (xtendedContract) {
            this.onContractChange(xtendedContract.contractId);
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.umamiRouterEventSubscritpion) this.umamiRouterEventSubscritpion.unsubscribe();
    if (this.globalFilterSubscription) this.globalFilterSubscription.unsubscribe();
    if (this.contractRouterEventSubscritpion) this.contractRouterEventSubscritpion.unsubscribe();
  }

  getCurrentPage() {
    return (
      this.menuLinks.find(
        (menu) =>
          menu.route === this.router.url ||
          (this.router.url.includes('#') && menu.route === this.router.url.split('#')[0]) ||
          (menu.haveTabs && this.router.url.startsWith(menu.route))
      ) ?? null
    );
  }

  search(searchCritera: CybersocXDRDashboardSearchCriteria) {
    this.filterService.checkCurrentPreset();
    (this?.outlet?.component as any)?.onSearch?.(searchCritera);
  }

  public onActivate(componentRef) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  onContractChange(contractId: number) {
    this.selectedContractId = +contractId;
    this.filterService.selectedContract = +contractId;
  }
}
