import { Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Alert, GPAlert } from '../../../model/alert';
import { AlertService } from '../../../services/alert.service';
import { ContextService } from '../../../services/context-service';
import { LocalStorageItem } from '../../../model/shared-items';
import { Router } from '@angular/router';
import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {

  @ViewChild('toastContainer', { read: ViewContainerRef }) entry: ViewContainerRef;

  public alerts: Alert[];
  public gpAlerts: GPAlert[];
  public hasPadding: boolean;

  constructor(
    public contextService: ContextService,
    public alertService: AlertService,
    public router: Router,
  ) { }

  ngOnInit() {
    const ids: string = localStorage.getItem(LocalStorageItem.gpAlerts);
    if (ids) {
      this.gpAlerts = this.contextService.gpNotifications.filter(gpAlerts => !ids.includes(gpAlerts._id.$oid));
    } else {
      this.gpAlerts = this.contextService.gpNotifications;
    }

    this.hasPadding = !this.router.url.startsWith('/xtended-soc');

    this.alertService.toastEvent$.subscribe(toast => {
      const component: ComponentRef<ToastComponent> = this.entry.createComponent(ToastComponent);
      component.instance.level = toast.level;
      component.instance.message = toast.message;
    })
  }

  closeAlert(alert: Alert) {
    this.alertService.close(alert);
  }

  public closeGPAlert(alert: GPAlert) {

    // update local storage
    const gpAlertStorage: string[] = JSON.parse(localStorage.getItem(LocalStorageItem.gpAlerts));
    const hideAlerts = gpAlertStorage ? gpAlertStorage : [];
    hideAlerts.push(alert._id.$oid);
    localStorage.setItem(LocalStorageItem.gpAlerts, JSON.stringify(hideAlerts));

    // remove alert
    this.gpAlerts.splice(this.gpAlerts.indexOf(alert), 1);
  }
}


