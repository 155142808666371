<!-- SERVICE NAME -->
<div
  class="p-3 d-none d-lg-flex justify-content-between bg-body-secondary border-light border-bottom border-1"
>
  <a
    routerLink="/xtended-soc"
    class="d-flex align-items-center justify-content-center link-dark text-decoration-none text-center"
  >
    <span class="fs-6 fw-semibold sidebar-label">Xtended SOC</span>
  </a>
</div>

<!-- NAVIGATION ITEMS -->
<ul
  class="list-unstyled ps-0 m-0 d-flex flex-row flex-lg-column overflow-x-auto"
>
  <li *ngFor="let item of menuLinks" class="mb-lg-2">
    <a
      routerLink="{{ item.route }}"
      class="btn d-inline-flex justify-content-between border-0 collapsed w-100 px-3 py-2 {{
        isActiveMenu(item)
          ? 'bg-body-secondary border-start border-3 border-primary'
          : ''
      }}"
    >
      <div class="sidebar-label">{{ item.name | translate }}</div>
    </a>
  </li>
</ul>
