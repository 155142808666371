import { Component, Input } from '@angular/core';
import { ContextService } from 'src/app/shared/services/context-service';

export interface Tab {
  titleKey: string;
  value: string;
  url: string;
}

@Component({
  selector: 'app-tabbar',
  templateUrl: './tabbar.component.html',
})
export class TabBarComponent {
  @Input()
  tabs: Array<Tab> = [];

  @Input()
  currentTab: string;

  isDarkThemeEnabled: boolean;

  constructor(contextService: ContextService) {
    this.isDarkThemeEnabled = contextService.isDarkTheme();
  }

  getTabClasses(tabValue: string) {
    const tab = this.tabs.find((t) => t.value === tabValue);
    if (tab) {
      return `nav-link d-flex align-items-center gap-2 tab-button ${tab.value === this.currentTab && 'active'}`;
    }
    return '';
  }
}
