import { constants } from '../constants';
import { HttpParams } from '@angular/common/http';
import { Utilities } from '../utilities';
import { IncidentDataSource } from './cybersocxdr/incidentDataSourceEnum';

export class DefaultSearchCriteria {
  limit = constants.scrollIterationLoading;
  skip = 0;
  sort: string;
  sortOrder: number;
  count: boolean; // return count or the records
  SNCount: boolean;

  /**
   * For each DateRange element, check if the date range is valid (means the 'from' date is anterior or equal to the 'to' date)
   * @param dateCategoriesToCheck - an array of the DateRange elements to check
   * @returns true if all date Ranges are valid
   */
  checkDateRanges(dateCategoriesToCheck: DateRange[]): boolean {
    const checkResults: boolean[] = [];
    dateCategoriesToCheck.forEach((dateCategory) => {
      if (this[`${dateCategory}From`] && this[`${dateCategory}To`]) {
        checkResults.push(Utilities.checkDateRange(this[`${dateCategory}From`], this[`${dateCategory}To`]));
      } else {
        checkResults.push(true);
      }
    });
    return !checkResults.includes(false) && checkResults.includes(true);
  }
}

export class SignalSearchCriteria extends DefaultSearchCriteria {
  name: string; // used if these search criteria are persisted as a saved search
  _id: string; // used if this search criteria has been save, id to allow delete
  categories: string[];
  tags: string[];
  emergency: boolean;
  status: string;
  urgency: string[];
  mainCategory: string;
  updateDateFrom: string; // in format YYYY-MM-DD
  updateDateTo: string; // in format YYYY-MM-DD
  publishDateFrom: string; // in format YYYY-MM-DD
  publishDateTo: string; // in format YYYY-MM-DD
  ref: string; // part of query search
  summary: string; // part of query search
  whatYouWillHear: string; // part of query search
  whatItMeans: string; // part of query search
  whatWeAreDoing: string; // part of query search
  whatYouShouldDo: string; // part of query search
  readMore: string; // part of query search
  ioc: string; // part of query search
  query: string; // will search any of the string fields noted above
  iocQuery: string; // will search all the above ioc fields
  returnStub: boolean; // return the basic attributes for summary screens
  exportPDF = false; // for exporting pdf to gcp
  PDFName = '';
  pdfAccessLevel = '';
}

export class SupportCaseSearchCriteria extends DefaultSearchCriteria {
  name: string; // used if these search criteria are persisted as a saved search
  _id: string; // used if this search criteria has been save, id to allow delete
  caseRefs: string[]; // used to load flagged list in loadList end point
  //Security Incidents
  actor: boolean;
  action: boolean;
  attributes: boolean;
  what: boolean;
  why: boolean;
  who: boolean;
  securityAnaltyics: boolean;
  securityAnalyt: boolean;
  caseNumber: string;
  description: string;
  createdFrom: string;
  createdTo: string;
  updatedFrom: string;
  updatedTo: string;
  closedDateFrom: string;
  closedDateTo: string;
  asset: string;
  assetId: string;
  type: string;
  category: string;
  priority: string;
  subject: string;
  status: string[];
  contact: string;
  contactEmail: string;
  contactEmailUnassigned: string;
  createdByEmail: string;
  escalation: string;
  export: boolean;
  exportColumns: string[];
  hideChildCases: boolean;
  pdfName: string;
  pdfAccessLevel = '0';
  extended: boolean;
  resolutionReport: boolean;
  benchmarking: boolean;
  currentPeriod: 'last30days' | 'lastFullCalendarMonth';
  comparisonPeriod: 'previous30days' | 'previousFullCalendarMonth';
  worldWatchSignal: string;
  byPassSF: boolean;
  supportOverViewCacheByPass: boolean;
  returnStub: boolean;
  sortBy: string;
  trueFalsePositives: string;
  createdPeriod: string;
  csocCases: boolean;
  service: string;
  forceTMLimit: boolean;
  requestTracker: { requestTracker: boolean; count: number } = {
    requestTracker: false,
    count: 0,
  };

  //For search function
  likeSearch: string;
}

export class SignalLoadListCriteria extends DefaultSearchCriteria {
  jiraRefs: string[]; // used to load flagged list in loadList end point
  returnStub: boolean; // return the basic attributes for summary screens
}

export class VulnVendorSearchCriteria extends DefaultSearchCriteria {
  vendor: string; // will search as a like
  typeahead: boolean; // search begins with, or anywhere
}

export class VulnProductSearchCriteria extends DefaultSearchCriteria {
  product: string; // will search as a like
  vendor: string; // will search as a fixed string
  typeahead: boolean; // search begins with, or anywhere
}

export class VulnSearchCriteria extends DefaultSearchCriteria {
  name: string; // used if these search criteria are persisted as a saved search
  _id: string; // used if this search criteria has been save, id to allow delete
  cveRefs: string[]; // used to load flagged list in loadList end point
  products: string[]; // used to load watched list in loadProductList end point
  cve: string;
  cwe: string;
  cpe: string;
  refSource: string;
  refName: string;
  product: string;
  vendor: string;
  severity: string;
  severities: string[];
  description: string;
  pubDateFrom: string; // in format YYYY-MM-DD
  pubDateTo: string; // in format YYYY-MM-DD
  modDateFrom: string; // in format YYYY-MM-DD
  modDateTo: string; // in format YYYY-MM-DD
  returnStub: boolean; // return subset for list view
  exportPDF = false; // for exporting pdf to gcp
  PDFName = '';
  pdfAccessLevel = '';
}

export class VulnSeverityStatsCriteria {
  pubDateFrom: string; // in format YYYY-MM-DD
  pubDateTo: string; // in format YYYY-MM-DD
  attributeName: string; // attribute
}

export class UserPreferenceArrayUpdateCriteria {
  topLevelObject: string; // allows arrayName to be a child, not required. If not specified arrayName at top level
  arrayName: string;
  item: any; // object, if updating existing requires an _id attribute
  delete: boolean; // are we deleting
}

export class SignalStatsCriteria {
  attributeName: string;
  publishDateFrom: string;
  publishDateTo: string;
}

export class ActivitySearchCriteria extends DefaultSearchCriteria {
  type: ActivityType;
  dateFrom: string;
  dateTo: string;
}

export enum ActivityType {
  cases = 'cases',
  worldWatch = 'worldWatch',
  vulnerabilities = 'vulnerabilities',
}

export type CybersocXDRDashboardSearchCriteriaKey = keyof CybersocXDRDashboardSearchCriteria;
export class CybersocXDRDashboardSearchCriteria {
  name: string; // used if these search criteria are persisted as a saved search
  _id: string; // used if this search criteria has been save, id to allow delete

  createdFrom: string;
  createdTo: string;
  closedFrom: string;
  closedTo: string;

  subject: string = '';
  id: string = '';

  slaNotification: string[] = [];
  category: string[] = [];
  owner: string[] = [];
  waitingFor: string[] = [];
  closureVerdict: string[] = [];
  severitys: string[] = [];
  status: string[] = [];
  slaInvestigationStatusBroken: boolean;
  slaNotificationStatusBroken: boolean;
  groupbyField: string;
  repartitionGroupbyLevel: number;
  subGroupbyField: string;
  groupLevel1: string[] = [];
  groupLevel2: string[] = [];

  limit: number;
  skip: number;
  sortBy: { field: string; type: string };

  incidentDataSource: IncidentDataSource = IncidentDataSource.ELASTIC;
  constructor() {
    this.skip = 0;
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
enum FacetKey {
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  START_CLOSED_TIME = 'start_closed_time',
  END_CLOSED_TIME = 'end_closed_time',
  SLA_INVESTIGATION = 'sla_investigation',
  SLA_NOTIFICATION = 'sla_notification',
  SEVERITY = 'severity',
  STATUS = 'status',
  WAITING_FOR = 'waiting_for',
  CLOSURE_VERDICT = 'closure_verdict',
  SUBJECT = 'subject',
  ID = 'id',
  CATEGORY = 'category',
  OWNER = 'owner',
  GROUPLEVEL1 = 'groupLevel1',
  GROUPLEVEL2 = 'groupLevel2',

  UNIMPLEMENTED = 'unimplemented',
}

const criteriaToHttpParamKey: Partial<Record<CybersocXDRDashboardSearchCriteriaKey, string | FacetKey>> = {
  createdFrom: FacetKey.START_TIME,
  createdTo: FacetKey.END_TIME,
  closedFrom: FacetKey.START_CLOSED_TIME,
  closedTo: FacetKey.END_CLOSED_TIME,
  status: FacetKey.STATUS,
  waitingFor: FacetKey.WAITING_FOR,
  closureVerdict: FacetKey.CLOSURE_VERDICT,
  severitys: FacetKey.SEVERITY,
  groupLevel1: FacetKey.GROUPLEVEL1,
  groupLevel2: FacetKey.GROUPLEVEL2,
  slaNotification: FacetKey.SLA_NOTIFICATION,
  category: FacetKey.CATEGORY,
  owner: FacetKey.OWNER,
  id: FacetKey.ID,
  subject: FacetKey.SUBJECT,

  // Other fields
  repartitionGroupbyLevel: 'groupbyLevel',
  groupbyField: 'groupbyField',
  subGroupbyField: 'subGroupbyField',
  limit: 'limit',
  skip: 'skip',
  sortBy: 'sortBy',
  incidentDataSource: 'incidentDataSource',
  slaInvestigationStatusBroken: 'sla_investigation',
  slaNotificationStatusBroken: 'sla_notification',
};

const getCriteriaFacet = (
  searchCriteria: CybersocXDRDashboardSearchCriteria,
  criteria: CybersocXDRDashboardSearchCriteriaKey
) => {
  switch (criteria) {
    case 'severitys':
    case 'status':
    case 'owner':
    case 'category':
    case 'waitingFor':
    case 'closureVerdict':
    case 'slaNotification':
    case 'groupLevel1':
    case 'groupLevel2':
      if (searchCriteria[criteria])
        return searchCriteria[criteria].map((criteriaItem) => {
          if (criteriaItem.startsWith('-')) {
            return `-${criteriaToHttpParamKey[criteria]}:${criteriaItem.substring(1)}`;
          }

          return `${criteriaToHttpParamKey[criteria]}:${criteriaItem}`;
        });
      break;

    // Optionnal simple strings, facets
    case 'closedTo':
    case 'closedFrom':
    case 'createdFrom':
    case 'createdTo':
      if (searchCriteria[criteria]) {
        return [`${criteriaToHttpParamKey[criteria]}:${searchCriteria[criteria]}`];
      }
      break;

    case 'id':
    case 'subject':
      //Advanced search if string is not empty
      if (searchCriteria[criteria] && searchCriteria[criteria].trim().length > 0) {
        return [`${criteriaToHttpParamKey[criteria]}:${searchCriteria[criteria]}`];
      }
      break;

    // Sla handling
    case 'slaInvestigationStatusBroken':
    case 'slaNotificationStatusBroken':
      if (searchCriteria[criteria]) {
        return [`${criteriaToHttpParamKey[criteria]}:break`];
      }
      break;
  }
  return undefined;
};

/** Translate each valid param into facets, an insert them into the provided http params */
export const buildHttpParams = (
  searchCriteria: CybersocXDRDashboardSearchCriteria,
  paramsToFill: HttpParams,
  criterias: CybersocXDRDashboardSearchCriteriaKey[],
  includeDefault = true
): HttpParams => {
  if (includeDefault) {
    const defaultCriterias: CybersocXDRDashboardSearchCriteriaKey[] = [
      'id',
      'subject',
      'createdFrom',
      'createdTo',
      'severitys',
      'status',
      'category',
      'waitingFor',
      'closureVerdict',
      'owner',
      'closedFrom',
      'closedTo',
      'incidentDataSource',
      'slaNotification',
    ];
    criterias.push(...defaultCriterias);
  }

  let facets: string[][] = new Array();

  for (const criteria of criterias) {
    const facet = getCriteriaFacet(searchCriteria, criteria);
    if (facet) {
      facets.push(facet);
    } else {
      switch (criteria) {
        case 'repartitionGroupbyLevel':
        case 'groupbyField':
        case 'subGroupbyField':
        case 'skip':
        case 'sortBy':
        case 'limit':
        case 'incidentDataSource':
          if (searchCriteria[criteria] !== null && searchCriteria[criteria] !== undefined) {
            paramsToFill = paramsToFill.append(criteriaToHttpParamKey[criteria], searchCriteria[criteria].toString());
          }
          break;
      }
    }
  }
  // Filter out empty facets
  facets = facets.filter((orFacets) => orFacets.length >= 1);
  paramsToFill = paramsToFill.set('facets', JSON.stringify(facets));
  return paramsToFill;
};

export enum DateRange {
  created = 'created',
  updated = 'updated',
  closedDate = 'closedDate',
  publishDate = 'publishDate',
  pubDate = 'pubDate',
  date = 'date',
}
