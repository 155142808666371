import { Component, Input } from '@angular/core';
import { ContextService } from 'src/app/shared/services/context-service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
})
export class CardComponent {
  
  @Input() title?: string;
  
  /* The data to pass for a link to appear on the card, alternatively you can pass a children named action */
  @Input() actionTitle?: string;
  @Input() actionRoute?: string;

  isDarkThemeEnabled: boolean;
  constructor(contextService: ContextService) {
    this.isDarkThemeEnabled = contextService.isDarkTheme();
  }
}
