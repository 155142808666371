import { Component } from '@angular/core';
import { constants } from 'src/app/shared/constants';
import { ContextService } from 'src/app/shared/services/context-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(
    public contextService: ContextService
  ) {}

  // network info
  public twitterlabel = constants.networks.twitterLabel;
  public linkedinlabel = constants.networks.linkedinLabel;
  public twitterUrl = constants.networks.twitter;
  public linkedinUrl = constants.networks.linkedin;

  // contact mails
  public mailToPortalSupport = constants.mailToPortalSupport;
  public mailToInfoSec = constants.mailToInfoSec;

}
