import { Component, Input, OnInit } from '@angular/core';
import { Signal } from 'src/app/shared/model/signal';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { WorldWatchService } from 'src/app/shared/services/worldwatch.service';
import { AdvisoryFilter, ReducedAdvisory } from 'src/app/updates/worldwatch/dashboard/worldwatch.component';
import { WorldWatchUtilService } from 'src/app/updates/worldwatch/worldwatch-util-service';

@Component({
  selector: 'app-worldwatch-carousel',
  templateUrl: './worldwatch-carousel.component.html',
  styleUrls: ['./worldwatch-carousel.component.scss']
})
export class WorldwatchCarouselComponent implements OnInit {

  public latestSignals: ReducedAdvisory[];
  public loadedSignals = false;

  constructor(
    private worldWatchService: WorldWatchService,
    private alertService: AlertService,
    private worldWatchUtilService: WorldWatchUtilService
  ) { }

  ngOnInit(): void {
    // load the latest activity and save the latest signals in session storage
    // for use in the signals list
    const filter = new AdvisoryFilter();
    filter.limit = 7;

    this.worldWatchService.getAdvisories(filter).subscribe(
      {
        next: response => {
          this.latestSignals = response.items;
          this.latestSignals.forEach(advisory => {
            advisory.severityStyle = this.worldWatchUtilService.getSeverityStyle(advisory.severity);
            advisory.severityLabel = this.worldWatchUtilService.getSeverityLabel(advisory.severity);
          })
          this.loadedSignals = true;
        },
        error: (err) => {
          this.loadedSignals = true;
          this.alertService.handlerError(err);
        }
      }
    )
  }

}
