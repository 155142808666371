import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { buildHttpParams, CybersocXDRDashboardSearchCriteria } from '../../model/searchCriteria';
import { XtendedBaseService } from './xtended-base.service';
import { RepartitionChartItem } from '../../model/cybersocxdr/charts/common';
import { Xtended4A3WFacet } from '../../model/cybersocxdr/facets';

const ROOT_URL = environment.endpoints.cybersocxdr;
const CHART_URL = ROOT_URL + 'charts';

@Injectable()
export class XtendedChartService extends XtendedBaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  async get4A3WByField(
    searchCriteria: CybersocXDRDashboardSearchCriteria,
    field: Xtended4A3WFacet
  ): Promise<RepartitionChartItem[]> {
    return await this.genericGet<any>(`${CHART_URL}/4A3W/${field}`, {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async get4ASankey(
    searchCriteria: CybersocXDRDashboardSearchCriteria
  ): Promise<[{ id: string; name: string }[], string[][]]> {
    return await this.genericGet<any>(`${CHART_URL}/4A3W/sankey/4A`, {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async get3WSankey(
    searchCriteria: CybersocXDRDashboardSearchCriteria
  ): Promise<[{ id: string; name: string }[], string[][]]> {
    return await this.genericGet<any>(`${CHART_URL}/4A3W/sankey/3W`, {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }
}
