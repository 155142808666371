import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { constants } from '../../constants';
import { Case } from '../../model/itsm';
import { ChartType, Priorities, PrioritiesOrder } from 'src/app/shared/model/activity';

const evalPriority = (item: Case) => {
  switch (item.priority) {
    case '1 - Business Critical' : return 'critical';
    case '2 - High' : return 'high';
    case '3 - Medium' : return 'medium';
    case '4 - Low' : return 'low';
    default : return 'unknown';
  }
};

@Component({
  selector: 'app-incidents-by-priority',
  templateUrl: './incidents-by-priority.component.html',
  styleUrls: ['./incidents-by-priority.component.scss']
})
export class IncidentsByPriorityComponent implements OnInit {

  //data
  @Input()
  currentCases: Case[];
  @Input()
  previousCases: Case[];
  @Input()
  monthCases: Case[];
  @Input()
  currentPeriodName: string;
  @Input()
  previousPeriodName: string;
  monthPeriodName = 'Last 3 months';

  monthNames = constants.monthNames;
  // calculated data
  currentData = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    total: 0
  };
  previousData = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    total: 0
  };
  highestMonthCases = {
    critical: {} as any,
    high: {} as any,
    medium: {} as any,
    low: {} as any,
    total: {} as any
  };
  lowestMonthCases = {
    critical: {} as any,
    high: {} as any,
    medium: {} as any,
    low: {} as any,
    total: {} as any
  };
  highestMonth: number;
  lowestMonth: number;


  totalTrend: string;
  criticalTrend: string;
  highTrend: string;
  mediumTrend: string;
  lowTrend: string;

  priorities = ['critical', 'high', 'medium', 'low'];

  // show Cases
  showIncidentByPriorityBoxesCases: Case[] = [];
  showIncidentByPriorityGraphCases: Case[] = [];

  ibpViewGraph = true;

  //Highcharts
  Highcharts: typeof Highcharts = Highcharts;
  incidentsChartOptions: Highcharts.Options;
  updateIncidentChart = false;
  currentFullName: string;
  previousFullName: string;
  monthFullName: string;

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // current and previous period
    this.currentCases.forEach((item)=>{
      const itemPriority = evalPriority(item);
      if(this.priorities.includes(itemPriority)){
        this.currentData[itemPriority] += 1;
        this.currentData.total += 1;
      }
    });
    this.previousCases.forEach((item)=>{
      const itemPriority = evalPriority(item);
      if(this.priorities.includes(itemPriority)){
        this.previousData[itemPriority] += 1;
        this.previousData.total += 1;
      }
    });

    this.totalTrend = this.currentData.total === this.previousData.total ? 'equal' :
                  this.currentData.total > this.previousData.total ? 'up' : 'down';
    this.criticalTrend= this.currentData.critical === this.previousData.critical ? 'equal' :
                  this.currentData.critical > this.previousData.critical ? 'up' : 'down';
    this.highTrend= this.currentData.high === this.previousData.high ? 'equal' :
                  this.currentData.high > this.previousData.high ? 'up' : 'down';
    this.mediumTrend= this.currentData.medium === this.previousData.medium ? 'equal' :
                  this.currentData.medium > this.previousData.medium ? 'up' : 'down';
    this.lowTrend= this.currentData.low === this.previousData.low ? 'equal' :
                  this.currentData.low > this.previousData.low ? 'up' : 'down';

    // month period
    const fetchedMonths = {};
    this.monthCases.forEach((item) => {
      const date = new Date(item.createdDate);
      const month = date.getMonth();
      const year = date.getFullYear();
      const monthYear = `${month},${year}`;

      if(fetchedMonths[monthYear] === undefined) {
        fetchedMonths[monthYear] = 0;
      }
    });

    const dic = {
      critical : JSON.parse(JSON.stringify(fetchedMonths)),
      high : JSON.parse(JSON.stringify(fetchedMonths)),
      medium : JSON.parse(JSON.stringify(fetchedMonths)),
      low : JSON.parse(JSON.stringify(fetchedMonths)),
      total : JSON.parse(JSON.stringify(fetchedMonths))
    };
    this.monthCases.forEach((item) => {
      const date = new Date(item.createdDate);
      const priority = evalPriority(item);
      const month = date.getMonth();
      const year = date.getFullYear();
      const monthYear = `${month},${year}`;
      if(this.priorities.includes(priority)){
        dic[priority][monthYear] += 1;
        dic.total[monthYear] += 1;
      }
    });

    const highest = {
      critical : -1,
      high : -1,
      medium : -1,
      low : -1,
      total : -1
    };
    const lowest = {
      critical : 10000000,
      high : 10000000,
      medium : 10000000,
      low : 10000000,
      total : 10000000
    };
    const highestMonths = {
      critical : {month:0, year:0, amount:0},
      high : {month:0, year:0, amount:0},
      medium : {month:0, year:0, amount:0},
      low : {month:0, year:0, amount:0},
      total: {month:0, year:0, amount:0}
    };
    const lowestMonths = {
      critical : {month:0, year:0, amount:0},
      high : {month:0, year:0, amount:0},
      medium : {month:0, year:0, amount:0},
      low : {month:0, year:0, amount:0},
      total: {month:0, year:0, amount:0}
    };

    Object.keys(dic).forEach((priority)=>{
      Object.keys(dic[priority]).forEach((monthYear)=>{
        const [month, year] = monthYear.split(',');
        const monthYearName = `${constants.monthNames[Number(month)]} ${year}`;

        if(dic[priority][monthYear] > highest[priority]){
          highest[priority] = dic[priority][monthYear];
          highestMonths[priority] = {month: monthYearName, year, amount: highest[priority]};
        }
        if(dic[priority][monthYear] < lowest[priority]){
          lowest[priority] = dic[priority][monthYear];
          lowestMonths[priority] = {month: monthYearName, year, amount: lowest[priority]};
        }
      });
    });

    this.highestMonthCases = highestMonths;
    this.lowestMonthCases = lowestMonths;

    // graph
    const currentCount = this.seperatePriorityWise(this.currentCases);
    const previousCount = this.seperatePriorityWise(this.previousCases);
    const threeCount = this.seperatePriorityWise(this.monthCases);
    this.currentFullName = this.currentPeriodName === 'last30days' ?
      this.translateService.instant('pages.generic.last30days') :
      this.translateService.instant('pages.generic.lastFullCalendarMonth');
    this.previousFullName = this.previousPeriodName === 'previous30days' ?
      this.translateService.instant('pages.generic.previous30days') :
      this.translateService.instant('pages.generic.previousFullCalendarMonth');
    this.monthFullName = this.translateService.instant('pages.generic.last3months');
    const series: Highcharts.SeriesColumnOptions[] = [
      {
        name: this.currentFullName,
        data: currentCount,
        type: ChartType.column
      },
      {
        name: this.previousFullName,
        data: previousCount,
        type: ChartType.column
      },
      {
        name: this.monthFullName,
        data: threeCount,
        type: ChartType.column
      },
    ];

    const pointClick: Highcharts.PointClickCallbackFunction = (e) => {
      this.resetCases();
      //@ts-ignore
      const priority = e.point.category.toLowerCase(); // column
      const period = e.point.series.name; // serie

      let cases = [];

      if(period === this.currentFullName) {
        cases = this.currentCases;
      }
      if(period === this.previousFullName) {
        cases = this.previousCases;
      }
      if (period === this.monthFullName) {
        cases = this.monthCases;
      }

      cases.forEach((item) => {
        const itemPriority = item.priority.split(' - ')[1].toLowerCase();
        if (itemPriority === priority){
            this.showIncidentByPriorityGraphCases.push(item);
        }
      });
    };

    this.updateIncidentChart = true;

    this.drawGraph(series, pointClick, 'Number of Cases');
  }

  selectCasesByMonth(monthYear: string, selected: string) {
    const [month, year] = monthYear.split(' ');
    const value = this.monthCases.filter( c => {
      const date = new Date(c.createdDate);
      const caseMonth = constants.monthNames[Number(date.getMonth())];
      const caseYear = String(date.getFullYear());
      const priority = evalPriority(c);
      if(selected === 'total') {
        return caseYear === year && caseMonth === month && this.priorities.includes(priority);
      }
      else {
        return caseYear === year && caseMonth === month && selected === priority;
      }
    });
    this.showIncidentByPriorityBoxesCases = value;
  }

  selectCasesByPeriod(period: 'current' | 'previous', selected: string) {
    if(period === 'current') {
      if(selected === 'total') {
        this.showIncidentByPriorityBoxesCases = this.currentCases.filter( c => this.priorities.includes(evalPriority(c)));
      } else {
        this.showIncidentByPriorityBoxesCases = this.currentCases.filter( c => evalPriority(c) === selected);
      }
    } else {
      if(selected === 'total') {
        this.showIncidentByPriorityBoxesCases = this.previousCases.filter( c => this.priorities.includes(evalPriority(c)));
      } else {
        this.showIncidentByPriorityBoxesCases = this.previousCases.filter( c => evalPriority(c) === selected);
      }
    }
  }

  drawGraph(series: Highcharts.SeriesColumnOptions[], pointClick: Highcharts.PointClickCallbackFunction, yAxisTitle: string) {

    this.incidentsChartOptions = {
      chart: {
        type: ChartType.column
    },
    title: {
        text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
        categories: [
          Priorities.critical,
          Priorities.high,
          Priorities.medium,
          Priorities.low
        ],
        crosshair: true
    },
    yAxis: {
        title: {
            useHTML: true,
            text: yAxisTitle
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:11px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0,
            point : {
              events: {
                click : pointClick,
                select : () => null,
                unselect : (_) => null
              }
            }
          }
      },
      series
    };
  }

  seperatePriorityWise(data: Case[]){
    const businessCritialLength = data.filter(a => a.priority === PrioritiesOrder.critical).length;
    const highLength = data.filter(a => a.priority === PrioritiesOrder.high).length;
    const mediumLength = data.filter(a => a.priority === PrioritiesOrder.medium).length;
    const lowLength = data.filter(a => a.priority === PrioritiesOrder.low).length;
    return [businessCritialLength, highLength, mediumLength, lowLength];
  }

  resetCases() {
    this.showIncidentByPriorityBoxesCases = [];
    this.showIncidentByPriorityGraphCases = [];
  }
}
