
<div class="section bg-black">
  <div class="global">

    <!-- header-->
    <div class="welcome">
      <div>
        <fa-icon *ngIf="!userPreferenceService.profilePicture" [icon]="['fas', 'user-large']" size="6x"></fa-icon>
        <figure *ngIf="userPreferenceService.profilePicture">
          <img [src]="userPreferenceService.profilePicture" alt="user's picture">
        </figure>
      </div>
      <div class="user-info" >
          <h3 class="primary">{{ 'pages.dashboard.welcome' | translate }}</h3>
          <div>{{name}}</div>
          <div>{{selectedTenant | uppercase}}</div>
      </div>
    </div>
  </div>
  <div>

    <!-- last login -->
    <h3 class="primary">{{ 'pages.dashboard.sinceLogin' | translate }} <app-help message="{{ 'pages.dashboard.sinceLoginDesc' | translate }}"></app-help></h3>
    <div *ngIf="loaded">
      <div class="p-0" *ngFor="let act of sinceLastLogin, let index = index" style="width:100%">
            <a class="click" [routerLink]="[act.url]" routerLinkActive="router-link-active">
                <ul>
                  <li class="last-login">
                    <span class="me-2" [ngStyle]="{'color': colorTab[index]}">{{act.count}}</span>
                    <span class="desc">{{act.description | translate}}</span>
                  </li>
                </ul>
            </a>
        </div>
    </div>  
    <div class="d-flex justify-content-center pt-5">
      <div *ngIf="!loaded" class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</div>
