<form>
  <div class="row mb-3 mb-sm-4">
    <div class="col-6 col-sm-2 mt-3">
      <label for="publishDate" class="form-label">{{
        'pages.cybersocxdr.filters.startDate' | translate
      }}</label>
      <input
        type="date"
        id="startDate"
        name="startDate"
        aria-describedby="startDate"
        class="form-control mt-1"
        [(ngModel)]="globalFilters.searchCriteria.createdFrom"
        [disabled]="isDynamicCreatedDate()"
        (change)="globalFilters.serializeSeachCriteria()"
      />
    </div>
    <div class="col-6 col-sm-2 mt-3">
      <label for="publishDate" class="form-label">{{
        'pages.cybersocxdr.filters.endDate' | translate
      }}</label>
      <input
        type="date"
        id="endDate"
        name="endDate"
        aria-describedby="endDate"
        class="form-control mt-1"
        [disabled]="isDynamicCreatedDate()"
        [(ngModel)]="globalFilters.searchCriteria.createdTo"
        (change)="globalFilters.serializeSeachCriteria()"
      />
    </div>
    <div class="col-6 col-sm-2 mt-3">
      <label class="form-label" for="publishDate">{{
        'pages.cybersocxdr.filters.created' | translate
      }}</label>
      <select
        class="form-select mt-1"
        name="createdPeriod"
        aria-describedby="createdPeriod"
        [(ngModel)]="displayCreated"
        (change)="changeCreatedDatePeriode($event.target.value)"
      >
        <option value="custom">
          {{ 'pages.cybersocxdr.filters.customPeriod' | translate }}
        </option>
        <option *ngFor="let period of periodOptions" [value]="period">
          {{ 'pages.cybersocxdr.filters.' + period | translate }}
        </option>
      </select>
    </div>
    <div class="col-12 col-sm-3 mt-3">
      <label for="requiredSelect" class="form-label">{{
        'pages.cybersocxdr.filters.severity' | translate
      }}</label>
      <app-searchabledropdown
        title="severity"
        [items]="severitysOptions"
        [selectedItems]="globalFilters.searchCriteria.severitys"
        (onCheck)="checkItem($event[1], $event[0], 'severitys')"
      />
    </div>
    <div class="col-12 col-sm-3 mt-3">
      <label for="requiredSelect" class="form-label">{{
        'pages.cybersocxdr.filters.status' | translate
      }}</label>
      <app-searchabledropdown
        title="status"
        [items]="incidentStatus"
        [selectedItems]="globalFilters.searchCriteria.status"
        (onCheck)="checkItem($event[1], $event[0], 'status')"
      />
    </div>

    @if(repartitions && repartitions.length > 0){
    <div class="col-6 col-sm-3 mt-3">
      <label for="requiredSelect" class="form-label">{{
        getCustomGroupbyNameKey(0) | translate
      }}</label>
      <div class="dropdown mt-1 w-100">
        <app-searchabledropdown
          [title]="getCustomGroupbyNameKey(0) | translate"
          [items]="repartitions[0]"
          [selectedItems]="globalFilters.searchCriteria.groupLevel1"
          (onCheck)="checkItem($event[1], $event[0], 'groupLevel1')"
        />
      </div>
    </div>
    } @if(repartitions && repartitions.length > 1){
    <div class="col-6 col-sm-3 mt-3">
      <label for="requiredSelect" class="form-label">{{
        getCustomGroupbyNameKey(1) | translate
      }}</label>
      <div ngbDropdown class="dropdown mt-1 w-100">
        <app-searchabledropdown
          [title]="getCustomGroupbyNameKey(1) | titlecase"
          [items]="repartitions[1]"
          [selectedItems]="globalFilters.searchCriteria.groupLevel2"
          (onCheck)="checkItem($event[1], $event[0], 'groupLevel2')"
        />
      </div>
    </div>
    }

    <div class="col-4 col-sm-2 mt-3">
      <label class="form-label" for="caseNumber">{{
        'pages.cybersocxdr.filters.caseNumber' | translate
      }}</label>
      <input
        type="text"
        name="caseNumber"
        id="caseNumber"
        class="form-control ng-untouched ng-pristine ng-valid mt-1"
        [(ngModel)]="globalFilters.searchCriteria.id"
      />
    </div>
    <div class="col-8 col-sm-4 mt-3">
      <label class="form-label" for="caseSubject">{{
        'pages.cybersocxdr.filters.subject' | translate
      }}</label>
      <input
        type="text"
        name="caseNumber"
        id="caseSubject"
        class="form-control ng-untouched ng-pristine ng-valid mt-1"
        [(ngModel)]="globalFilters.searchCriteria.subject"
      />
    </div>
    <div class="col-12 col-sm-3 mt-3">
      <label for="requiredSelect" class="form-label">{{
        'pages.cybersocxdr.filters.category' | translate
      }}</label>
      <app-searchabledropdown
        title="category"
        [items]="categories"
        [selectedItems]="globalFilters.searchCriteria.category"
        (onCheck)="checkItem($event[1], $event[0], 'category')"
      />
    </div>
    <div class="col-12 col-sm-3 mt-3">
      <label for="requiredSelect" class="form-label">{{
        'pages.cybersocxdr.filters.owner' | translate
      }}</label>
      <app-searchabledropdown
        title="owner"
        [items]="owners"
        [selectedItems]="globalFilters.searchCriteria.owner"
        (onCheck)="checkItem($event[1], $event[0], 'owner')"
      />
    </div>
    <div class="col-12 col-sm-3 mt-3">
      <label for="requiredSelect" class="form-label">{{
        'pages.cybersocxdr.filters.waitingFor' | translate
      }}</label>
      <app-searchabledropdown
        title="waitingFor"
        [items]="waitingFor"
        [selectedItems]="globalFilters.searchCriteria.waitingFor"
        (onCheck)="checkItem($event[1], $event[0], 'waitingFor')"
      />
    </div>
    <div class="col-12 col-sm-3 mt-3">
      <label for="requiredSelect" class="form-label">{{
        'pages.cybersocxdr.filters.closureVerdict' | translate
      }}</label>
      <app-searchabledropdown
        title="closureVerdict"
        [items]="closureVerdict"
        [selectedItems]="globalFilters.searchCriteria.closureVerdict"
        (onCheck)="checkItem($event[1], $event[0], 'closureVerdict')"
      />
    </div>
    <div class="col-12 col-sm-3 mt-3">
      <label for="requiredSelect" class="form-label">{{
        'pages.cybersocxdr.filters.sla_status' | translate
      }}</label>
      <app-searchabledropdown
        title="slaNotification"
        [items]="slaNotification"
        [selectedItems]="globalFilters.searchCriteria.slaNotification"
        (onCheck)="checkItem($event[1], $event[0], 'slaNotification')"
      />
    </div>
  </div>

  <div class="row d-flex justify-content-between gap-3">
    <div class="w-auto d-flex flex-wrap justify-content-center gap-2">
      <button class="btn btn-primary" (click)="search()">
        {{ 'pages.cybersocxdr.filters.search' | translate }}
      </button>
      <button class="btn btn-primary" (click)="globalFilters.resetFilters()">
        {{ 'pages.cybersocxdr.filters.reset' | translate }}
      </button>
    </div>
  </div>
</form>
