<div class="services">
  <div class="service-type">
    <div class="section">
      <span>{{ 'pages.updates.ocdServices.serviceType' | translate }}</span>

      <!-- all service checkbox-->
      <div class="group" id="all_services">
        <span (click)="goToAnchor(serviceGroupings[0].id)">{{ 'pages.updates.ocdServices.allServices' | translate
          }}</span>
        <input class="form-check-input float-end" type="checkbox" [(ngModel)]=allServices (click)="selectedAllServices()">
      </div>

      <!-- ocd service categories checkbox-->
      <div class="group" *ngFor="let group of serviceGroupings">
        <span (click)="goToAnchor(group.id)">{{group.categoryName}} <span> ({{group.categories.length}})</span></span>
        <input class="form-check-input" type="checkbox" [(ngModel)]=group.active (click)="filterServices(group)">
      </div>
    </div>
  </div>
  <div>

    <!-- no service selected -->
    <span *ngIf="noService" class="no-service">{{ 'pages.updates.ocdServices.noServiceSelected' | translate }}</span>

    <!-- all service's cards-->
    <div *ngFor="let group of serviceGroupings">
      <div *ngIf="group.active">
        <div class="category" [id]="group.id">
          <fa-icon [icon]="['fas', group.icon]" size="xl" data-placement="top"></fa-icon>
          <span class="ms-4">{{group.categoryName}}</span>
        </div>
        <div class="service-grid">
          <div *ngFor="let service of group.categories" class="card highlight"
            style="width: 100%" (click)="openService(service)">
            <figure>
              <img src={{service.img}} alt="orange cyberdefense staff">
            </figure>
            <div class="card-body">
              <h5 class="card-title">{{service.title}}</h5>
              <p class="card-text fw-bold">{{service.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
