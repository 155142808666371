/* eslint-disable @typescript-eslint/member-ordering */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ACTIONS, constants, PAGES, TYPES } from 'src/app/shared/constants';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { SupportCaseSearchCriteria, DateRange } from 'src/app/shared/model/searchCriteria';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { Asset, Case, Contact } from 'src/app/shared/model/itsm';
import { ActivatedRoute } from '@angular/router';
import { Utilities } from 'src/app/shared/utilities';
import { OktaAuthService } from 'src/app/app.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FileExtension, Pages } from 'src/app/shared/model/shared-items';
import { TranslateService } from '@ngx-translate/core';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ModalService } from 'src/app/shared/modals/modal.service';
import {
  EditColumnParameters,
  ExportParameters,
  GenerateReportParameters,
  ModalParameters,
  SaveFilterParameters,
  SaveFilterResponse
} from 'src/app/shared/modals/modal';

import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationAudience, NotificationCategory } from 'src/app/shared/model/notification';
import { ReportPdfAccessLevel } from 'src/app/shared/model/files';
import { ContextService } from 'src/app/shared/services/context-service';
import { FlaggedListComponent } from 'src/app/shared/components/flagged-list/flagged-list.component';
import { FormGroup } from '@angular/forms';
import { QueryStatus } from 'src/app/support/supportlog/filters/asset-and-service/asset-and-service.component';

@Component({
  selector: 'app-supportdashboard',
  templateUrl: './supportdashboard.component.html',
  styleUrls: ['./supportdashboard.component.scss'],
})
export class SupportDashboardComponent extends FlaggedListComponent implements OnInit {

  // dark theme
  public isDarkTheme : boolean;

  // scrolling
  public scrollLoading = false;

  requestTracker = 0;

  // pagination
  perPage = constants.recordsPerPage.cases;
  currentPage: number;

  // data
  cases: Case[];
  casesCount: number;
  searchCriteria: SupportCaseSearchCriteria;
  selectedSearchCriteria: SupportCaseSearchCriteria;
  selectedSearch: SupportCaseSearchCriteria = undefined;
  searched: boolean;
  casesLoaded: boolean;

  private assetsLimit: number = 40;
  private servicesLimit: number = 40;
  public servicesTotalCount: number;
  public assetsTotalCount: number;
  public infiniteSelectLabels = { assets: '', services: '' }

  // counts
  casesCreatedSinceLastLogin: number;
  casesUpdatedSinceLastLogin: number;

  // dropdown cache
  caseTypeCV: string[];
  caseCategoryCV: string[];
  casePriorityCV: string[];
  statusSelectDropdown: string[] = [];
  caseAssetCV: Asset[] = [];
  caseStatusCV: string[];
  contacts: Contact[];
  contactsCreatedBy: Contact[];

  //filter
  serviceStatus:QueryStatus;
  assetStatus:QueryStatus;

  // saved searches
  savedSearches: SupportCaseSearchCriteria[];
  searchName: string;
  hideSaveSearch: boolean;

  public radioAction = 'create';

  // view
  page: string;

  // search
  advSearch = false;
  hideChildCases = false;

  // search fields
  caseNumber: string;
  description: string;
  service: string;
  createdFrom: string;
  createdTo: string;
  updatedFrom: string;
  updatedTo: string;
  closedDateFrom: string;
  closedDateTo: string;
  asset: string;
  assetId: string;
  type: string[];
  category: string;
  selectedPriorities: string[];
  selectedStatus: string[];
  subject: string;
  status: string;
  contact: string;
  contactEmail: any[];
  createdByEmail: any[];
  createdPeriod: string;
  escalation: string;
  trueFalsePositives: string;
  dateRanges: DateRange[];
  validDateRanges: [];
  allRangesValid: boolean;

  sort: string;
  sortBy: string;

  datepipe: DatePipe = new DatePipe(constants.languages.enGB);
  dateRangesToCheck: string[] = ['created', 'updated', 'closedDate'];

  // initial page to load
  loadpage: string;
  private signalRef: string
  allowedCaseTypes: string[];

  //services dropdown
  servicesDropdown: any[];
  serviceValue: string;
  public servicesSelectInput: any;
  public clearSelectedService: boolean;

  // assets dropdown
  public assetsSelectInput: any;
  public clearSelectedAsset: boolean;

  //Pages
  casesPage = Pages.cases;
  flaggedPage = Pages.flagged;

  //soc csoc
  csocCases: boolean;

  // File parameters
  pdfLabel = FileExtension.pdf.toUpperCase();
  csvLabel = FileExtension.csv.toUpperCase();

  // column general settings
  selectAll = '';
  unSelectAll = '';

  public researchForm: FormGroup = new FormGroup({});

  //dropdowns
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: this.selectAll,
    unSelectAllText: this.unSelectAll,
    itemsShowLimit: 2,
    allowSearchFilter: false,
  };

  dropdownSettingsStatus: IDropdownSettings = {
    singleSelection: false,
    selectAllText: this.selectAll,
    unSelectAllText: this.unSelectAll,
    itemsShowLimit: 2,
    allowSearchFilter: false,
  };

  dropdownSettingsContact: IDropdownSettings = {
    singleSelection: false,
    selectAllText: this.selectAll,
    unSelectAllText: this.unSelectAll,
    itemsShowLimit: 2,
    idField: 'email',
    textField: 'fullName',
    allowSearchFilter: false,
  };

  dropdownSettingsCreatedBy: IDropdownSettings = {
    singleSelection: false,
    selectAllText: this.selectAll,
    unSelectAllText: this.unSelectAll,
    itemsShowLimit: 2,
    idField: 'email',
    textField: 'fullName',
    allowSearchFilter: false,
  };

  dropdownSettingsColumns: IDropdownSettings = {
    singleSelection: false,
    selectAllText: this.selectAll,
    unSelectAllText: this.unSelectAll,
    itemsShowLimit: 2,
    idField: 'field',
    textField: 'name',
    allowSearchFilter: false,
  };

  //dropdown columns
  selectedColumns = [
    { name: 'Case Number', field: 'caseNumber' },
    { name: 'Subject', field: 'subject' },
    { name: 'Priority', field: 'priority' },
    { name: 'Status', field: 'status' },
    { name: 'Type', field: 'type' },
    { name: 'Created Date', field: 'createdDate' },
    { name: 'Updated', field: 'lastModifiedDate' },
    { name: 'Contact', field: 'contact' },
  ];

  columnsSelection = [
    { name: 'Case Number', field: 'caseNumber' },
    { name: 'Subject', field: 'subject' },
    { name: 'Priority', field: 'priority' },
    { name: 'Status', field: 'status' },
    { name: 'Type', field: 'type' },
    { name: 'Created Date', field: 'createdDate' },
    { name: 'Updated', field: 'lastModifiedDate' },
    { name: 'Escalation', field: 'escalation' },
    { name: 'Service Contract', field: 'serviceContract' },
    { name: 'Opened By', field: 'openedBy' },
    { name: 'Updated By', field: 'updatedBy' },
    // { name: 'Made SLA', field: 'madeSLA' },
    { name: 'Parent Id', field: 'parentId' },
  ];

  constructor(
    private itsmService: ItsmService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private oktaAuthService: OktaAuthService,
    private userActivity: UserActivityService,
    private notificationService: NotificationService,
    public contextService: ContextService,
    public translateService: TranslateService,
    public alertService: AlertService,
    public userprefService: UserPreferenceService
  ) {
    super(userprefService, alertService);
    this.viewType = constants.local.views.support;
    this.flagType = constants.local.flags.support;
    super.checkPreferences();
  }

  ngOnInit() {
    this.isDarkTheme = this.contextService.isDarkTheme();
    //fetch services

    this.serviceStatus = new QueryStatus(this.servicesLimit);
    this.assetStatus = new QueryStatus(this.assetsLimit);

    this.loadAssetsAndservices();

    // init labels
    this.selectAll = this.translateService.instant('pages.generic.selectAll');
    this.unSelectAll = this.translateService.instant('pages.generic.unSelectAll');
    this.infiniteSelectLabels = {
      services: this.translateService.instant('pages.support.dashboard.fieldTitles.services'),
      assets: this.translateService.instant('pages.support.dashboard.fieldTitles.asset')
    }

    this.route.params.subscribe((params) => this.loadpage = params.loadpage);

    this.route.queryParams.subscribe((queryParams) => {
      this.signalRef = queryParams.signalRef;
    });

    // initialise
    this.hideSaveSearch = false;
    this.currentPage = 1;
    this.searched = false;
    this.searchCriteria = new SupportCaseSearchCriteria();
    this.casesLoaded = false;

    //Hard coded case types to not have to pull all types
    this.caseTypeCV = ['Change Request', 'Incident', 'Service Request', 'Problem'];

    // initialize dates checks
    this.validDateRanges = [];
    this.dateRanges  = [DateRange.created, DateRange.updated, DateRange.closedDate];
    this.dateRanges.forEach( dateRange => {
      this.validDateRanges[dateRange] = true;
    });
    this.allRangesValid = true;

    // load user details
      this.allowedCaseTypes = [];
      this.oktaAuthService.userDetails?.groups.map((g) => {
        if (g.includes('Access_')) {
          this.allowedCaseTypes.push(g.substr(g.indexOf('Access_') + 7));
        }
      });
      this.allowedCaseTypes.push('Problem');

      // filter case type by roles
      this.caseTypeCV = this.caseTypeCV.filter(
        (t) =>
          (t === 'Change Request' && this.allowedCaseTypes.includes('Change_Request')) ||
          (t === 'Service Request' && this.allowedCaseTypes.includes('Service_Request')) ||
          (t === 'Incident' && this.allowedCaseTypes.includes('Incident')) ||
          (t === 'Problem' && this.allowedCaseTypes.includes('Problem'))
      );
      this.userActivity.logActivity(TYPES.support, PAGES.supportOverview, ACTIONS.visit);

    // count the signals since last login
    this.userprefService
      .getUserLastLoginDate()
      .then((ress) => {
        // get new and updated
        this.itsmService
          .cacheCasesCountCreatedSinceLastLogin(ress)
          .then((res) => {
            this.casesCreatedSinceLastLogin = res;
          })
          .catch((err) => {
            throw err;
          });

        this.itsmService
          .cacheCasesCountUpdatedSinceLastLogin(ress)
          .then((res) => {
            this.casesUpdatedSinceLastLogin = res;
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        this.handlerError(err);
      });

    // load the flags signals from the user preferences
    this.refreshSupportUserPrefs();

    // load cache for dropdowns
    this.loadCache();

    this.page = this.casesPage;
    this.loadCasesFromPage();
  }

  private loadAssetsAndservices() {
    this.loadServices();
    this.loadAssets();
  }

  public loadServices(offset?: number) {
    // load filter services
    this.serviceStatus.loading = true;
    this.serviceStatus.offset = offset ?? 0;
    this.itsmService.getLazyloadedServices(this.serviceStatus)
    .subscribe({
      next:(res) => {
        for (const service of res) {
          if (service.totalCount) {
            this.servicesTotalCount = service.totalCount;
            break
          }
        }
        let services = res.filter(e => {
          // some UK contracts can't have tickets logged against them, and are flagged with "nonTicketable"         
          const ticketable = (e.nonTicketable && e.nonTicketable === 'true') ? false : true;
          const active = (e.active === 'true');
          return active && ticketable;
        });

        services.forEach(ser => {
          ser.updatedServiceName = ser.serviceName || ser.name || ser.shortDescription;
          ser.displayValue = ser.updatedServiceName; 
        });
        services = services.filter(sn => sn.updatedServiceName !== '');
        services = services.filter(this.onlyUnique);
        this.servicesDropdown = services;
        this.serviceStatus.loading = false;
      },
      error:(err)=> {
        this.alertService.handlerError(err);
        this.serviceStatus.loading = false;
      }
    })
  }

  public loadAssets(offset?: number) {
    // load filter assets
    this.assetStatus.loading = true;
    this.assetStatus.offset = offset ?? 0;
    
    this.itsmService.getLazyloadedAssets(this.assetStatus)
    .subscribe({
      next:(res) => {
        for (const asset of res) {
          if (asset.totalCount) {
            this.assetsTotalCount = asset.totalCount;
            break
          }
        }
        let assets = [];
        res.forEach(a => {
          a.displayValue = this.itsmService.getAssetFullname(a);
          assets.push(a);
        });
        this.caseAssetCV = assets; 
        this.assetStatus.loading = false;
      },
      error:(err)=> {
        this.alertService.handlerError(err);
        this.assetStatus.loading = false;
      }
    })
  }

  public setSelectedService(service: any) {
    this.serviceValue = service?.id ?? '';
    this.serviceDropDownChange();
  }

  public setSelectedAsset(asset: any) {
    this.asset = asset ? `${asset.serialNumber}||${asset.name}` : '';
    this.assetId = asset?.id ?? null;
  }

  public searchServices(searchTerm: string) {
    this.serviceStatus.search = searchTerm;
    this.loadServices(0);
  }

  public searchAssets(searchTerm: string) {
    this.assetStatus.search = searchTerm;
    this.loadAssets(0);
  }

  private loadCasesFromPage() {
    // load default page for navigation
    if (!this.loadpage || this.loadpage === 'latest') {
      // load the latest signals and stats for this month
      this.latestCases();
    } else if (this.loadpage === 'lastlogincreated') {
      // other options for click through from dashboard
      this.loadNewSinceLastVisit();
    } else if (this.loadpage === 'opencases') {
      this.loadOpenCases();
    } else if (this.loadpage === 'lastloginupdated') {
      this.loadUpdatedSinceLastVisit();
    } else if (this.loadpage === 'created30days') {
      this.loadCreatedInDays(30);
    } else if (this.loadpage === 'updated30days') {
      this.loadUpdatedInDays(30);
    } else if (this.loadpage === 'newhigh30days') {
      this.loadNewHighInDays(30);
    } else if (this.loadpage === 'newcritical30days') {
      this.loadNewHighInDays(30);
    } else if (this.loadpage === 'customerpending') {
      this.loadCustomerPending();
    } else if (this.loadpage === 'pendingocd') {
      this.loadOcdPending();
    } else if (['1', '2', '3', '4', '5'].includes(this.loadpage)) {
      this.priorityCases(this.loadpage);
    } else if (this.loadpage === 'TPO' || this.loadpage === 'FPO') {
      this.loadTrueFalsePositives(this.loadpage);
    } else if (this.loadpage === 'worldwatch') {
      this.loadWWTickets();
    } else if (this.loadpage !== '') {
      this.cases = [];
      this.casesLoaded = true;
    }
  }

  public openEditColumnModal() {
    const params = new EditColumnParameters();
    params.dropdownSettingsColumns = this.dropdownSettingsColumns;
    params.columnsSelection = this.columnsSelection;
    params.selectedColumns = this.selectedColumns;
    params.successCallback = (selectedColumns) => {
      this.updateSelectedColumns(selectedColumns);
    };
    this.modalService.editColumnsModal(params);
  }

 /**
  * Utility to erase double from the list
  */
  onlyUnique(value, index, self) {
    return index === self.findIndex((t) => value.updatedServiceName === t.serviceName || value.updatedServiceName === t.name);
  }

  /**
   * open search modal
   */
  openSaveSearch() {
    const params = new SaveFilterParameters();
    this.modalService.saveFilterModal(params);
    params.savedSearches = this.savedSearches;
    params.successCallback = (result: SaveFilterResponse) => {
      if (result.selectedFilter) {
        // update existing filter
        this.selectedSearchCriteria = result.selectedFilter as SupportCaseSearchCriteria;
        this.updateSearch();
      } else {
        // create new filter
        this.searchName = result.filterName;
        if(this.searchName) {
          this.saveSearch();
        }
      }
    };
  }

  public exportCases() {
    const modalParameters = new GenerateReportParameters();
    modalParameters.successCallback = (results) => this.exportData(results);
    modalParameters.reportCanBeScheduled = true;
    this.modalService.exportModal(modalParameters);
  }

  /**
   * when user scroll, load the nexts cases
   */
  public loadNextCases() {
    if (!this.casesLoaded || !this.cases || this.cases.length >= this.casesCount) {
      return;
    }

    this.scrollLoading = true;
    this.searchCriteria.skip += this.searchCriteria.limit;

    this.itsmService
      .caseSearch(this.searchCriteria)
      .then((res) => {
        // if the results are not from the latest search, don't show them
        if (res.requestTracker.toString() === this.requestTracker.toString()) {
          res = res.data;
          // avoid a race condition when user changes tabs
          if (this.page !== this.flaggedPage) {
            this.cases = this.cases.concat(convertDatesFormat(res));
            this.checkTrueFalsePositive();
          }
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        this.scrollLoading = false;
      });
  }

  /**
   * Check true & false positives while loading cases
   */
  checkTrueFalsePositive() {
    if (this.trueFalsePositives && this.trueFalsePositives !=='0') {
      const trueFalseCases = [];
      this.cases.forEach((c) => {
        if (Utilities.checkTruePositive(c.caseNumber, c.status, c.closureCode)) {
          if (this.trueFalsePositives === '1') {
            trueFalseCases.push(c);
          }
        } else if (Utilities.checkFalsePositive(c.status, c.closureCode)) {
          if (this.trueFalsePositives === '2') {
            trueFalseCases.push(c);
          }
        }
      });
      this.cases = trueFalseCases;
      this.casesCount = this.cases.length;
    }
  }

  /**
   * Cache the CV values for the dropdowns
   */
  loadCache() {
    Promise.all([
      Promise.resolve(), // was this.itsmService.getCachedCV('Case','Type'),
      this.itsmService.getCachedCV('priority'),
      this.itsmService.getCachedCV('status'),
      this.itsmService.getCachedContacts(),
    ])
      .then((res) => {
        this.casePriorityCV = res[1];
        this.caseStatusCV = res[2];
        this.statusSelectDropdown = res[2];
        this.contactsCreatedBy = res[3];
        this.contacts = [
          {
            id: '1',
            accountId: '2',
            email: 'unassigned',
            fullName: 'Unassigned',
          },
          ...res[3],
        ];
      })
      .then(() => {
        this.casePriorityCV = this.casePriorityCV.filter(
          (p) =>
            p.startsWith('1 -') ||
            p.startsWith('2 -') ||
            p.startsWith('3 -') ||
            p.startsWith('4 -') ||
            p.startsWith('5 -')
        );
      })
      .then(() => {
        this.caseTypeCV = this.caseTypeCV.filter(
          (p) => p.startsWith('Change') || p.startsWith('Service') || p.startsWith('Incident') || p.startsWith('Problem')
        );
        this.caseTypeCV.sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });
      })
      .catch((err) => {
        this.alertService.handlerError(err);
      });
  }

  /**
   * Refresh the local flagRefs and viewType variable from user preferences
   */
  refreshSupportUserPrefs() {
    // load the flagged signals from user preferences
    const res = this.userprefService.userPreferences;

    if (res?.searches?.support) {
      this.savedSearches = res.searches.support;
    } else {
      this.savedSearches = [];
    }
    if (res?.supportViewColumns) {
      this.selectedColumns = res.supportViewColumns;
    }
  }

  onDateChange() {
    this.validateDateRanges();
    this.allRangesValid = this.areAllRangesValid();
  }

  private validateDateRanges() {
    this.searchCriteria.createdFrom = this.createdFrom;
    this.searchCriteria.createdTo = this.createdTo;
    this.searchCriteria.updatedFrom = this.updatedFrom;
    this.searchCriteria.updatedTo = this.updatedTo;
    this.searchCriteria.closedDateFrom = this.closedDateFrom;
    this.searchCriteria.closedDateTo = this.closedDateTo;
    this.dateRanges.forEach(dateRange => {
      if (this[`${dateRange}To`] || this[`${dateRange}From`]) {
        this.validDateRanges[dateRange] = this.searchCriteria.checkDateRanges([dateRange]);
      } else {
        this.validDateRanges[dateRange] = true;
      }
    });
  }

  private areAllRangesValid(): boolean {
    const values = Object.keys(this.validDateRanges).map(key => this.validDateRanges[key]);
    return !values.includes(false);
  }

  /**
   * manage searchCritera from form
   */
  manageFilter(isUpdate: boolean = false) {
    this.page = this.casesPage;

    this.casesCount = null;
    this.searched = true;
    this.searchCriteria = new SupportCaseSearchCriteria();

    if (this.caseNumber) {
      this.searchCriteria.caseNumber = this.caseNumber;
    }
    if (this.description) {
      this.searchCriteria.description = this.description;
    }

    if (this.createdPeriod) {
      this.searchCriteria.createdPeriod = this.createdPeriod;
      if (this.createdPeriod === '1w') {
        this.searchCriteria.createdFrom = Utilities.getTodayMinusDays(7);
        this.searchCriteria.createdTo = Utilities.getToday();
      } else if (this.createdPeriod === '2w') {
        this.searchCriteria.createdFrom = Utilities.getTodayMinusDays(14);
        this.searchCriteria.createdTo = Utilities.getToday();
      } else if (this.createdPeriod === 'thismonth') {
        this.searchCriteria.createdFrom = Utilities.getFirstOfCurrentMonth();
        this.searchCriteria.createdTo = Utilities.getLastOfCurrentMonth();
      } else if (this.createdPeriod === 'lastmonth') {
        this.searchCriteria.createdFrom = Utilities.getFirstOfLastMonth();
        this.searchCriteria.createdTo = Utilities.getLastOfLastMonth();
      }
    } else {
        if (this.createdFrom) {
          this.searchCriteria.createdFrom = this.createdFrom;
        }
        if (this.createdTo) {
          this.searchCriteria.createdTo = this.createdTo;
        }
    }

    if (this.updatedFrom) {
      this.searchCriteria.updatedFrom = this.updatedFrom;
    }
    if (this.updatedTo) {
      this.searchCriteria.updatedTo = this.updatedTo;
    }
    if (this.closedDateFrom) {
      this.searchCriteria.closedDateFrom = this.closedDateFrom;
    }
    if (this.closedDateTo) {
      this.searchCriteria.closedDateTo = this.closedDateTo;
    }
    if (this.assetId) {
      this.searchCriteria.assetId = this.assetId;
    }
    if (this.asset) {
      this.searchCriteria.asset = this.asset;
    }
    if (this.type) {
      this.searchCriteria.type = this.type.toString();
    }
    if (this.category) {
      this.searchCriteria.category = this.category;
    }
    if (this.selectedPriorities) {
      this.searchCriteria.priority = this.selectedPriorities.map((a) => a).join(',');
    }
    if (this.subject) {
      this.searchCriteria.subject = this.subject;
    }
    if(this.selectedStatus && this.selectedStatus.length > 0) {
      this.searchCriteria.status = this.selectedStatus;
    }
    if (this.contact) {
      this.searchCriteria.contact = this.contact;
    }
    if (this.contactEmail?.length > 0) {
      if (this.contactEmail.length === 1 && this.contactEmail[0].email === 'unassigned') {
        this.searchCriteria.contactEmailUnassigned = 'unassigned';
      } else {
        this.searchCriteria.contactEmail = this.contactEmail
          .map( (el: { email: string; fullName: string }) => el.email ).toString();
      }
    }
    if (this.createdByEmail?.length > 0) {
      this.searchCriteria.createdByEmail = this.createdByEmail
        .map( (el: { email: string; fullName: string }) => el.email).toString();
    }
    if (this.sort) {
      this.searchCriteria.sort = this.sort;
    }
    if (this.sortBy) {
      this.searchCriteria.sortBy = this.sortBy;
    }
    if (this.escalation) {
      this.searchCriteria.escalation = this.escalation;
    }
    if (this.hideChildCases){
      this.searchCriteria.hideChildCases = true;
    }
    if (this.trueFalsePositives && this.trueFalsePositives !== '0') {
      this.searchCriteria.trueFalsePositives = this.trueFalsePositives;
      this.searchCriteria.escalation = 'To Customer';
      this.searchCriteria.securityAnalyt = true;
      this.searchCriteria.limit = undefined;
    }

    if (isUpdate) {
      this.searchCriteria.name = this.selectedSearchCriteria.name;
      this.searchCriteria._id = this.selectedSearchCriteria._id;
    }
    if(this.csocCases !== undefined){
      this.searchCriteria.csocCases = this.csocCases;
    }
    if(this.service){
      this.searchCriteria.service = this.service;
    }
  }

  /**
   * Search itsm on a bunch of attributes
   */
  search(isUpdate: boolean = false) {
    this.casesLoaded = false;
    this.manageFilter(isUpdate);
    // check all dates pairs
    if(this.areAllRangesValid) {
      this.caseSearch().then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      });
    } else {
      this.cases = [];
      this.casesLoaded = true;
      this.handlerError(
        this.translateService.instant('pages.dashboard.errors.cantReturnResults')
        + ' - '
        + this.translateService.instant('pages.dashboard.errors.invalidDateRange')
      );
    }
  }

  private serviceDropDownChange(){
    delete this.csocCases;
    delete this.service;

    if(this.serviceValue === 'all'){
      return;
    }
    else if(this.serviceValue === 'csoc'){
      this.csocCases = true;
    }
    else if(this.serviceValue === 'soc'){
      this.csocCases = false;
    }
    else{
      this.service = this.serviceValue;
    }
  }

  exportData(params: ExportParameters) {
    this.casesLoaded = false;
    this.manageFilter(false);
    // check all dates pairs
    if(this.areAllRangesValid) {
      // fetch the actual records
      this.searchCriteria.count = false;
      this.searchCriteria.limit = undefined;
      this.searchCriteria.export = true;
      this.searchCriteria.pdfName =
        params.pdfName +
        ' - ' +
        constants.accessLevels[params.pdfAccessLevel] +
        '.' +
        (params.reportOption === FileExtension.csv ? FileExtension.csv : FileExtension.pdf);
      this.searchCriteria.pdfAccessLevel = params.pdfAccessLevel;
      this.searchCriteria.exportColumns = [...this.selectedColumns].map( column => column.field).filter( exportColumn => exportColumn !== "caseNumber");
      if(!params.isReportScheduled){
        this.itsmService.caseSearch(this.searchCriteria).then(() => {
          this.alertService.addSuccess(this.translateService.instant('pages.generic.fileuploaded'));
          this.casesLoaded = true;
          this.userActivity.logActivity(TYPES.reports, PAGES.supportOverview, ACTIONS.generateReport);
          const fullName =
            `${Utilities.capitalizeFirstLetter(ReportPdfAccessLevel[this.searchCriteria.pdfAccessLevel])}/${this.searchCriteria.pdfName}`;
          this.notificationService.sendNotification(
            NotificationCategory.generateReport,
            {title: fullName, content: fullName},
            NotificationAudience.admins
          );
        })
        .catch((err) =>{
          this.handlerError(err);
        });
      } else {
        this.itsmService.scheduleReport(params.pdfName,
          constants.accessLevels[params.pdfAccessLevel],
          params.reportOption,
          params.time,
          this.searchCriteria
        ).then(() => {
          this.casesLoaded = true;
          this.alertService.addSuccess(this.translateService.instant('pages.generic.schedulerecorded'));
          this.userActivity.logActivity(TYPES.reports, PAGES.supportOverview, ACTIONS.scheduleReport);
        })
        .catch(this.handlerError);
      }
    } else {
      this.casesLoaded = true;
      this.handlerError(
        this.translateService.instant('pages.dashboard.errors.cantGenerateReport')
        + ' - '
        + this.translateService.instant('pages.dashboard.errors.invalidDateRange')
      );
    }
  }

  /**
   * Reset search fields
   *
   * @param view
   */
  reset() {
    delete this.caseNumber;
    delete this.description;
    delete this.createdFrom;
    delete this.createdTo;
    delete this.updatedFrom;
    delete this.updatedTo;
    delete this.closedDateFrom;
    delete this.closedDateTo;
    delete this.createdPeriod;
    delete this.assetId;
    delete this.asset;
    delete this.type;
    delete this.category;
    delete this.selectedPriorities;
    delete this.subject;
    delete this.sort;
    delete this.status;
    delete this.sortBy;
    delete this.contact;
    delete this.contactEmail;
    delete this.createdByEmail;
    delete this.asset;
    delete this.escalation;
    delete this.trueFalsePositives;
    delete this.selectedStatus;
    
    this.clearSelectedAsset = !this.clearSelectedService;
    this.clearSelectedService = !this.clearSelectedService;
    this.allRangesValid = true;
    this.dateRanges.forEach( range => this.validDateRanges[range] = true);
    this.searched = false;
  }

  /**
   * Return the top 12 cases
   */
  latestCases() {
    this.casesLoaded = false;
    this.searched = false;
    this.searchCriteria = new SupportCaseSearchCriteria();

    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }

    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  contactFilterChange(event) {
    if (event.email === 'unassigned') {
      this.contactEmail = [{ email: 'unassigned', fullName: 'Unassigned' }];
    } else if (this.contactEmail.findIndex((x) => x.email === 'unassigned') !== -1) {
      this.contactEmail = [event];
    }
  }

  /**
   * Load flagged cases
   */
  loadFlagged() {
    this.page = this.flaggedPage;
    this.searched = false;
    this.casesLoaded = false;

    if (this.flagRefs.length === 0) {
      // avoid a race condition when user changes tabs
      if (this.page === this.flaggedPage) {
        this.cases = [];
        this.casesLoaded = true;
      }
    } else {
      const loadListCriteria = new SupportCaseSearchCriteria();
      loadListCriteria.caseRefs = this.flagRefs;

      this.itsmService
        .caseLoadList(loadListCriteria)
        .then((res) => {
          // avoid a race condition when user changes tabs
          if (this.page === this.flaggedPage) {
            this.cases = convertDatesFormat(res);
            this.casesLoaded = true;
          }
        })
        .catch((err) => {
          this.handlerError(err);
        });
    }
  }

  /**
   * Load cases updated in a number of days
   */
  loadUpdatedInDays(days: number) {
    this.page = this.casesPage;
    this.casesLoaded = false;
    this.searchCriteria = new SupportCaseSearchCriteria();
    this.searchCriteria.closedDateFrom = Utilities.getTodayMinusDays(days);
    this.searchCriteria.closedDateTo = Utilities.getToday();
    this.searchCriteria.count = true;
    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }
    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Load cases created in a number of days
   */
  loadCreatedInDays(days: number) {
    this.page = this.casesPage;
    this.casesLoaded = false;
    this.searchCriteria = new SupportCaseSearchCriteria();
    this.searchCriteria.createdFrom = Utilities.getTodayMinusDays(days);
    this.searchCriteria.createdTo = Utilities.getToday();
    this.searchCriteria.count = true;
    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }
    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Load open cases that are high priority and new in a number of days
   */
  loadOpenCases() {
    this.page = this.casesPage;
    this.casesLoaded = false;
    this.searchCriteria = new SupportCaseSearchCriteria();
    this.selectedStatus = constants.itsm.globalStates.open;
    this.searchCriteria.status = this.selectedStatus;
    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }
    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Load cases that are pending customer
   */
  loadCustomerPending() {
    this.page = this.casesPage;
    this.casesLoaded = false;

    this.searchCriteria = new SupportCaseSearchCriteria();
    this.selectedStatus = [constants.itsm.states.pendingCustomer];
    this.searchCriteria.status = this.selectedStatus;
    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }
    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Load cases that are pending under Orange Cyberdefense
   */
  loadOcdPending() {
    this.page = this.casesPage;
    this.casesLoaded = false;

    this.searchCriteria = new SupportCaseSearchCriteria();
    this.selectedStatus = [constants.itsm.states.pendingOcd];
    this.searchCriteria.status = this.selectedStatus;
    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }
    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Load cases that are high priority and new in a number of days
   */
  loadNewHighInDays(days: number) {
    this.page = this.casesPage;
    this.casesLoaded = false;
    this.searchCriteria = new SupportCaseSearchCriteria();
    this.searchCriteria.createdFrom = Utilities.getTodayMinusDays(days);
    this.searchCriteria.createdTo = Utilities.getToday();
    this.selectedStatus = constants.itsm.globalStates.open;
    this.searchCriteria.status = this.selectedStatus;
    this.searchCriteria.priority = constants.itsm.priorities.high;
    this.searchCriteria.count = true;
    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }
    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Load cases that are high priority and new in a number of days
   */
  loadNewCriticalInDays(days: number) {
    this.page = this.casesPage;
    this.casesLoaded = false;
    this.searchCriteria = new SupportCaseSearchCriteria();
    this.searchCriteria.createdFrom = Utilities.getTodayMinusDays(days);
    this.searchCriteria.createdTo = Utilities.getToday();
    this.selectedStatus = constants.itsm.globalStates.open;
    this.searchCriteria.status = this.selectedStatus;
    this.searchCriteria.priority = constants.itsm.priorities.critical;
    this.searchCriteria.count = true;
    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }
    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Load cases updated since last login
   */
  loadUpdatedSinceLastVisit() {
    this.page = this.casesPage;
    this.casesLoaded = false;

    // since last login
    this.userprefService
      .getUserLastLoginDate()
      .then((res) => {
        this.searchCriteria = new SupportCaseSearchCriteria();
        this.searchCriteria.updatedFrom = this.datepipe.transform(res, 'yyyy-MM-dd');
        this.searchCriteria.updatedTo = Utilities.getToday();
        if (this.sort && this.sortBy) {
          this.searchCriteria.sort = this.sort;
          this.searchCriteria.sortBy = this.sortBy;
        }
        this.caseSearch()
          .then((result) => {
            if (!result.abandoned) {
              this.casesLoaded = true;
            }
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  /**
   * Load cases created since last login
   */
  loadNewSinceLastVisit() {
    this.page = this.casesPage;
    this.casesLoaded = false;

    // since last login
    this.userprefService
      .getUserLastLoginDate()
      .then((res) => {
        this.searchCriteria = new SupportCaseSearchCriteria();
        this.searchCriteria.createdFrom = this.datepipe.transform(res, 'yyyy-MM-dd');
        this.searchCriteria.createdTo = Utilities.getToday();
        if (this.sort && this.sortBy) {
          this.searchCriteria.sort = this.sort;
          this.searchCriteria.sortBy = this.sortBy;
        }
        this.caseSearch()
          .then((result) => {
            if (!result.abandoned) {
              this.casesLoaded = true;
            }
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  /**
   * Save the search criteria in user preferences for future use
   */
  public saveSearch(isUpdate = false): void {
    this.search(isUpdate);

    const copy = JSON.parse(JSON.stringify(this.searchCriteria));

    delete copy.count;
    delete copy.limit;
    delete copy.skip;
    delete copy.sort;
    delete copy.sortOrder;

    if (!isUpdate) {
      copy.name = this.searchName;
      this.selectedSearchCriteria = copy;
    }

    this.userprefService.userPreferencesUpdateChildArray('searches', 'support', copy, false).then((res) => {
      this.searchName = undefined;
      this.refreshSupportUserPrefs();
      this.hideSaveSearch = true;
    });
  }

  sortResults(field: string) {
    this.currentPage = 1;
    if (this.sort === field && this.sortBy) {
      if (this.sortBy === 'DESC') {
        this.sortBy = 'ASC';
      } else {
        this.sortBy = 'DESC';
      }
    } else {
      this.sort = field;
      this.sortBy = 'DESC';
    }

    if (this.searched) {
      this.search();
    } else {
      this.loadCasesFromPage();
    }
  }

  /**
   * Return cases based on true/false positives
   */
  loadTrueFalsePositives(positive) {
    this.casesLoaded = false;
    this.searched = false;

    this.searchCriteria = new SupportCaseSearchCriteria();

    this.searchCriteria.securityAnaltyics = true;

    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }

    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          const newCases = [];

          this.cases = this.cases.filter((t) => t.type !== 'Change Request' && t.type !== 'Service Request');

          this.cases.forEach((rec) => {
            if (positive === 'TPO') {
              if (Utilities.checkTruePositive(rec.caseNumber, rec.status, rec.closureCode)) {
                newCases.push(rec);
              }
            } else if (positive === 'FPO') {
              if (Utilities.checkFalsePositive(rec.status, rec.closureCode)) {
                newCases.push(rec);
              }
            }
          });

          this.casesCount = newCases.length;
          this.cases = newCases;
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  /**
   * Return cases based on priorities
   */
  priorityCases(priority) {
    this.casesLoaded = false;
    this.searched = false;
    this.searchCriteria = new SupportCaseSearchCriteria();
    this.searchCriteria.limit = 12;
    this.selectedStatus = constants.itsm.globalStates.open
    this.searchCriteria.status = this.selectedStatus;
    this.searchCriteria.priority = priority;
    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }

    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  /**
   * Load a saved search and run
   *
   * @param savedSearch
   */
  loadSearch(savedSearch: SupportCaseSearchCriteria) {
    this.searchCriteria = savedSearch;
    this.selectedSearchCriteria = savedSearch;
    this.fillForm();
    this.casesLoaded = false;
    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  /**
   * Delete a saved search
   *
   * @param event
   * @param content
   * @param savedSearch
   */
  public deleteSearch(): void {
    const params = new ModalParameters();
    params.title = this.translateService.instant('pages.generic.deleteSearchModal');
    params.bodyMessage = this.translateService.instant('pages.generic.deleteSearchConfirm');
    params.successBtnLabel = this.translateService.instant('pages.generic.confirm');
    params.successCallback = () => {
      this.userprefService
        .userPreferencesUpdateChildArray('searches', 'support', this.selectedSearchCriteria, true)
        .then(() => {
          this.refreshSupportUserPrefs();
          this.selectedSearchCriteria = undefined;
        });
    };
    params.closeBtnLabel = this.translateService.instant('pages.generic.close');

    this.modalService.confirmModal(params);
  }

  /** update  */
  public updateSearch() {
    this.saveSearch(true);
  }

  private loadWWTickets() {
    this.casesLoaded = false;
    this.searchCriteria = new SupportCaseSearchCriteria();
    this.searchCriteria.worldWatchSignal = this.signalRef;

    if (this.sort && this.sortBy) {
      this.searchCriteria.sort = this.sort;
      this.searchCriteria.sortBy = this.sortBy;
    }
    this.caseSearch()
      .then((result) => {
        if (!result.abandoned) {
          this.casesLoaded = true;
        }
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  private fillForm() {
    this.advSearch = false;
    this.reset();

    // NORMAL SEARCH
    this.fillNormalSearchForm();
    
    // ADVANCED SEARCH
    this.fillAdvancedSearchForm();
  }

  private fillNormalSearchForm() {
    const normalSearchBasicAttr = [
      'caseNumber',
      'subject',
      'trueFalsePositives',
      'status',
      'contact'
    ];

    normalSearchBasicAttr.forEach( attr => {
      if (this.searchCriteria[attr]) {
        this[attr] = this.searchCriteria[attr];
      }
    });

    if (this.searchCriteria.type) {
      this.type = this.searchCriteria.type.split(',');
    }

    if (this.searchCriteria.priority) {
      this.selectedPriorities = this.searchCriteria.priority.split(',');
    }
    if (this.searchCriteria.service) {
      this.itsmService.getServiceByServiceId(this.searchCriteria.service)
        .subscribe({
          next:(res) => {
            let ser = res[0];
            ser.updatedServiceName = ser.serviceName || ser.name || ser.shortDescription;
            ser.displayValue = ser.updatedServiceName;
            this.servicesSelectInput = ser;
          },
          error:(err)=> {
            this.alertService.handlerError(err);
          }
        });
    }
  }

  private fillAdvancedSearchForm() {
    const advSearchBasicAttr = [
      'updatedFrom',
      'updatedTo',
      'closedDateFrom',
      'closedDateTo',
      'asset',
      'sort',
      'sortBy',
      'escalation'
    ];

    advSearchBasicAttr.forEach(attr => {
      if (this.searchCriteria[attr]) {
        this.advSearch = true;
        this[attr] = this.searchCriteria[attr];
      }
    });

    if (this.searchCriteria.createdPeriod) {
      this.advSearch = true;
      this.createdPeriod = this.searchCriteria.createdPeriod;
    } else {
      if (this.searchCriteria.createdFrom) {
        this.advSearch = true;
        this.createdFrom = this.searchCriteria.createdFrom;
      }
      if (this.searchCriteria.createdTo) {
        this.advSearch = true;
        this.createdTo = this.searchCriteria.createdTo;
      }
    }
    if (this.searchCriteria.assetId) {
      this.advSearch = true;
      this.itsmService.getAssetsByAssetId(this.searchCriteria.assetId)
        .subscribe({
          next:(res: any) => {
            let asset = res[0];
            asset.displayValue = this.itsmService.getAssetFullname(asset);
            this.assetsSelectInput = asset;
          },
          error:(err)=> {
            this.alertService.handlerError(err);
          }
        });
    }
    this.contactEmail = [];
    if (this.searchCriteria.contactEmailUnassigned) {
      this.contactEmail.push({ email: 'unassigned', fullName: 'Unassigned' });
    }
    if (this.searchCriteria.contactEmail) {
      this.searchCriteria.contactEmail.split(',').forEach((email) => {
        const contact = this.contacts.find((search) => search.email === email);
        this.contactEmail.push({ email: contact.email, fullName: contact.fullName });
      });
    }
    this.createdByEmail = [];
    if (this.searchCriteria.createdByEmail) {
      this.advSearch = true;
      this.searchCriteria.createdByEmail.split(',').forEach((email) => {
        const contact = this.contacts.find((search) => search.email === email);
        this.createdByEmail.push({ email: contact.email, fullName: contact.fullName });
      });
    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /**
   * Error handling forwarder, only not going direct so
   * it allows us to add context within this module
   *
   * @param err
   */
  private handlerError(err) {
    this.alertService.handlerError(err);
  }
  /**
   * Utility method to load count of results as well as results themselves
   * Used for pagination
   */
  private caseSearch(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.searchCriteria.SNCount = true;
      this.searchCriteria.byPassSF = true;
      this.searchCriteria.forceTMLimit = true;
      const tempLimit = this.searchCriteria.limit;

      this.searchCriteria.requestTracker.requestTracker = true;
      this.requestTracker = this.requestTracker + 1;
      this.searchCriteria.requestTracker.count = this.requestTracker;
      this.searchCriteria.supportOverViewCacheByPass = false;
      // fetch the count of matching records (happens before the actual results are fetched)
      this.itsmService
        .caseSearch(this.searchCriteria)
        .then((res) => {
          // if the count is not from the latest search, don't perform the full search
          if (res.requestTracker.toString() === this.requestTracker.toString()) {
            res = res.data;
            if (res) {
              this.casesCount = res;
            } else {
              this.casesCount = 0;
            }

            // fetch the actual records
            this.searchCriteria.SNCount = false;

            this.searchCriteria.supportOverViewCacheByPass = true;

            this.itsmService
              .caseSearch(this.searchCriteria)
              .then((res2) => {
                // if the results are not from the latest search, don't show them
                if (res2.requestTracker.toString() === this.requestTracker.toString()) {
                  res2 = res2.data;
                  // avoid a race condition when user changes tabs
                  if (this.page !== 'flagged') {
                    this.cases = convertDatesFormat(res2);

                    //checking true/false positives
                    this.checkTrueFalsePositive()
                  }
                  resolve({ abandoned: false });
                } else {
                  resolve({ abandoned: true });
                }
              })
              .catch((err) => {
                throw err;
              });
          } else {
            resolve({ abandoned: true });
          }
        })
        .catch((err) => {
          reject();
          this.handlerError(err);
        });
    });
  }

  /**
   * Update selected columns
   */
  updateSelectedColumns(selectedColumns) {
    this.userprefService
      .userPreferencesUpdateSingle(constants.local.views.columnsPrefs, selectedColumns)
      .then((_) => {
        this.refreshSupportUserPrefs();
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }
}
export const convertDatesFormat = (res: any): Case[] => {
  const cases: Case[] = [];
  if (res.length === undefined) {
    let singleCase = res;
    singleCase = transformDates(singleCase);
    cases.push(singleCase);
  } else {
    res.forEach((element) => {
      let singleCase = element;
      singleCase = transformDates(singleCase);

      cases.push(singleCase);
    });
  }
  return cases;
};

const convertToUtcFormat = (date: string) => {
  // convert "2022-03-24 14:40:03" to "2022-03-24T14:40:03.000+0000"
  if (date.indexOf(' ') > -1) {
    date = date.replace(' ', 'T');
    date = date + '.000+0000'; // UTC timezone
  }
  return date;
};

const transformDates = (singleCase: any): any => {
  singleCase.createdDate = convertToUtcFormat(singleCase.createdDate);

  singleCase.comments.forEach((element, index) => {
    singleCase.comments[index].createdDate = convertToUtcFormat(element.createdDate);
  });

  singleCase.attachments.forEach((element, index) => {
    singleCase.attachments[index].createdDate = convertToUtcFormat(element.createdDate);
  });

  if (singleCase.lastModifiedDate !== undefined) {
    singleCase.lastModifiedDate = convertToUtcFormat(singleCase.lastModifiedDate);
  }

  return singleCase;
};
