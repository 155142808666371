  <div class="d-flex pt-2 faketableheaderrow w-100" *ngIf="cves && loaded">
    <div class="col-xl-3 col-lg-2 col-md-3 col-sm-6 col-xs-12 faketableheader click" (click)="sortCVEs($event, 'cve');">{{ 'pages.updates.vulnerabilities.fieldTitles.cve' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
    <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 d-none d-sm-block faketableheader click" (click)="sortCVEs($event, 'baseSeverity');">{{ 'pages.updates.vulnerabilities.fieldTitles.severity' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
    <div class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader click" (click)="sortCVEs($event, 'impactScore');">{{ 'pages.updates.vulnerabilities.fieldTitles.impact' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
    <div class="col-xl-1 col-lg-2 d-none d-lg-block faketableheader click" (click)="sortCVEs($event, 'exploitabilityScore');">{{ 'pages.updates.vulnerabilities.fieldTitles.exploitability' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
    <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketableheader click" (click)="sortCVEs($event, 'lastModifiedDate');">{{ 'pages.updates.vulnerabilities.fieldTitles.modified' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketableheader click" (click)="sortCVEs($event, 'publishedDate');">{{ 'pages.updates.vulnerabilities.fieldTitles.published' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
    <div class="col-xl-1 col-lg-1 col-md-1 d-none d-md-block faketableheader">{{ 'pages.generic.flag' | translate }}</div>
  </div>

  <div *ngIf="cves && loaded">
    <div class="d-flex faketablerow" *ngFor="let cve of cves" [routerLink]="['/updates/vulnerabilities/viewVuln/' + cve._id]" routerLinkActive="activeLink">
      <div class="col-12">
        <div class="d-flex">
          <div class="col-xl-3 col-lg-2 col-md-3 col-sm-6 col-xs-12 faketablecell">

            <div *ngIf="cve.impact.baseMetricV3">
                <span class="tag tag-sm" [ngClass]="cve.impact.baseMetricV3.cvssV3.baseSeverity | lowercase">{{cve._id}}</span>
            </div>
            <div *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">
                <span class="tag tag-sm" [ngClass]="cve.impact.baseMetricV2.severity | lowercase">{{cve._id}}</span>
            </div>
            <div *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">
                <span class="tag tag-sm text-bg-light">{{cve._id}}</span>
            </div>

          </div>
          <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 d-none d-sm-block faketablecell">

              <div *ngIf="cve.impact.baseMetricV3">
                <span class="tag tag-sm" [ngClass]="cve.impact.baseMetricV3.cvssV3.baseSeverity | lowercase">{{cve.impact.baseMetricV3.cvssV3.baseSeverity}}</span>
              </div>
              <div *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">
                <span class="tag tag-sm" [ngClass]="cve.impact.baseMetricV2.severity | lowercase">{{cve.impact.baseMetricV2.severity}}</span>
              </div>
              <div *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">
                  <span class="tag tag-sm text-bg-light">N/A</span>
              </div>

          </div>
          <div class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">

            <span class="tag tag-sm tag-info" *ngIf="cve.impact.baseMetricV3">{{cve.impact.baseMetricV3.impactScore}}</span>
            <span class="tag tag-sm tag-info" *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">{{cve.impact.baseMetricV2.impactScore}}</span>
            <span class="tag tag-sm text-bg-light" *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">N/A</span>

          </div>
          <div class="col-xl-1 col-lg-2 d-none d-lg-block faketablecell">

            <span class="tag tag-sm tag-info" *ngIf="cve.impact.baseMetricV3">{{cve.impact.baseMetricV3.exploitabilityScore}}</span>
            <span class="tag tag-sm tag-info" *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">{{cve.impact.baseMetricV2.exploitabilityScore}}</span>
            <span class="tag tag-sm text-bg-light" *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">N/A</span>

          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketablecell">{{cve.lastModifiedDate | date: 'dd/MM/yyyy HH:mm'}}</div>
          <div class="col-xl-2 col-lg-2 d-none d-lg-block faketablecell">{{cve.publishedDate | date: 'dd/MM/yyyy HH:mm'}}</div>
          <div class="col-xl-1 col-lg-1 col-md-1 d-none d-md-block faketablecell">
            <div class="spinner-grow spinner-grow-sm text-primary" *ngIf="flaggingRef && flaggingRef === cve._id">
              <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
            </div>
            <fa-icon [icon]="['far', 'flag']" (click)="flagSignal($event, cve._id)" *ngIf="(!flagRefs.includes(cve._id)) && (!flaggingRef || (flaggingRef && flaggingRef !== cve._id))"></fa-icon>
            <fa-icon [icon]="['fas', 'flag']" (click)="flagSignal($event, cve._id)" *ngIf="(flagRefs.includes(cve._id)) && (!flaggingRef || (flaggingRef && flaggingRef !== cve._id))"></fa-icon>
          </div>
        </div>
        <div class="row">
          <div class="col-12 faketablecell text-start"><span *ngIf="cve.cve && cve.cve.description && cve.cve.description.description_data">{{cve.cve.description.description_data[0].value}}</span></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center p-5" *ngIf="!loaded">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  </div>

  <div class="row p-3 justify-content-center redborder-sides" *ngIf="loaded && cves && cves.length === 0">
    <div class="col-md-12 table-border justify-content-center">
      {{ 'pages.updates.vulnerabilities.noResults' | translate }}
    </div>
  </div>
