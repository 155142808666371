/* eslint-disable no-underscore-dangle */
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DateRange, SignalLoadListCriteria, SignalStatsCriteria } from 'src/app/shared/model/searchCriteria';
import { MonthlyStats, Signal, SignalStat, WordCloudItem } from 'src/app/shared/model/signal';
import { WorldWatchService } from 'src/app/shared/services/worldwatch.service';
import { Utilities } from 'src/app/shared/utilities';

import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import WordCloud from 'highcharts/modules/wordcloud.js';
import { FlaggedListComponent } from 'src/app/shared/components/flagged-list/flagged-list.component';
import { ACTIONS, constants, PAGES, TYPES } from 'src/app/shared/constants';
import {
  GenerateReportParameters,
  ModalParameters,
  SaveFilterParameters
} from 'src/app/shared/modals/modal';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { Pages, UserTheme } from 'src/app/shared/model/shared-items';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ChartService, HighchartsOptions } from 'src/app/shared/services/chart.service';
import { ContextService } from 'src/app/shared/services/context-service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { WorldWatchUtilService } from '../worldwatch-util-service';
import moment from 'moment-timezone';

WordCloud(Highcharts);

@Component({
  selector: 'app-worldwatch',
  templateUrl: './worldwatch.component.html',
  styleUrls: ['./worldwatch.component.scss'],
})
export class WorldWatchComponent extends FlaggedListComponent implements OnInit {

  @Input() theme: UserTheme;

  // data
  signals: ReducedAdvisory[] = [];
  signalsCount: number;
  flaggedSignalsCriteria: SignalLoadListCriteria;
  loading = false;
  loadLastVisit = false;
  lastLoginTimestamp: string;

  dateRanges: DateRange[];

  // saved searches
  searched: boolean;
  public savedFilters: AdvisorySavedFilter[];
  public selectedFilter: AdvisorySavedFilter;
  searchName: string;
  openSaveSearch: boolean;

  // view
  page: Pages = Pages.signals;

  // search
  advSearch = false;

  // search fields
  //reference: string;
  query: string;
  //category: string;
  urgency: string;
  iocQuery: string;
  publishFrom: string;
  publishTo: string;

  datepipe: DatePipe = new DatePipe('en-GB');

  // initial page to load
  loadpage: string;
  public scrollLoading = false;

  selectAll = '';
  unSelectAll = '';

  //dropdowns
  selectedUrgencies = [];
  urgenciesData = [
    {
      label: 'Information',
      value: 0
    },
    {
      label: 'Very Low',
      value: 1
    },
    {
      label: 'Low',
      value: 2
    },
    {
      label: 'Medium',
      value: 3
    },
    {
      label: 'High',
      value: 4
    },
    {
      label: 'Critical',
      value: 5
    }
  ]
  dropdownSettings = {
    singleSelection: true,
    selectAllText: this.selectAll,
    unSelectAllText: this.unSelectAll,
    itemsShowLimit: 3,
    allowSearchFilter: false,
    textField: "label",
    idField: 'value'
  };

  public signalsPage = Pages.signals;
  public flaggedPage = Pages.flagged;
  public analyticsPage = Pages.analytics;

  // ANALYTICS

  // monthly stats
  monthWord: string;
  urgencyStats: SignalStat[];
  categoryStats: SignalStat[];
  totalStats: number;
  sinceLastLogin: number;

  // annuals stats
  annualSignals: Signal[];
  annualUrgencyStats: SignalStat[];
  annualCategoryStats: SignalStat[];
  annualTotalStats: number;

  // graphs
  Highcharts: typeof Highcharts = Highcharts;
  chartDataLoaded: boolean;
  annualChartDataLoaded: boolean;

  updateCategoryPie: boolean;
  catPieChartOptions: Highcharts.Options;

  updateUrgencyPie: boolean;
  urgPieChartOptions: Highcharts.Options;

  updateWordChart: boolean;
  wordChartOptions: Highcharts.Options;

  updateAnnualCategoryPie: boolean;
  catAnnualPieChartOptions: Highcharts.Options;

  updateAnnualUrgencyPie: boolean;
  urgAnnualPieChartOptions: Highcharts.Options;

  updateAnnualWordChart: boolean;
  wordAnnualChartOptions: Highcharts.Options;

  updateStackedBarUrgency: boolean;
  loadStackedBarUrgency = false;
  stackedBarUrgencyOptions: Highcharts.Options;

  updateStackedBarCategory: boolean;
  loadStackedBarCategory = false;
  stackedBarCategoryOptions: HighchartsOptions;

  updateStackedBarBreach: boolean;
  loadStackedBarBreach = false;
  stackedBarBreachOptions: HighchartsOptions;

  constructor(
    private worldwatchService: WorldWatchService,
    public userprefService: UserPreferenceService,
    public alertService: AlertService,
    private modal: ModalService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userActivity: UserActivityService,
    private notificationService: NotificationService,
    private worldWatchUtilService: WorldWatchUtilService,
    private chartService: ChartService,
    public context: ContextService,
    private formBuilder: FormBuilder
  ) {
    super(userprefService, alertService);
    this.flagType = constants.local.flags.worldwatch;
    this.viewType = constants.local.views.signals;
  }

  public advisoryForm: FormGroup = new FormGroup({});

  // GLOBAL FORM GETTERS

  get id() {
    return this.advisoryForm.get('id');
  }

  get title() {
    return this.advisoryForm.get('title');
  }

  get severity() {
    return this.advisoryForm.get('severity');
  }

  get threatCategoryTitle() {
    return this.advisoryForm.get('threatCategoryTitle');
  }

  get createdAfter() {
    return this.advisoryForm.get('createdAfter');
  }

  get createdBefore() {
    return this.advisoryForm.get('createdBefore');
  }

  get createdRange() {
    return this.advisoryForm.get('createdRange');
  }

  get updatedAfter() {
    return this.advisoryForm.get('updatedAfter');
  }

  get updatedBefore() {
    return this.advisoryForm.get('updatedBefore');
  }

  get updatedRange() {
    return this.advisoryForm.get('updatedRange');
  }

  get limit() {
    return this.advisoryForm.get('limit');
  }

  get offset() {
    return this.advisoryForm.get('offset');
  }

  get sortBy() {
    return this.advisoryForm.get('sortBy');
  }

  get sortOrder() {
    return this.advisoryForm.get('sortOrder');
  }

  ngOnInit() {
    // init labels
    this.selectAll = this.translateService.instant('pages.generic.selectAll');
    this.unSelectAll = this.translateService.instant('pages.generic.unSelectAll');

    this.initForm();

    this.lastLoginTimestamp = this.userPreferenceService.userPreferences?.lastLogin;

    this.route.params.subscribe((params) => (this.loadpage = params.loadpage));

    // initialise
    this.searched = false;
    this.openSaveSearch = false;
    this.chartDataLoaded = false;

    this.stackedBarUrgencyOptions = this.chartService.initStackedBarChart('12 Month Trend Analysis - Urgency');
    this.stackedBarCategoryOptions = this.chartService.initStackedBarChart('12 Month Trend Analysis - Category');
    this.stackedBarBreachOptions = this.chartService.initStackedBarChart('12 Month Trend Analysis - Breach');

    // these booleans dictate when to refresh charts
    this.updateWordChart = false;
    this.updateUrgencyPie = false;
    this.updateCategoryPie = false;

    // load the flags signals from the user preferences
    this.refreshSignalUserPrefs();

    // load default page for navigation
    if (!this.loadpage || this.loadpage === 'latest') {
      this.loadAdvisories(false)
    } 
    
    this.loadSinceLastVisit(this.loadpage === 'lastlogin');
    this.dateRanges = [DateRange.publishDate];
    this.userActivity.logActivity(TYPES.resources, PAGES.worldwatch, ACTIONS.visit);
  }

  /**
  * init the reactive log case form
  */
  private initForm() {

    this.advisoryForm.addControl('id', this.formBuilder.control({value: '', disabled: true}, []));

    this.advisoryForm.addControl('title', this.formBuilder.control("", []));

    this.advisoryForm.addControl('severity', this.formBuilder.control("", []));

    this.advisoryForm.addControl('threatCategoryTitle', this.formBuilder.control("", []));
 
    this.advisoryForm.addControl('createdAfter', this.formBuilder.control("", []));

    this.advisoryForm.addControl('createdBefore', this.formBuilder.control("", []));

    this.advisoryForm.addControl('createdRange', this.formBuilder.control("", []));

    this.advisoryForm.addControl('updatedAfter', this.formBuilder.control("", []));

    this.advisoryForm.addControl('updatedBefore', this.formBuilder.control("", []));

    this.advisoryForm.addControl('updatedRange', this.formBuilder.control("", []));

    this.advisoryForm.addControl('sortBy', this.formBuilder.control("timestamp_updated", []));
  
    this.advisoryForm.addControl('sortOrder', this.formBuilder.control("desc", []));

    this.advisoryForm.addControl('limit', this.formBuilder.control(30, []));

    this.advisoryForm.addControl('offset', this.formBuilder.control(0, []));

    this.updatedAfter.valueChanges.subscribe(() => {
      this.rangeValidator('updated');
    })

    this.updatedBefore.valueChanges.subscribe(() => {
      this.rangeValidator('updated');
    })

    this.createdAfter.valueChanges.subscribe(() => {
      this.rangeValidator('created');
    })

    this.createdBefore.valueChanges.subscribe(() => {
      this.rangeValidator('created');
    })

  }

  /**
   * Reset fields and criteria
   */
  public reset(loadList: boolean) {
    this.advisoryForm.reset();
    this.sortOrder.patchValue("desc");
    this.sortBy.patchValue("timestamp_updated");
    this.limit.patchValue(30);
    this.offset.patchValue(0);
    this.selectedFilter = null;
    if(loadList) {
      this.loadAdvisories(false);
    }
  }

  /**
   * convert localDate to UTC
   */
  private getUTCDate(timeStamp) {
      return new Date(timeStamp).toISOString();
  }

   /**
   * convert date to UTC
   */
   private getLocalDate(timeStamp) {
      const date = new Date(timeStamp).toISOString();
      return moment(date).local().format('YYYY-MM-DDTHH:mm');
  }

  public displayAdvisories() {
    this.page = this.signalsPage;
    this.reset(false);
    this.loadAdvisories(false)

  }

  public displayFlaggedAdvisories() {
    this.page = this.flaggedPage;
    this.advisoryForm.reset();
    this.reset(false);
    this.loadAdvisories(false);
  }

  public loadAdvisories(fromScroll: boolean) {

    if (!this.loading) {
      this.loading = true;

      // increment offset if from scrolling
      if (fromScroll) {

        if (this.page === this.flaggedPage) {
          // we don't scroll load flagged advisories, they are all returned after first load
          this.loading = false;
          return;
        }
        
        //check offset 
        let totalCount = this.signalsCount;
        if (this.offset.value + this.limit.value >= totalCount) {
          this.loading = false;
          return
        }

        this.offset.patchValue(this.offset.value + this.limit.value);
      } else {
        this.signals = [];
      }

      const flags = this.userPreferenceService.userPreferences?.flags;
      if(this.page === this.flaggedPage && (!flags ||  !flags.worldwatch || flags.worldwatch.length === 0)) {
        // no flagged advisories to load
        this.loading = false;
        return;
      }

      // binding backend filter
      const requestFilter = new AdvisoryFilter();
      requestFilter.mapFilterWithForm(this.advisoryForm.value);

      const $request = this.page === this.signalsPage ? this.worldwatchService.getAdvisories(requestFilter) : this.worldwatchService.getFlaggedAdvisories(requestFilter);
      $request.subscribe({
        next: response => {
          const items = response.items;
          if (this.page === this.signalsPage) {
            this.signalsCount = response.count;
          }

          this.addSeverityStyle(items)
          this.signals = this.signals.concat(items);
          this.loading = false;
        },
        error: error => {
          this.alertService.handlerError(error);
          this.loading = false;
        }
      })
    }

  }

  /**
   * 
   * @param list 
   */
  private addSeverityStyle(list: ReducedAdvisory[]) {
    list.forEach(advisory => {
      advisory.severityStyle = this.worldWatchUtilService.getSeverityStyle(advisory.severity);
    })
  }

  public applySearch() {
    this.signals = [];
    this.offset.patchValue(0);
    this.loadAdvisories(false);
  }

  /**
   * Open export modal
   */
  public openExportModal() {
    const modalParameters = new GenerateReportParameters();
    modalParameters.reportCanBeScheduled = false;
    this.modal.exportModal(modalParameters);
  }

  /**
   * Toggle between grid/list view
   *
   * @param view
   */
  public changeViewType(view: string) {
    // set local variable to determine what view the user is using
    this.viewType = view;

    // update the users flags appropriately
    this.userprefService
      .userPreferencesUpdateSingle(constants.local.views.signals, view)
      .then(() => {
        this.refreshSignalUserPrefs();
      })
      .catch((err) => {
        this.alertService.handlerError(err);
      });
  }

  /**
   * Load first 12 signals matching with search criteria
   */
  /*
  public loadSignalsTab(): void {
    this.signalsLoaded = false;
    this.searched = false;

    this.searchCriteria = new SignalSearchCriteria();
    this.manageFilters();
    this.searchCriteria.limit = 12;
    this.searchCriteria.returnStub = true;

    this.loadSignalDatas()
      .then(() => {
        this.signalsLoaded = true;
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }
  */

  /**
   * Load all the annual analytics data for graphical presentation
   * Urgency and categories need formatting for charts
   */
  public loadAnalytics(): void {
    // monthly data
    Promise.all([
      this.getMonthlyUrgencyTotalStats(Utilities.getFirstOfCurrentMonth(), Utilities.getLastOfCurrentMonth()),
      this.getMonthlyCategoryTotalStats(Utilities.getFirstOfCurrentMonth(), Utilities.getLastOfCurrentMonth()),
    ]).then(() => {
      this.monthWord = Utilities.getCurrentMonthWord();
      this.chartDataLoaded = true;
    });

    // annual data
    //this.loadAnnualSignals();

    // load category & breach chart
    // use session cache where possible
    if (
      sessionStorage.getItem('signalAnnualCategorySeries') &&
      sessionStorage.getItem('signalAnnualCategoryCategories')
    ) {

      const categories = JSON.parse(
        sessionStorage.getItem('signalAnnualCategoryCategories')
      );
      this.stackedBarCategoryOptions.xAxis.categories = categories;
      this.stackedBarBreachOptions.xAxis.categories = categories;

      const series = JSON.parse(sessionStorage.getItem('signalAnnualCategorySeries'));
      this.stackedBarCategoryOptions.series = series;
      this.stackedBarBreachOptions.series = [
        series[1],
        series[2],
        series[4],
      ];
      this.updateStackedBarBreach = true;
      this.loadStackedBarBreach = true;
      this.updateStackedBarCategory = true;
      this.loadStackedBarCategory = true;
    } else {
      this.loadAnnualCategoryMonthlyStats()
        .then((res) => {
          this.stackedBarCategoryOptions.xAxis.categories = [];

          // initialise an array for each urgency
          const adv: Highcharts.SeriesColumnOptions = { name: 'Advisory', data: [], type: 'column' };
          const threat: Highcharts.SeriesColumnOptions = { name: 'Threat', data: [], type: 'column' };
          const vuln: Highcharts.SeriesColumnOptions = { name: 'Vulnerability', data: [], type: 'column' };
          const news: Highcharts.SeriesColumnOptions = { name: 'News', data: [], type: 'column' };
          const breach: Highcharts.SeriesColumnOptions = { name: 'Breach', data: [], type: 'column' };
          const brkst: Highcharts.SeriesColumnOptions = { name: 'Breaking Story', data: [], type: 'column' };
          const update: Highcharts.SeriesColumnOptions = { name: 'Update', data: [], type: 'column' };
          const annou: Highcharts.SeriesColumnOptions = { name: 'Announcement', data: [], type: 'column' };

          let arrayIndex = 1;
          res.forEach((dataPoint) => {
            // add this months data to categories
            const x = dataPoint.name.substring(4) + '/' + dataPoint.name.substring(0, 4);
            this.stackedBarCategoryOptions.xAxis.categories.push(x);
            this.stackedBarBreachOptions.xAxis.categories.push(x);

            // get this months data point for each urgency
            dataPoint.data.forEach((dp) => {
              if (dp._id.mainCategory === 'Advisory') {
                adv.data.push(dp.count);
              } else if (dp._id.mainCategory === 'Threat') {
                threat.data.push(dp.count);
              } else if (dp._id.mainCategory === 'Vulnerability') {
                vuln.data.push(dp.count);
              } else if (dp._id.mainCategory === 'News') {
                news.data.push(dp.count);
              } else if (dp._id.mainCategory === 'Breach') {
                breach.data.push(dp.count);
              } else if (dp._id.mainCategory === 'Breaking Story') {
                brkst.data.push(dp.count);
              } else if (dp._id.mainCategory === 'Update') {
                update.data.push(dp.count);
              } else if (dp._id.mainCategory === 'Announcement') {
                annou.data.push(dp.count);
              }
            });

            // if there were no datapoints for this month then add a 0
            if (adv.data.length !== arrayIndex) {
              adv.data.push(0);
            }
            if (threat.data.length !== arrayIndex) {
              threat.data.push(0);
            }
            if (vuln.data.length !== arrayIndex) {
              vuln.data.push(0);
            }
            if (news.data.length !== arrayIndex) {
              news.data.push(0);
            }
            if (breach.data.length !== arrayIndex) {
              breach.data.push(0);
            }
            if (brkst.data.length !== arrayIndex) {
              brkst.data.push(0);
            }
            if (update.data.length !== arrayIndex) {
              update.data.push(0);
            }
            if (annou.data.length !== arrayIndex) {
              annou.data.push(0);
            }
            arrayIndex++;
          });

          const catSeries = [];
          catSeries.push(adv);
          catSeries.push(threat);
          catSeries.push(vuln);
          catSeries.push(news);
          catSeries.push(breach);
          catSeries.push(brkst);
          catSeries.push(update);
          catSeries.push(annou);

          this.stackedBarCategoryOptions.series = catSeries;
          this.stackedBarBreachOptions.series = [breach, vuln, threat];

          sessionStorage.setItem('signalAnnualCategorySeries', JSON.stringify(catSeries));
          sessionStorage.setItem('signalAnnualCategoryCategories',
            JSON.stringify(this.stackedBarCategoryOptions.xAxis.categories)
          );

          this.updateStackedBarBreach = true;
          this.loadStackedBarBreach = true;
          this.updateStackedBarCategory = true;
          this.loadStackedBarCategory = true;
        })
        .catch((err) => {
          this.alertService.handlerError(err);
        });
    }

    // load urgency chart
    // use session cache where possible
    if (
      sessionStorage.getItem('signalAnnualUrgencySeries') &&
      sessionStorage.getItem('signalAnnualUrgencyCategories')
    ) {
      this.stackedBarUrgencyOptions.xAxis = { categories: JSON.parse(sessionStorage.getItem('signalAnnualUrgencyCategories')) };
      this.stackedBarUrgencyOptions.series = JSON.parse(sessionStorage.getItem('signalAnnualUrgencySeries'));
      this.updateStackedBarUrgency = true;
      this.loadStackedBarUrgency = true;
    } else {
      this.loadAnnualUrgencyMonthlyStats()
        .then((res) => {
          this.stackedBarUrgencyOptions.xAxis = { categories: [] };

          // initialise an array for each urgency
          const info = { name: 'Information', data: [] };
          const low = { name: 'Low', data: [] };
          const med = { name: 'Medium', data: [] };
          const high = { name: 'High', data: [] };
          const crit = { name: 'Critical', data: [] };

          let arrayIndex = 1;
          const categories = [];
          res.forEach((dataPoint) => {
            // add this months data to categories
            const x = dataPoint.name.substring(4) + '/' + dataPoint.name.substring(0, 4);
            categories.push(x);

            // get this months data point for each urgency
            dataPoint.data.forEach((dp) => {
              if (dp._id.signalUrgency === 'Information') {
                info.data.push(dp.count);
              } else if (dp._id.signalUrgency === 'Low') {
                low.data.push(dp.count);
              } else if (dp._id.signalUrgency === 'Medium') {
                med.data.push(dp.count);
              } else if (dp._id.signalUrgency === 'High') {
                high.data.push(dp.count);
              } else if (dp._id.signalUrgency === 'Critical') {
                crit.data.push(dp.count);
              }
            });

            // if there were no datapoints for this month then add a 0
            if (info.data.length !== arrayIndex) {
              info.data.push(0);
            }
            if (low.data.length !== arrayIndex) {
              low.data.push(0);
            }
            if (med.data.length !== arrayIndex) {
              med.data.push(0);
            }
            if (high.data.length !== arrayIndex) {
              high.data.push(0);
            }
            if (crit.data.length !== arrayIndex) {
              crit.data.push(0);
            }
            arrayIndex++;
          });

          this.stackedBarUrgencyOptions.xAxis = { categories };

          const urgencySeries = [];
          urgencySeries.push(info);
          urgencySeries.push(low);
          urgencySeries.push(med);
          urgencySeries.push(high);
          urgencySeries.push(crit);

          sessionStorage.setItem('signalAnnualUrgencySeries', JSON.stringify(urgencySeries));
          const xAxis = this.stackedBarUrgencyOptions.xAxis as Highcharts.XAxisOptions;
          sessionStorage.setItem(
            'signalAnnualUrgencyCategories',
            JSON.stringify(xAxis.categories)
          );

          this.stackedBarUrgencyOptions.series = urgencySeries;
          this.updateStackedBarUrgency = true;
          this.loadStackedBarUrgency = true;
        })
        .catch((err) => {
          this.alertService.handlerError(err);
        });
    }
  }

  /**
  * Load signals created since last visit
  */
  public loadSinceLastVisit(clicked: boolean): void {
    const lastLogin = this.userPreferenceService.userPreferences?.lastLogin;

    if(clicked) {
      this.reset(false);
      this.page = this.signalsPage;
      const localDate = this.getLocalDate(lastLogin);
      this.updatedAfter.patchValue(localDate);
      this.loadAdvisories(false)
    } else {
      this.loadLastVisit = true;
      const filter = new AdvisoryFilter();
      filter.updatedAfter = this.getUTCDate(lastLogin);
      this.worldwatchService.getAdvisories(filter).subscribe({
        next:(response) => {
          this.sinceLastLogin = response.count;
          this.loadLastVisit = false;
        },
        error:(err) => {
          this.alertService.handlerError(err);
          this.loadLastVisit = false;
        }
      });
    }    
  }

  /**
   * Click through helpers from analytics page
   *
   * @param type annual or monthly, determines date range for search
   */
  public loadNew(type: string) {
    this.reset(false);
    this.advSearch = true;
    if (type.toLowerCase() === 'month') {
      this.publishFrom = Utilities.getFirstOfCurrentMonth();
      this.publishTo = Utilities.getLastOfCurrentMonth();
    } else if (type.toLowerCase() === 'annual') {
      this.publishFrom = Utilities.get12MonthsAgo();
      this.publishTo = Utilities.getToday();
    }
    //this.search();
  }

  /**
   * Click through helpers from analytics page
   *
   * @param type annual or monthly, determines date range for search
   * @param category
   */
  public loadCategory(type: string, category: string) {
    this.reset(false);
    //this.category = category;
    this.advSearch = true;
    if (type.toLowerCase() === 'month') {
      this.publishFrom = Utilities.getFirstOfCurrentMonth();
      this.publishTo = Utilities.getLastOfCurrentMonth();
    } else if (type.toLowerCase() === 'annual') {
      this.publishFrom = Utilities.get12MonthsAgo();
      this.publishTo = Utilities.getToday();
    }
    //this.search();
  }

  /**
   * Click through helpers from analytics page
   *
   * @param type annual or monthly, determines date range for search
   * @param urgency
   */
  public loadUrgency(type: string, urgency: string) {
    this.reset(false);
    this.selectedUrgencies = [urgency];
    this.advSearch = true;
    if (type.toLowerCase() === 'month') {
      this.publishFrom = Utilities.getFirstOfCurrentMonth();
      this.publishTo = Utilities.getLastOfCurrentMonth();
    } else if (type.toLowerCase() === 'annual') {
      this.publishFrom = Utilities.get12MonthsAgo();
      this.publishTo = Utilities.getToday();
    }
    //this.search();
  }

  /**
   * Open a modal for saving a search
   */
  public openSearchModal() {
    const params = new SaveFilterParameters();
    params.savedSearches = this.savedFilters;
    params.successCallback = (result) => {
      if (result.selectedFilter) {
        // update existing filter
        this.selectedFilter = result.selectedFilter as AdvisorySavedFilter;
        this.saveSearch(true);
      } else {
        // create new filter
        this.searchName = result.filterName;
        if (this.searchName) {
          this.saveSearch();
        }
      }
    };
    this.modal.saveFilterModal(params);
  }

  /**
   * Open a modal to delete a saved search
   */
  public deleteSearch(): void {
    const params = new ModalParameters();
    params.title = this.translateService.instant('pages.generic.deleteSearchModal');
    params.bodyMessage = this.translateService.instant('pages.generic.deleteSearchConfirm');
    params.closeBtnLabel = this.translateService.instant('pages.generic.close');
    params.successBtnLabel = this.translateService.instant('pages.generic.confirm');
    params.successCallback = () => {
      this.userprefService
        .userPreferencesUpdateChildArray('searches', 'signal', this.selectedFilter, true)
        .then(() => {
          this.refreshSignalUserPrefs();
        });
    };
    this.modal.confirmModal(params);
  }


  /**
   * Refresh the local flagRefs and viewType variable from user preferences
   */
  private refreshSignalUserPrefs() {

    // load saved filters
    const preference = this.userprefService.userPreferences;
    if (preference?.searches?.signal) {
      this.savedFilters = preference.searches.signal;
    } else {
      this.savedFilters = [];
    }
    // load the flagged signals
    if (preference?.flags?.worldwatch) {
      this.flagRefs = preference.flags.worldwatch
    } else {
      this.flagRefs = [];
    }
  }

  /**
   * Return the signal count for urgency in a given annual time frame
   * This give totals across the entire year rather than broken down by month
   *
   * @param pubDateFrom
   * @param pubDateTo
   */
  private getAnnualUrgencyTotalStats(pubDateFrom: string, pubDateTo: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getAnnualUrgencyTotalStatsCache(pubDateFrom, pubDateTo)
        .then((res) => {
          this.annualTotalStats = this.annualUrgencyStats.reduce((a, b) => a + b.count, 0);
          const data = [];
          this.annualUrgencyStats.forEach((c) => {
            data.push([c._id, c.count]);
          });
          this.urgAnnualPieChartOptions = this.chartService.initWwPieChart(data, 'Urgency');
          this.updateAnnualUrgencyPie = true;
          resolve();
        })
        .catch((err) => {
          this.alertService.handlerError(err);
        });
    });
  }

  /**
   * Use cache if it exists, if not load from database
   *
   * @param pubDateFrom
   * @param pubDateTo
   */
  private getAnnualUrgencyTotalStatsCache(pubDateFrom: string, pubDateTo: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (sessionStorage.getItem('signalAnnualUrgencyStats')) {
        this.annualUrgencyStats = JSON.parse(sessionStorage.getItem('signalAnnualUrgencyStats'));
        resolve();
      } else {
        this.worldwatchService
          .signalUrgencyStats(pubDateFrom, pubDateTo)
          .then((res) => {
            this.annualUrgencyStats = res;
          })
          .then(() => {
            sessionStorage.setItem('signalAnnualUrgencyStats', JSON.stringify(this.annualUrgencyStats));
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  /**
   * Return the signal count for urgency in a given monthly time frame
   *
   * @param pubDateFrom
   * @param pubDateTo
   */
  private getMonthlyUrgencyTotalStats(pubDateFrom: string, pubDateTo: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getMonthlyUrgencyTotalStatsCache(pubDateFrom, pubDateTo)
        .then((res) => {
          this.totalStats = this.urgencyStats.reduce((a, b) => a + b.count, 0);
          this.processSignalsWordCloud(this.signals).then((res2) => {
            const t = [];
            res2.forEach((e) => {
              if (e.weight > 1 && e.name.length > 2 && !constants.ignoreList.includes(e.name)) {
                t.push(e);
              }
            });
            this.wordChartOptions = this.chartService.initWwWordChart(t);
            this.updateWordChart = true;
          });
          const data = [];
          this.urgencyStats.forEach((c) => {
            data.push([c._id, c.count]);
          });
          this.urgPieChartOptions = this.chartService.initWwPieChart(data, 'Urgency');
          this.updateUrgencyPie = true;
          resolve();
        })
        .catch((err) => {
          this.alertService.handlerError(err);
          reject(err);
        });
    });
  }

  /**
   * Use cache if it exists, if not load from database
   *
   * @param pubDateFrom
   * @param pubDateTo
   */
  private getMonthlyUrgencyTotalStatsCache(pubDateFrom: string, pubDateTo: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (sessionStorage.getItem('signalMonthUrgencyStats')) {
        this.urgencyStats = JSON.parse(sessionStorage.getItem('signalMonthUrgencyStats'));
        resolve();
      } else {
        this.worldwatchService
          .signalUrgencyStats(pubDateFrom, pubDateTo)
          .then((res) => {
            this.urgencyStats = res;
          })
          .then(() => {
            sessionStorage.setItem('signalMonthUrgencyStats', JSON.stringify(this.urgencyStats));
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  /**
   * Return the signal count for categories in a given time frame
   * This give totals across the entire year rather than broken down by month
   *
   * @param pubDateFrom
   * @param pubDateTo
   */
  private getAnnualCategoryTotalStats(pubDateFrom: string, pubDateTo: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getAnnualCategoryTotalStatsCache(pubDateFrom, pubDateTo)
        .then((res) => {
          const data = [];
          this.annualCategoryStats.forEach((c) => {
            data.push([c._id, c.count]);
          });
          this.catAnnualPieChartOptions = this.chartService.initWwPieChart(data, 'Category');
          this.updateAnnualCategoryPie = true;
          resolve();
        })
        .catch((err) => {
          this.alertService.handlerError(err);
          reject(err);
        });
    });
  }

  /**
   * Use cache if it exists, if not load from database
   *
   * @param pubDateFrom
   * @param pubDateTo
   */
  private getAnnualCategoryTotalStatsCache(pubDateFrom: string, pubDateTo: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (sessionStorage.getItem('signalAnnualCategoryStats')) {
        this.annualCategoryStats = JSON.parse(sessionStorage.getItem('signalAnnualCategoryStats'));
        resolve();
      } else {
        this.worldwatchService
          .signalCategoryStats(pubDateFrom, pubDateTo)
          .then((res) => {
            this.annualCategoryStats = res;
          })
          .then(() => {
            sessionStorage.setItem('signalAnnualCategoryStats', JSON.stringify(this.annualCategoryStats));
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  /**
   * Return the signal count for categories in a given time frame
   * This give totals across the entire year rather than broken down by month
   *
   * @param pubDateFrom
   * @param pubDateTo
   */
  private getMonthlyCategoryTotalStats(pubDateFrom: string, pubDateTo: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getMonthlyCategoryTotalStatsCache(pubDateFrom, pubDateTo)
        .then((res) => {
          const data = [];
          this.categoryStats.forEach((c) => {
            data.push([c._id, c.count]);
          });
          this.catPieChartOptions = this.chartService.initWwPieChart(data, 'Category');
          this.updateCategoryPie = true;
          resolve();
        })
        .catch((err) => {
          this.alertService.handlerError(err);
          reject(err);
        });
    });
  }

  /**
   * Use cache if it exists, if not load from database
   *
   * @param pubDateFrom
   * @param pubDateTo
   */
  private getMonthlyCategoryTotalStatsCache(pubDateFrom: string, pubDateTo: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (sessionStorage.getItem('signalMonthCategoryStats')) {
        this.categoryStats = JSON.parse(sessionStorage.getItem('signalMonthCategoryStats'));
        resolve();
      } else {
        this.worldwatchService
          .signalCategoryStats(pubDateFrom, pubDateTo)
          .then((res) => {
            this.categoryStats = res;
          })
          .then(() => {
            sessionStorage.setItem('signalMonthCategoryStats', JSON.stringify(this.categoryStats));
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  /*
  private loadSignalsCount(): Promise<void> {
    const filter = Object.assign({}, this.searchCriteria);
    filter.count = true;
    filter.limit = undefined;
    return this.worldwatchService
    .signalSearch(filter)
    .then((res) => {
      this.signalsCount = res && res[0] ? res[0].matching_signals : 0;
    })
    .catch((err) => {
      this.handlerError(err);
    });
  }
    */

  /**
   * Return the top 10 signals
   */
  /*
  private loadAnnualSignals(): void {
    this.loaded = false;

    this.loadAnnualSignalsCache()
      .then(() => {
        Promise.all([
          this.getAnnualUrgencyTotalStats(Utilities.get12MonthsAgo(), Utilities.getToday()),
          this.getAnnualCategoryTotalStats(Utilities.get12MonthsAgo(), Utilities.getToday()),
        ]).then(() => {
          this.annualChartDataLoaded = true;
        });
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }
      */

  /**
   * Load from cache if valid, else database
   */
  /*
  private loadAnnualSignalsCache(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (sessionStorage.getItem('signalAnnualSignals')) {
        this.annualSignals = JSON.parse(sessionStorage.getItem('signalAnnualSignals'));
        resolve();
      } else {
        this.searchCriteria = new SignalSearchCriteria();
        this.searchCriteria.updateDateFrom = Utilities.get12MonthsAgo();
        this.searchCriteria.updateDateTo = Utilities.getToday();
        this.searchCriteria.returnStub = true;
        this.worldwatchService
          .signalSearch(this.searchCriteria)
          .then((res) => {
            this.annualSignals = res;
          })
          .then(() => {
            sessionStorage.setItem('signalAnnualSignals', JSON.stringify(this.annualSignals));
            resolve();
          })
          .catch((err) => {
            this.handlerError(err);
            reject(err);
          });
      }
    });
  }
    */

  /**
   * Returns the signal count for urgency for the last 12 months
   * This is broken down by month
   */
  private loadAnnualUrgencyMonthlyStats(): Promise<MonthlyStats[]> {
    const searchCriteria = new SignalStatsCriteria();
    searchCriteria.attributeName = constants.fields.signals.urgency;
    searchCriteria.publishDateFrom = Utilities.get12MonthsAgo();
    searchCriteria.publishDateTo = Utilities.getToday();
    return this.worldwatchService.signalMonthlyStats(searchCriteria);
  }

  /**
   * Returns the signal count for category for the last 12 months
   * This is broken down by month
   */
  private loadAnnualCategoryMonthlyStats(): Promise<MonthlyStats[]> {
    const searchCriteria = new SignalStatsCriteria();
    searchCriteria.attributeName = constants.fields.signals.category;
    searchCriteria.publishDateFrom = Utilities.get12MonthsAgo();
    searchCriteria.publishDateTo = Utilities.getToday();

    return this.worldwatchService.signalMonthlyStats(searchCriteria);
  }

  /**
   * Process the summry of each signal into a counted word list
   * for the use in a wordcloud
   */
  private processSignalsWordCloud(signals: ReducedAdvisory[]): Promise<WordCloudItem[]> {
    return new Promise((resolve, reject) => {
      // concat all the summary
      let totalWords = '';
      signals.forEach((s) => {
        //totalWords = totalWords + ' ' + s.summary.toLowerCase() + ' ' + s.whatYouWillHear.toLowerCase();
      });

      // break down into a word count
      const lines = totalWords.split(/[,\. \(\)]+/g);
      resolve(
        lines.reduce(
          (arr, word) => {
            let obj: WordCloudItem = arr.find((obj1) => (obj1.name === word));
            if (obj) {
              obj.weight += 1;
            } else {
              obj = {
                name: word,
                weight: 1,
              };
              arr.push(obj);
            }
            return arr;
          },
          []
        )
      );
    });
  }

  /**
   * callback used to trigger the worldwatch export from modal confirm
   *
   * @param params
   */
  /*
  private exportWorldwatch(params: ExportParameters) {
    this.loaded = false;
    //this.manageFilters();
    // fetch the actual records
    this.searchCriteria.count = false;
    this.searchCriteria.limit = undefined;
    this.searchCriteria.exportPDF = true;
    this.searchCriteria.PDFName =
      params.pdfName +
      ' - ' +
      constants.accessLevels[params.pdfAccessLevel] +
      '.' +
      (params.reportOption === FileExtension.csv ? FileExtension.csv : FileExtension.pdf);
    this.searchCriteria.pdfAccessLevel = params.pdfAccessLevel;
    this.worldwatchService
      .signalSearch(this.searchCriteria)
      .then(() => {
        this.alertService.addSuccess(this.translateService.instant('pages.generic.fileuploaded'));
        this.loaded = true;
        this.userActivity.logActivity(TYPES.reports, PAGES.worldwatch, ACTIONS.generateReport);
        const fullName =
          `${Utilities.capitalizeFirstLetter(ReportPdfAccessLevel[this.searchCriteria.pdfAccessLevel])}/${this.searchCriteria.PDFName}`;
        this.notificationService.sendNotification(
          NotificationCategory.generateReport,
          {title: fullName, content: fullName},
          NotificationAudience.admins
        );
      })
      .catch((err) => {
        throw err;
      });
  }
      */

  public onSort(event) {
    if(event.sortBy) {
      this.sortBy.patchValue(event.sortBy);
    } else if(event.sortOrder) {
      this.sortOrder.patchValue(event.sortOrder);
    }
    this.loadAdvisories(false);
  }

  public hasActiveFilter(): boolean {

    // check the search part
    const hasFilterSearch = this.id.value || this.title.value || (this.severity.value && this.severity.value.length > 0) || this.threatCategoryTitle.value || this.createdAfter.value
      || this.createdBefore.value || this.updatedBefore.value || this.updatedAfter.value;

    if (!hasFilterSearch) {
      // if not selected filter, check also 'sortBy' and 'sortOrder'
      return this.sortBy.value !== 'timestamp_updated' || this.sortOrder.value !== 'desc';
    } else {
      return hasFilterSearch;
    }

  }

  /**
   * Save the search criteria in user preferences for future use
   * utility method for save search modal
   */
  public saveSearch(isUpdate = false): void {

    const saveFilter = new AdvisorySavedFilter();
    saveFilter.filter = JSON.parse(JSON.stringify(this.advisoryForm.value));
    delete saveFilter.filter.offset;

    if (!isUpdate) {
      saveFilter.name = this.searchName;
      delete saveFilter._id;
      this.selectedFilter = saveFilter;
    } else {
      saveFilter.name = this.selectedFilter.name;
      saveFilter._id = this.selectedFilter._id;
    }

    this.userprefService.userPreferencesUpdateChildArray('searches', 'signal', saveFilter, false).then((response) => {
      this.alertService.addSuccess(this.translateService.instant('pages.generic.searchSaved'));
      this.searchName = undefined;
      this.refreshSignalUserPrefs();
      this.selectedFilter = this.savedFilters.filter(f => f.name === this.selectedFilter.name)[0];
      this.openSaveSearch = true;
    });
  }

  /**
   * Load a saved search and run
   *
   * @param savedSearch
   */
  public loadSelectedFilter(savedFilter: AdvisorySavedFilter) {
    this.selectedFilter = savedFilter;
    this.advisoryForm.patchValue(savedFilter.filter);
    this.offset.patchValue(0);
    // this.advisoryFilter.
    //this.mapFilterWithForm();
    this.advSearch = !!this.createdAfter.value || !!this.createdBefore.value || !!this.updatedAfter.value || !!this.updatedBefore.value;
    this.loadAdvisories(false);
  }

  rangeValidator(type: string) {
    const controls = this.advisoryForm.controls;
    const beforeValue = type === 'created' ? controls["createdBefore"].value : controls["updatedBefore"].value;
    const afterValue = type === 'created' ? controls["createdAfter"].value : controls["updatedAfter"].value;
    if(beforeValue && afterValue) {
      const afterDate = moment(afterValue);
      const beforeDate = moment(beforeValue);
      const valid = afterDate.isBefore(beforeDate);

      if(type === 'created') {
        this.advisoryForm.controls['createdRange'].setErrors(valid ? null : {'incorrect': true});
      } else {
        this.advisoryForm.controls['updatedRange'].setErrors(valid ? null : {'incorrect': true});
      }
    };      
  }
}


export class AdvisoryFilter {
  title: string;
  id: number;
  tdcId: number;
  content: string;
  severity: string;
  tagsName: string;
  threatCategoryTitle: string;
  createdBefore: string;
  createdAfter: string;
  updatedBefore: string;
  updatedAfter: string;
  sortBy: string;
  sortOrder: string;
  limit: number;
  offset: number;

  public mapFilterWithForm(values) {
    this.title = values.title;
    this.tdcId = values.id;
    this.content = values.content;
    this.severity = values.severity && values.severity.length > 0 ? values.severity[0].value : undefined;;
    this.tagsName = values.tagsName;
    this.threatCategoryTitle = values.threatCategoryTitle;
    this.createdBefore = this.convertDateToUTC(values.createdBefore);
    this.createdAfter = this.convertDateToUTC(values.createdAfter);
    this.updatedBefore = this.convertDateToUTC(values.updatedBefore);
    this.updatedAfter = this.convertDateToUTC(values.updatedAfter);
    this.sortBy = values.sortBy;
    this.sortOrder = values.sortOrder;
    this.limit = values.limit;
    this.offset = values.offset;
  }

  /**
   * convert localDate to UTC
   */
  private convertDateToUTC(inputDate) {
    if(inputDate) {
      // add the Zero timsezone for backend request
      return inputDate + ":00Z";
    }
  }

    /**
   * convert localDate to UTC
   */
    private getUTCDate(timeStamp) {
      return new Date(timeStamp).toISOString();
  }

   /**
   * convert date to UTC
   */
   private getLocalDate(timeStamp) {
      const date = new Date(timeStamp).toISOString();
      return moment(date).local().format('YYYY-MM-DDTHH:mm');
  }
}

export class ReducedAdvisory {
  id: number;
  tdc_id: number;
  title: string;
  severity: number;
  tags: string[];
  threat_category: string;
  timestamp_created: string;
  timestamp_updated: string;
  license_agreement: string;

  // Additionnal params for UI
  severityStyle: string;
  severityLabel: string;
}

export class AdvisoryListResponse {
  count: number;
  items: ReducedAdvisory[];
}

export class TimeStamp {
  year: string;
  monthValue: number;
  dayOfMonth: number;
  hour: number;
  minute: number;
  second: number;
  nano: number;
  dayOfYear: string;
  month: string;
  offset: Offset;
}

export class Offset {
  totalSeconds: number;
  id: string;
}

export class AdvisorySavedFilter {
  name: string;
  _id: string;
  filter: any;
}
