<div class="row" *ngIf="cases && loaded">
  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 pb-2" *ngFor="let c of cases">

    <a [routerLink]="['/support/view/' + c.id]" routerLinkActive="activeLink" style="text-decoration: none;">

      <div class="card highlight boxshadow">
        <div class="card-header" [ngClass]="'P'+c.priority | lowercase | removeWhiteSpace">
          <div class="mb-1">{{c.caseNumber}}</div>
          <div class="header-subject">{{c.subject | uppercase}}</div>
        </div>
        <div class="card-body">
          <h3 class="card-title justify-content-center">
            {{c.type | uppercase}}
          </h3>
          <h3 class="card-title">
            <div style="float:left; width:70%">{{c.status | uppercase}}</div>
            <span class="justify-content-end" style="display:flex;">
              <span class="priority tag p-2 w-100" [ngClass]="'P' + c.priority | lowercase | removeWhiteSpace">
                {{c.priority | uppercase}}
              </span>  
            </span>
            
          </h3>
          <br/>
          <p class="card-text text-start">{{c.description | slice:0:200}}</p>
          <br/>
          <p class="card-text text-start">{{c.contact?.fullName}}</p>
        </div>

        <div class="card-footer">
          <div style="float:left; width:100%">
            <small>
              {{ 'pages.shared.created' | translate }}<i>{{c.createdDate | date: 'dd/MM/yyyy HH:mm'}}</i>
            </small>
          </div>
          <span class="justify-content-end" style="display:flex;">
            <div class="spinner-grow spinner-grow-sm text-primary" style="margin-left: -100px" *ngIf="flaggingRef && flaggingRef === c.caseNumber">
              <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
            </div>
            <fa-icon [icon]="['far', 'flag']" class="click" (click)="flagCase($event, c.caseNumber)" *ngIf="(!flagRefs.includes(c.caseNumber)) && (!flaggingRef || (flaggingRef && flaggingRef !== c.caseNumber))"></fa-icon>
            <fa-icon [icon]="['fas', 'flag']" class="click" (click)="flagCase($event, c.caseNumber)" *ngIf="(flagRefs.includes(c.caseNumber)) && (!flaggingRef || (flaggingRef && flaggingRef !== c.caseNumber))"></fa-icon>
          </span>
        </div>
      </div>

    </a>

  </div>
</div>

<div class="row justify-content-center p-5" *ngIf="!loaded || scrollLoading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div class="row p-3 justify-content-center redborder-sides" *ngIf="loaded && cases && cases.length === 0">
  <div class="col-md-12 table-border justify-content-center">
    {{ 'pages.support.dashboard.noResults' | translate }}
  </div>
</div>
