<footer class="footer bg-black">
  <div class="container-xxl footer-terms d-flex">
    <div class="d-grid" style="width: 80%;">
      <ul class="navbar-nav mb-3 ps-0" >
        <li><a class="nav-link" routerLink="/termsofservice">{{ 'footer.termsOfService' | translate }}</a></li>
        <li><a class="nav-link" routerLink="/privacypolicy">{{ 'footer.privacyPolicy' | translate }}</a></li>
        <li><a class="nav-link" routerLink="/cookiepolicy">{{ 'footer.cookiePolicy' | translate }}</a></li>
      </ul>
      <div class="mb-0 pb-1 d-inline">
        {{ 'footer.bug' | translate }}<a class="ps-1" href="mailto:{{mailToPortalSupport}}">{{mailToPortalSupport}}</a>
      </div>
      <div class="mb-0 pt-2 pl-3 d-inline">
        {{ 'footer.vuln' | translate }}<a class="ps-1" href="mailto:{{mailToInfoSec}}">{{mailToInfoSec}}</a>
      </div>
    </div>
    <div class="footer-social mt-2" style="width: 20%;">
      <h3 class="footer-heading me-md-3">{{ 'footer.follow' | translate }} </h3>
      <ul class="navbar-nav gap-2 flex-row align-self-start ">
        <li><a href={{twitterUrl}} class="btn btn-icon btn-social btn-twitter btn-inverse" [ngClass]="{'btn-inverse': contextService.userSelectedTheme === 'dark'}"><span class="visually-hidden">{{ 'footer.followUsOnX' | translate }}</span></a></li>
        <li><a href={{linkedinUrl}} class="btn btn-icon btn-social btn-linkedin btn-inverse" [ngClass]="{'btn-inverse': contextService.userSelectedTheme === 'dark'}"><span class="visually-hidden">{{ 'footer.followUsOnlinkedin' | translate }}</span></a></li>
      </ul>
    </div>
  </div>
</footer>


