import { DatePipe } from '@angular/common';
import { constants } from './constants';

export class Utilities {

  static enLanguage: string = constants.languages.enGB;
  static defaultDateFormat = 'yyyy-MM-dd';

    static getStringXSSEncoded(text: string){
        const lt = /</g; const gt = />/g; const ap = /'/g; const ic = /"/g;
        return text.toString().replace(lt, '&lt;').replace(gt, '&gt;').replace(ap, '&#39;').replace(ic, '&#34;');
    }
    static getCurrentMonthWord(){
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        return datepipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'MMMM yyyy');
    }

    static getFirstOfCurrentMonth() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        return datepipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), this.defaultDateFormat);
    }

    static getLastOfCurrentMonth() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        return datepipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), this.defaultDateFormat);
    }

    static getFirstOfLastMonth() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        return datepipe.transform(new Date(date.getFullYear(), date.getMonth()-1, 1), this.defaultDateFormat);
    }

    static getLastOfLastMonth() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        return datepipe.transform(new Date(date.getFullYear(), date.getMonth(), 0), this.defaultDateFormat);
    }

    static getZeroDate() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date(0);
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static get12MonthsAgo() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        date.setMonth(date.getMonth() - 12);
        return datepipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), this.defaultDateFormat);
    }

    static get6MonthsAgo() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        date.setMonth(date.getMonth() - 6);
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static get3MonthsAgo() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        date.setMonth(date.getMonth() - 3);
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static get1MonthAgo() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static getTwoWeeksAgo() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        date.setDate(date.getDate() - 14);
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static getOneWeekAgo() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        date.setDate(date.getDate() - 7);
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static getToday() {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static getTodayMinusDays(days: number){
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        date.setDate(date.getDate() - days);
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static getTodayPlusDays(days: number){
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        const date = new Date();
        date.setDate(date.getDate() + days);
        return datepipe.transform(date, this.defaultDateFormat);
    }
    static getPreviousMonthStart(){
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        let date = new Date();
        date = new Date(date.getFullYear(), date.getMonth()-2, 1);

        return datepipe.transform(date, this.defaultDateFormat);
    }

    static getPreviousMonthLast(){
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        let date = new Date();
        date = new Date(date.getFullYear(), date.getMonth()-2, new Date(date.getFullYear(), date.getMonth() -1, 0).getDate());
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static getDateAsString(date: Date) {
        const datepipe: DatePipe = new DatePipe(this.enLanguage);
        return datepipe.transform(date, this.defaultDateFormat);
    }

    static getNextRoundHour() {
        let date = new Date();
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() +1);
        return date.getTime() / 1000;
    }

    static checkTruePositive(caseNumber: string, status: string, closureCode: string){
        return (
            (!caseNumber.startsWith('OCD') && constants.incidentStatus.truepositive.includes(status))
            || constants.incidentStatus.truepositive.includes(closureCode)
        );
    }

    static checkFalsePositive(status: string, closureCode: string){
        return (constants.incidentStatus.falsepositive.includes(status) || constants.incidentStatus.falsepositive.includes(closureCode));
    }

    static enumToStringArray(enumerator): string[] {
        const enumValues = Object.values(enumerator);
        return enumValues.slice(0, enumValues.length/2) as string[];
    }

    /** get a random number between start (included) and end (excluded) */
    static getRandomNumber(start?: number, end?: number) {
        if(!start) {
            start = 1;
        }
        if(!end) {
            end = 10000;
        }
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        const MAX_UINT32 = Math.pow( 2, 32 ) - 1;
        const value = array[0];
        return Math.floor((value/MAX_UINT32)*(end-start)) + start;
    }

    static checkDateRange(smallestDate: string | Date, biggestDate: string | Date): boolean {
        // check date format
        let smallestDateParsed: number;
        let biggestDateParsed: number;
        try {
            smallestDateParsed = (typeof smallestDate === 'string') ? Date.parse(smallestDate) : smallestDate.getTime();
            biggestDateParsed = (typeof biggestDate === 'string') ? Date.parse(biggestDate) : biggestDate.getTime();
        } catch (e) {
            return false;
        }
        // check if smallestDate is really the smallest
        return smallestDate <= biggestDate;
    }

    static capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /** Allows the type checker to see the intended variable type, while making it undefined */
    static defaultNull<Type>(): (undefined | Type) {
        return undefined;
    }
}

/*
    Javascript can export individual functions, no need to wrap in a class
    For the sake of legacy code, the class is kept in place.
*/



