export class Case {
    id: string;
    caseNumber: string;
    attachments: any;
    accountId: string;
    account: Account;
    actor?: string;
    action?: string;
    subAction?: string;
    killchain?: string;
    attributes?: string;
    assetId: any; // UI uses a multiselect, resulting in an array of strings (that sometimes needs to be set programmatically as a string[])
    entitlementId: string;
    asset: Asset;
    contactId: string;
    contact: Contact;
    secondContactId: string;
    secondContact: Contact;
    subject: string;
    customerReference: string;
    description: string;
    type: string;
    priority: string;
    lastModifiedDate: string;
    createdDate: string;
    closedDate: string;
    status: string;
    category: string;
    comments: Comment[];
    reqType: string;
    changeType: string;
    vendor: string;
    serviceType: string;
    serviceName: string;
    escalation: string;
    closureCode: string;
    childTasks: Case[];
    parentId: string;
    serviceContract: string;
    dateTime: string;
    openedBy: string;
    updatedBy: string;
    worldWatchReference: string;
    startDate: string;
    UTCStartDate?: string;
    endDate: string;
    whyFalsePositive?: string;
    whatFalsePositive?: string;
    whoFalsePositive?: string;
    parentCaseNumber: string;
    u_asset?: string;
    truePositive?: string;
    serviceCenter: string;
    contractId: string;
    justification: string;
    implementationPlan: string;
    testPlan: string;
    backoutPlan: string;
    approval: string;
    approvalPermission: boolean;
}

export enum CaseType {
    changeRequest = 'Change Request',
    incident = 'Incident',
    serviceRequest = 'Service Request',
    normal = 'Normal'
}

export class Account {
    id: string;
    accountNumber: string;
    name: string;
    industry: string;
    address: string;
}

export class Contact {
    id: string;
    accountId: string;
    // account: Account;
    fullName: string;
    email?: string;
    phone?: string;
    lastName?: string;
    companyId?: string;
}

export class Comment {
    id: string;
    creatorName: string;
    commentBody: string;
    isPublished: string;
    createdDate: string;
    isEngineer?: boolean;
    isInternal?: boolean;
}

export class Asset {
    source: string; // non persisted value  eg) itsm, scanning
    id: string;
    name: string;
    accountId: string;
    // account: Account;
    status: string;
    active: string;
    model: string;
    fullName: string;
    vendor: string;
    serialNumber: string;
    installDate: string;
    purchaseDate: string;
    product2Id: string;
    product: Product;
    entitlements: Entitlement[];
    cases: Case[];
    friendlyName: string;
    hostName: string;
    assetVendor = '';
    osVersion: string;
}

export class Entitlement {
    id: string;
    accountId: string;
    // account: Account;
    assetId: string;
    // asset: Asset;
    name: string;
    startDate: string;
    endDate: string;
    lastModifiedDate: string;
}

export class Product {
    id: string;
    name: string;
    description: string;
    stockKeepingUnit: string;
}

export class Milestone {
    id: string;
    name: string;
    description: string;
}
