import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { constants } from "src/app/shared/constants";
import { Asset, CaseType } from "src/app/shared/model/itsm";
import { PriorityOneErrorContacts } from "src/app/shared/model/support";

export class LogCaseForm {

    public logForm: FormGroup = new FormGroup({});

    // GLOBAL FORM GETTERS

    get type() {
        return this.logForm.get('type');
    }

    get subject() {
        return this.logForm.get('subject');
    }

    get description() {
        return this.logForm.get('description');
    }

    get selectedService() {
        return this.logForm.get('selectedService');
    }

    get selectedAsset() {
        return this.logForm.get('selectedAsset');
    }

    get worldWatchReference() {
        return this.logForm.get('worldWatchReference')
    }

    get contactId() {
        return this.logForm.get('contactId')
    }

    // CHANGE REQUEST FORM GETTERS

    get changeRequest() {
        return this.logForm.get('changeRequest');
    }

    get changeType() {
        return this.changeRequest.get('changeType');
    }

    get preferedDate() {
        return this.changeRequest.get('preferedDate');
    }

    get dateTime() {
        return this.changeRequest.get('dateTime');
    }

    get incident() {
        return this.logForm.get('incident');
    }

    // INCIDENT FORM GETTERS

    get priority() {
        return this.incident.get('priority');
    }

    // PARAMETERS

    public caseTypes = [
        {
            value: CaseType.changeRequest,
            label: 'pages.support.dashboard.fieldTitles.changeRequestDesc'
        },
        {
            value: CaseType.incident,
            label: 'pages.support.dashboard.fieldTitles.incidentDesc'
        },
        {
            value: CaseType.serviceRequest,
            label: 'pages.support.dashboard.fieldTitles.serviceRequestDesc'
        }
    ];

    public changeTypes = [
        {
            value: constants.changeTypes.standardChange,
            label: 'pages.support.log.standardChange',
            desc: 'pages.support.log.changeStandDesc'
        },
        {
            value: constants.changeTypes.normalChange,
            label: 'pages.support.log.normalChange',
            desc: 'pages.support.log.normalChange'
        },
        {
            value: constants.changeTypes.emergencyChange,
            label: 'pages.support.log.emergencyChange',
            desc: 'pages.support.log.changeEmergDesc'
        }
    ]

    public priorityOneErrorContacts = [
        PriorityOneErrorContacts.netherlands,
        PriorityOneErrorContacts.belgium,
        PriorityOneErrorContacts.denmark,
        PriorityOneErrorContacts.norway,
        PriorityOneErrorContacts.sweden,
        PriorityOneErrorContacts.unitedKingdom,
        PriorityOneErrorContacts.germany
    ];

    // VALIDATORS

    DateTimeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            // check date validity
            const currTime = new Date();
            const startTime = new Date(value);
            return (Math.abs(startTime.getTime() - currTime.getTime()) / 3600000) >= 24 ? null : { invalidDateTime: true };
        }
    }

    PriorityValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value === '1 - Business Critical' ? { criticalPriority: true } : null;
        }
    }

    ServiceValidator(defaultService: Service): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value === defaultService ? { isDefaultservice: true } : null;
        }
    }

    AssetValidator(assetsList: AssetFilterFunc): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return !control.value && assetsList().length > 0 ? { noAssetSelected: true } : null;
        }
    }

}

export interface AssetFilterFunc{
    (): Asset[]
   }

export class Service {
    id: string;
    serviceName: string;
    serviceCenter: string;

    constructor(id: string, serviceName: string, serviceCenter: string) {
        this.id = id;
        this.serviceName = serviceName;
        this.serviceCenter = serviceCenter;
    }
}
