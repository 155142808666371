import { Injectable } from '@angular/core';
import { CssColor, UserTheme } from '../model/shared-items';
import { ContextService } from './context-service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  // widget color settings (black theme by default)

  private blackColorSettings = {
    fontColor: CssColor.white,
    backgroundColor: CssColor.gray900,
    borderColor: CssColor.gray500
  };

  private whiteColorSettings = {
    fontColor: CssColor.black,
    backgroundColor: CssColor.gray300,
    borderColor: CssColor.gray500
  };

  constructor(
    private contextService: ContextService
  ) { }

  /**
   * update style depending of 'light' or 'dark' theme selected
   * @param chartOptions
   * @param theme
   */
  public updateWidgetStyle(chartOptions, theme) {

    // get theme
    if (!theme) {
      theme = this.contextService.userSelectedTheme;
    }
    const colorSettings = theme === UserTheme.light ? this.whiteColorSettings : this.blackColorSettings;

    // CHART
    chartOptions.chart.backgroundColor = colorSettings.backgroundColor;
    chartOptions.chart.borderRadius = '15px';

    // TITLE
    if(chartOptions.title) {
      chartOptions.title.style = {
        color: colorSettings.fontColor,
        fontWeight: 'bold',
      };
    }

    // SUB TITLE
    if(chartOptions.subtitle) {
      chartOptions.subtitle.style = {
        color: colorSettings.fontColor,
        fontWeight: 'bold',
      };
    }

    // LEGEND
    if(chartOptions.legend) {
      chartOptions.legend.itemStyle = {
        fontWeight: 'bold',
        fontSize: '15px',
        color: colorSettings.fontColor
      };

      chartOptions.legend.itemHoverStyle = {
        fontWeight: 'bold',
        fontSize: '15px',
        color: CssColor.primary
      };
    }

    // AXIS
    const axisStyle = {
      style: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: colorSettings.fontColor
      }
    };

    if (chartOptions.xAxis) {
      chartOptions.xAxis.title = axisStyle;
      chartOptions.xAxis.labels = axisStyle;
    }

    if (chartOptions.yAxis) {
      chartOptions.yAxis.title = axisStyle;
      chartOptions.yAxis.labels = axisStyle;
    }

    // DRILLDOWN
    if(chartOptions.drilldown) {
      chartOptions.drilldown.activeAxisLabelStyle = {
        color : colorSettings.fontColor,
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration:'none'
      };
      chartOptions.drilldown.activeDataLabelStyle = {
        color : colorSettings.fontColor,
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration:'none'
      };
    }

  }
}
