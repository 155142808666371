import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { FileAccessService } from '../../services/fileaccess.service';
import { File, FileStructure, FileStructureArray } from '../../model/files';
import FileSaver from 'file-saver';
import { NotificationService } from '../../services/notification.service';
import { NotificationAudience, NotificationCategory } from '../../model/notification';
import { ACTIONS, PAGES, TYPES } from '../../constants';
import { UserActivityService } from '../../services/user-activity.service';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent implements OnInit, OnChanges {

  @Input()
  filesList: File[];

  @Input()
  viewedFileStructure: FileStructure;

  viewedFileStructureArray: FileStructureArray;
  fileAction = FileAction;
  viewableTypes: string[];

  constructor(
    private fileAccessService: FileAccessService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private userActivity: UserActivityService,
  ) { }

  ngOnInit(): void {
    this.toArray();
    this.viewableTypes = [
      this.fileAccessService.mimeTypeMap.pdf,
      this.fileAccessService.mimeTypeMap.txt
    ];
  }

  ngOnChanges(_: SimpleChanges) {
    this.toArray();
  }

  originalOrder(a: any, b: any){
    return 0;
  }

  /** transform viewedFileStruture Json object into an array */
  toArray() {
    this.viewedFileStructureArray = this.toArrayRec(this.viewedFileStructure, []);
    this.viewedFileStructureArray.sort((a,b)=> {
      if(b.value == null) {
        return -1;
      }
      if(a.value == null) {
        return 1;
      }
      return 0;
    });
  }

  /** recursive method to transform file structure nodes into arrays */
  toArrayRec(viewedFileStructure: FileStructure, folderPath: string[]): FileStructureArray {
    const array = [];
    for(const item in viewedFileStructure) {
      if(JSON.stringify(viewedFileStructure[item]) !== '{}') {
        array.push({
          name: item,
          value : this.toArrayRec(viewedFileStructure[item], folderPath.concat([item]))
        });
      }
      else {
        array.push({
          name: item,
          value : null,
          created : this.getFileCreationDate(this.getFilePath(folderPath, item)),
          folderPath
        });
      }
    }
    return array;
  }

  /** triggers view or download a file from gcp */
  clickFile(currentPath: [], fileName: string, action: FileAction) {
    const ff = this.filesList.filter(f => f.title = fileName)[0];

    if(ff) {
      const newFile = this.getFilePath(currentPath, ff.title);
      this.fileAccessService.downloadFile(newFile)
        .subscribe(val => {

          // correct file extension if needed
          const fileExtension = this.getContentType(fileName);
          const contentType = (fileExtension && ff.contentType !== fileExtension) ? fileExtension : ff.contentType;

          let file: Blob;
          if (action === FileAction.view) {
            file = new Blob([val], {type: contentType});
            this.viewFile(file);
          } else if (action === FileAction.download) {
            file = new Blob([val], {type: contentType});
            this.downloadFile(file, fileName);
          }

          this.notificationService.sendNotification(
            NotificationCategory.reportDownload,
            {title: newFile, content: newFile},
            NotificationAudience.admins
          );
          this.userActivity.logActivity(TYPES.reports, PAGES.reportsAndDocuments, ACTIONS.downloadReport);
        },
        error => this.alertService.handlerError(error)
      );
    } else {
      this.alertService.handlerError('File failed to download, please try again or contact support');
    }
  }

  /** display file in a new tab */
  private viewFile(file: Blob) {
    const url = URL.createObjectURL(file);
    window.open(url, '_blank');
    URL.revokeObjectURL(url);
  }

  /** download a file loaded from gcp */
  private downloadFile(file: Blob, fileName: string): void {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(file);
      } else {
      FileSaver.saveAs(file, fileName.substring(fileName.lastIndexOf('/')+1));
    }
  }

  /** get the original file path in gcp */
  getFilePath(folderPath, filename) {
    if (folderPath.length > 0) {
      return folderPath.join('/') + '/' + filename;
    } else {
      // no leading forwardslash
      return filename;
    }
  }

  /** get the creation date of a file */
  getFileCreationDate(filename: string) {
    const i = this.filesList.findIndex(file => file.title === filename);
    if(i>=0) {return Math.round(this.filesList[i].created / 1000);}
    else {return null;}
  }


  private getContentType(fileName: string): string | undefined {
    const fileExtension = fileName.split('.').pop() || '';
    return this.fileAccessService.getMimeType(fileExtension);
  }
}

export enum FileAction {
  download = 'download', view = 'view'
}
