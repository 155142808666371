import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Signal, MonthlyStat, SignalStat, MonthlyStats } from '../model/signal';
import { SignalLoadListCriteria, SignalSearchCriteria, SignalStatsCriteria } from '../model/searchCriteria';
import { constants } from '../constants';
import { Utilities } from '../utilities';
import { SessionStorageItem } from '../model/shared-items';
import { AdvisoryFilter, AdvisoryListResponse } from 'src/app/updates/worldwatch/dashboard/worldwatch.component';
import { Observable } from 'rxjs';
import { Advisory } from 'src/app/updates/worldwatch/viewsignal/viewsignal.component';

@Injectable()
export class WorldWatchService {

    private signalURL = environment.endpoints.worldwatch;
    private signalLikeURL = environment.endpoints.worldwatch + 'like/';
    private signalPdfURL = environment.endpoints.worldwatch + 'pdf/';
    private signalSearchURL = environment.endpoints.worldwatch + 'search';
    private signalLoadListURL = environment.endpoints.worldwatch + 'loadList';
    private signalAttributeStatsURL = environment.endpoints.worldwatch + 'attributeStats';
    private signalAttributeMonthlyStatsURL = environment.endpoints.worldwatch + 'attributeMonthlyStats';

    constructor(private http: HttpClient) { }

    signal(jiraRef: string): Promise<Signal> {
      return this.http
        .get(this.signalURL + jiraRef)
        .toPromise()
        .then(response => response as Signal)
        .catch(this.handleError);
    }

    signalLike(jiraRef: string): Promise<any> {
        return this.http
            .get(this.signalLikeURL + jiraRef)
            .toPromise()
            .then(response => response as any)
            .catch(this.handleError);
    }

    signalSearch(searchQuery: SignalSearchCriteria): Promise<any[]> {
        return this.http
            .post(this.signalSearchURL, JSON.stringify(searchQuery))
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }

    signalLoadList(searchQuery: SignalLoadListCriteria): Promise<Signal[] | any[]> {
        return this.http
            .post(this.signalLoadListURL, JSON.stringify(searchQuery))
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }

    getAdvisories(filter: AdvisoryFilter): Observable<AdvisoryListResponse> {

        const params = new URLSearchParams();
        Object.keys(filter).forEach((key) => {
            if(filter[key] || filter[key] === 0) {
                params.append(key, filter[key]);
            }
        })
        const url = new URL(this.signalURL + "api/advisories")
        url.search = params.toString();

        return this.http.get<AdvisoryListResponse>(url.toString())
    }

    getFlaggedAdvisories(filter: AdvisoryFilter): Observable<AdvisoryListResponse> {

        const params = new URLSearchParams();
        Object.keys(filter).forEach((key) => {
            if(filter[key] || filter[key] === 0) {
                params.append(key, filter[key]);
            }
        })
        const url = new URL(this.signalURL + "api/flaggedAdvisories")
        url.search = params.toString();

        return this.http.get<AdvisoryListResponse>(url.toString())
    }

    getAdvisoryById(id: string): Observable<Advisory> {
        const params = new URLSearchParams();
        params.append("id", id);

        const url = new URL(this.signalURL + "api/advisory")
        url.search = params.toString();

        return this.http.get<Advisory>(url.toString())
    }

    getPDFAdvisory(id: string): Observable<any> {
        const url = new URL(this.signalURL + "api/pdf/" + id)
        return this.http.get<any>(url.toString())
    }

    signalUrgencyStats(pubDateFrom: string, pubDateTo: string): Promise<SignalStat[]> {
        return this.http
            .post(this.signalAttributeStatsURL, JSON.stringify({publishDateFrom: pubDateFrom, publishDateTo: pubDateTo, attributeName: constants.fields.signals.urgency}))
            .toPromise()
            .then(response => response as SignalStat[])
            .catch(this.handleError);
    }

    signalCategoryStats(pubDateFrom: string, pubDateTo: string): Promise<SignalStat[]> {
        return this.http
            .post(this.signalAttributeStatsURL, JSON.stringify({publishDateFrom: pubDateFrom, publishDateTo: pubDateTo, attributeName: constants.fields.signals.category}))
            .toPromise()
            .then(response => response as SignalStat[])
            .catch(this.handleError);
    }

    signalMonthlyStats(searchCritera: SignalStatsCriteria): Promise<MonthlyStats[]> {
        return this.http
            .post(this.signalAttributeMonthlyStatsURL, JSON.stringify(searchCritera))
            .toPromise()
            .then(response => response as MonthlyStat[])
            .catch(this.handleError);
    }

    signalPDF(jiraRef: string): any {
        return this.http.get(this.signalPdfURL + jiraRef, {responseType: 'blob'});
    }

    signalCountSinceLastLogin(date: Date): Promise<number> {
        return new Promise((resolve,reject) => {
            const filter = new AdvisoryFilter();
            filter.updatedAfter = date.toISOString();
            this.getAdvisories(filter).subscribe({
                next:(response) => {
                    resolve(response.count)
                },
                error:(err) => {
                    reject(err);
                }
            });
        });
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

}
