<div class="section" *ngIf="loadedSignals">
  <div class="header">
    <h3 class="title">{{ 'pages.dashboard.worldwatch' | translate }}</h3>
  </div>
  <ngb-carousel #carousel [interval]="5000" >
    <ng-template ngbSlide *ngFor="let sig of latestSignals; index as i" >
        <div class="carousel" [routerLink]="['/updates/worldwatch/viewSignal/' + sig.id]" routerLinkActive="router-link-active">
              <div class="logo" placement="top" >
              </div>
              <div class="sig-title">
                <span>{{sig.title}}</span>
              </div>
              <div class="published">
                <fa-icon class="me-2" icon="calendar"></fa-icon>{{sig.timestampCreated | offsetDatetimePipe}}
              </div>
              <div class="tags">
                <ul class="list-unstyled m-0 d-flex gap-4 flex-wrap m-0">
                  <li><span [ngClass]="sig.severityStyle" class="tag">{{sig.severityLabel | translate}}</span></li>
                  <li><span class="tag">{{sig.threatCategory}}</span></li>
                  <li><span class="tag">{{sig.id}}</span></li>
                  @for(tag of sig.tags; track tag) {
                  <li><span class="tag">{{tag}}</span></li>
                  }
                </ul>
              </div>
        </div>
    </ng-template>
  </ngb-carousel>

  <div class="row justify-content-center orangeText" *ngIf="loadedSignals && !latestSignals">
    <fa-icon [icon]="['fas', 'exclamation-circle']" size="2x"></fa-icon>
  </div>
</div>

<div class="row justify-content-center p-5" *ngIf="!loadedSignals">
  <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>
