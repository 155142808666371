export enum ClosureVerdict {
  UNCATEGORIZED = 'uncategorized',
  DUPLICATE = 'duplicate',
  TRUE_POSITIVE = 'true positive',
  FALSE_POSITIVE = 'false positive',
  LINKED_INCIDENT = 'linked incident',
}

export enum Severity {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  INFORMATIONAL = 'informational',
}

export enum DynamicDate {
  TODAY = 'today',
  LAST_24H = 'last24h',
  THIS_WEEK = 'thisWeek',
  LAST_7D = 'last7d',
  THIS_MONTH = 'thisMonth',
  LAST_30D = 'last30d',
  LAST_MONTH = 'lastMonth',
  LAST_90D = 'last90d',
  LAST_180D = 'last180d',
  THIS_YEAR = 'thisYear',
}

export enum SLANotification {
  BREAK = 'break',
  AT_RISK = 'at_risk',
  IN = 'in',
}
