import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import FileSaver from 'file-saver';
import { OktaAuthService } from 'src/app/app.service';
import { File } from 'src/app/shared/model/files';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileAccessService } from 'src/app/shared/services/fileaccess.service';
import trainingConstants from 'src/assets/training-list.json';

@Component({
  selector: 'app-trainingdashboard',
  templateUrl: './trainingdashboard.component.html',
  styleUrls: ['./trainingdashboard.component.scss']
})
export class TrainingDashboardComponent implements OnInit {
  public userGuideDetails: DownloadableFile = trainingConstants.fileDetails.userGuide;
  public adminGuideDetails: DownloadableFile = trainingConstants.fileDetails.adminGuide;
  public releaseNotesDetails: DownloadableFile = trainingConstants.fileDetails.releaseNotes;
  public releaseNotes: File[];
  public releaseNotesLoaded: boolean;
  public trainings: TrainingItem[];
  public trainingsLoaded = false;
  public downloadInProgress = false;
  public isAdmin = false;

  constructor(
    private fileAccessService: FileAccessService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private oktaAuthService: OktaAuthService,
    ) {}

  ngOnInit(): void {
    this.trainings = trainingConstants.trainingList;
    this.filterChildrenTrainings();
    this.releaseNotesLoaded = false;
    this.trainingsLoaded = true;
    this.getReleaseNotes();
    this.oktaAuthService.isUserAdminOnCurrentTenant().then( res => {
      this.isAdmin = res;
    });
  }

  private getReleaseNotes() {
    this.fileAccessService.getFilesByFolder(this.releaseNotesDetails.path).then( (res: File[]) => {
      this.releaseNotes = res;
      // remove directory string from release notes titles
      this.releaseNotes.forEach( note => note.title = note.title
          .replace(`${this.releaseNotesDetails.path}/`, '')
      );
      this.releaseNotesLoaded = true;
    });
  }

  public downloadDoc(targetName: string) {
    this.downloadInProgress = true;
    let downloadDetails: DownloadableFile;
    switch (targetName) {
      case this.userGuideDetails.fileName:
        downloadDetails = this.userGuideDetails;
        break;
        case this.adminGuideDetails.fileName:
          downloadDetails = this.adminGuideDetails;
          break;
      default:
        downloadDetails = this.releaseNotesDetails;
    }
    const newFile = `${downloadDetails.path}/${targetName}`;
      this.fileAccessService.downloadFile(newFile)
        .subscribe(val => {
          const file = new Blob([val], {type: downloadDetails.contentType});
          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(file);
          } else {
            FileSaver.saveAs(file, targetName);
          }
          this.downloadInProgress = false;
        },
        () => {
          this.alertService.handlerError(this.translateService.instant('pages.generic.downloadError'));
          this.downloadInProgress = false;
        }
      );
  }

  filterChildrenTrainings() {
    const childrenIds: any[] = [];
    this.trainings.forEach( training => {
      if (training.children) {
        training.children.forEach( childId => childrenIds.push(childId));
      }
    });
    this.trainings = this.trainings.filter( training => !childrenIds.includes(training.id));
  }
}

export class TrainingItem {
  id: string;
  title: string;
  shortDescription: string;
  description: string;
  link: string;
  imagePath?: string;
  pubDate?: Date;
  children?: string[];
}

export type DownloadableFile = {
  fileName?: string; path: string; contentType: string;
};
