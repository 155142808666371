export enum XtendedFacet {
  ID = 'id',
  ACTION = 'action',
  ACTOR = 'actor',
  ASSET = 'asset',
  ATTRIBUTE = 'attribute',
  WHAT = 'what',
  WHO = 'who',
  WHY = 'why',
  SEVERITY = 'severities',
  OWNER = 'owner',
  INCIDENT_DETAILED_STATUS = 'incident_detailed_status',
  XDR_STATUS = 'xdr_status',
  CATEGORIES = 'categories',
  HOSTNAMES = 'hostnames',
  WAITING_FOR = 'waiting_for',
  CLOSURE_VERDICT = 'closure_verdict',
  ACTION_LEVEL1 = 'actionLevel1',
  GROUPLEVEL1 = 'groupLevel1',
  GROUPLEVEL2 = 'groupLevel2',
  SLA_NOTIFICATION = 'sla_notification',
}

export type Xtended4A3WFacet =
  | XtendedFacet.ACTION
  | XtendedFacet.ACTOR
  | XtendedFacet.ASSET
  | XtendedFacet.ATTRIBUTE
  | XtendedFacet.WHAT
  | XtendedFacet.WHO
  | XtendedFacet.WHY;

export type XtendedDashboardFacet = XtendedFacet.ACTION;

export type XtendedGroupbyFacet =
  | Xtended4A3WFacet
  | XtendedFacet.SEVERITY
  | XtendedFacet.OWNER
  | XtendedFacet.INCIDENT_DETAILED_STATUS
  | XtendedFacet.XDR_STATUS
  | XtendedFacet.CATEGORIES
  | XtendedFacet.HOSTNAMES
  | XtendedFacet.WAITING_FOR
  | XtendedFacet.CLOSURE_VERDICT
  | XtendedFacet.ACTION_LEVEL1
  | XtendedFacet.GROUPLEVEL1
  | XtendedFacet.GROUPLEVEL2
  | XtendedFacet.SLA_NOTIFICATION;
