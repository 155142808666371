import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Asset } from 'src/app/shared/model/itsm';
import { FilterPipe } from 'src/app/shared/pipes/filter-pipe.pipe';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ReplaySubject, debounceTime, forkJoin, takeUntil } from 'rxjs';

@Component({
  selector: 'app-asset-and-service',
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule, ReactiveFormsModule, FilterPipe, TranslateModule, InfiniteScrollModule],
  templateUrl: './asset-and-service.component.html',
  styleUrl: './asset-and-service.component.scss'
})
export class AssetAndServiceComponent implements OnInit {

  private stopAssetLoading$: ReplaySubject<boolean> = new ReplaySubject(1);
  private stopServiceLoading$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() form: FormGroup
  @Input() assetRef;
  @Input() submitted;

  public loaded = false;
  public limit = 40;

  // service values
  public entitlements = [];
  public services = [];
  public serviceFilter = new QueryStatus(this.limit);

  // asset values
  public assets: Asset[] = [];
  public assetFilter = new QueryStatus(this.limit);

  get selectedService() {
    return this.form.get('selectedService');
  }

  get serviceSearch() {
    return this.form.get('serviceSearch');
  }

  get selectedAsset() {
    return this.form.get('selectedAsset');
  }

  get assetSearch() {
    return this.form.get('assetSearch');
  }

  constructor(
    private itsmService: ItsmService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {

    this.initForm();
    this.loadServices();
    this.loadAssets();

    // init all values from the route params
    this.route.queryParams.subscribe(params => {
      this.assetRef = params.asset;

      if (this.assetRef) {
        this.itsmService.getAssetsByAssetId(this.assetRef)
        .subscribe({
          next: (response) => {
            if(response.length === 1) {
              this.selectedAsset.patchValue(response[0]);
              // update service with asset id
              this.onSelectAsset(response[0]);
            }
          }, 
          error: () => {
            this.alertService.addError("Error : multiple assets found for the selected asset reference");
          }
        })
      }
    });

    this.loaded = true;

  }

  //###################################################################################################################################################
  //################################################################### LOADING FUNCTIONS #############################################################
  //###################################################################################################################################################

  private initForm() {

    // service search 
    this.form.addControl('serviceSearch', new FormControl(''));
    this.serviceSearch.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(value => {
      if (this.selectedAsset.value || value === this.serviceFilter.search) {
        return;
      }
      this.serviceFilter = new QueryStatus(this.limit);
      this.serviceFilter.search = value;
      this.services = [];
      this.entitlements = [];
      this.loadServices();
    })

    // asset search
    this.form.addControl('assetSearch', new FormControl(''));
    this.assetSearch.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(value => {
      if (this.selectedService.value || value === this.assetFilter.search) {
        return;
      }
      this.assetFilter = new QueryStatus(this.limit);
      this.assetFilter.search = value;
      this.assets = [];
      this.loadAssets();
    })

    this.selectedService.valueChanges.subscribe((value) => {
      value ? this.assetSearch.disable() : this.assetSearch.enable();
    })

    this.selectedAsset.valueChanges.subscribe((value) => {
      value ? this.serviceSearch.disable() : this.serviceSearch.enable();
    })
  }

  private resetAllLists() {

    this.services = [];
    this.assets = [];
    this.selectedService.patchValue(undefined);
    this.selectedAsset.patchValue(undefined);
    this.serviceFilter = new QueryStatus(this.limit, this.serviceFilter.search);
    this.assetFilter = new QueryStatus(this.limit, this.assetFilter.search);
    this.loadAssets();
    this.loadServices();
  }

  //###################################################################################################################################################
  //################################################################### SERVICE FUNCTIONS #############################################################
  //###################################################################################################################################################

  /**
   * initialize services from 
   */
  private getTicketableServices(entitlements, keepExistingList: boolean) {

    //filter entilements and return only entitlement ticketable & active
    const filteredEntitlements = entitlements.filter(e => {
      // some UK contracts can't have tickets logged against them, and are flagged with "nonTicketable"
      const ticketable = (e.nonTicketable && e.nonTicketable === 'true') ? false : true;
      const active = (e.active === 'true');
      return active && ticketable;
    });

    //extract services names from entitlements
    let services = [];
    filteredEntitlements.forEach(ent =>
      services.push({ id: ent.id, serviceName: ent.serviceName || ent.name || ent.shortDescription, serviceCenter: ent.serviceCenter })
    );

    if (keepExistingList) {
      services = services.concat(this.services);
    }

    services = services.filter(sn => sn.serviceName !== '');
    services = [...new Map(services.map(s => [s.serviceName, s])).values()]; //remove duplicated service name
    this.services = services;
  }

  public loadServices() {
    if (this.serviceFilter.loading || this.serviceFilter.offset >= this.serviceFilter.totalCount) {
      return;
    }

    this.serviceFilter.loading = true;
    this.stopServiceLoading$ = new ReplaySubject(1);

    this.itsmService.getLazyloadedServices(this.serviceFilter)
      .pipe(takeUntil(this.stopServiceLoading$))
      .subscribe({
        next: (loadedEntitlements) => {
          this.serviceFilter.currentCount += loadedEntitlements.length;
          this.entitlements = this.entitlements.concat(loadedEntitlements);
          this.getTicketableServices(this.entitlements, true);
          // update offset for next call
          this.serviceFilter.offset += this.serviceFilter.limit;
          this.serviceFilter.totalCount = loadedEntitlements[0]?.totalCount;
        },
        error: (error) => {
          this.alertService.handlerError(error);
        },
        complete: () => {
          this.serviceFilter.loading = false;
        }
      });
  }

  async onSelectService(service) {
    this.selectedService.patchValue(service);
    if (!this.selectedAsset.value) {
      this.stopAssetLoading$.next(true);
      this.itsmService.getAssetsByServiceId(service.id)
        .subscribe({
          next: (assets) => {
            this.assets = assets;
            this.selectedAsset.patchValue(this.assets.length === 1 ? this.assets[0] : undefined);
          },
          error: (error) => this.alertService.handlerError(error)
        })
    }
  }

  public removeSelectedService() {
    this.resetAllLists();
  }

  public removeServiceSearch() {
    this.serviceSearch.patchValue("");
  }

  //###################################################################################################################################################
  //################################################################### ASSET FUNCTIONS ###############################################################
  //###################################################################################################################################################

  public onSelectAsset(item: Asset) {
    this.selectedAsset.patchValue(item);
    this.stopServiceLoading$.next(true);
    // filter all service that contains asset
    if (!this.selectedService.value) {

      // get id of all entitlements
      const ids = item.entitlements.map(entitlement => entitlement.id);      

      // get all services
      forkJoin(
        ids.map(id => this.itsmService.getServiceByServiceId(id))
      ).subscribe(values => {
        let entitlements = [];
        values.forEach(res => {
          entitlements = entitlements.concat(res);
        })

        this.getTicketableServices(entitlements, false)

        if (this.services.length === 1) {
          // if only one service available, select it
          this.selectedService.patchValue(this.services[0]);
        }
      })
    }
  }

  public loadAssets() {
    if (this.assetFilter.loading || this.assetFilter.offset >= this.assetFilter.totalCount) {
      return;
    }

    this.assetFilter.loading = true;
    this.stopAssetLoading$ = new ReplaySubject(1);

    this.itsmService.getLazyloadedAssets(this.assetFilter)
      .pipe(takeUntil(this.stopAssetLoading$))
      .subscribe({
        next: (loadedAssets) => {
          this.assetFilter.currentCount += loadedAssets.length;
          this.assets = this.assets.concat(loadedAssets);

          // update offset for next call
          this.assetFilter.offset += this.assetFilter.limit;

          // get the total count
          for (const asset of loadedAssets) {
            if (asset.totalCount) {
              this.assetFilter.totalCount = asset.totalCount;
              break
            }
          }
        },
        error: (error) => {
          this.alertService.handlerError(error);
        },
        complete: () => {
          this.assetFilter.loading = false;
        }
      });
  }

  public removeSelectedAsset() {
    this.selectedAsset.patchValue(undefined);
    if (this.selectedService.value) {
      this.resetAllLists();
    }
  }

  public removeAssetSearch() {
    this.assetSearch.patchValue("");
  }

}



export class QueryStatus {
  offset: number;
  limit: number;
  currentCount: number
  totalCount: number;
  search: string;
  loading: boolean;

  constructor(limit: number, search?: string) {
    this.offset = 0;
    this.limit = limit;
    this.currentCount = 0;
    this.loading = false;
    this.search = search;

    
  }
}

