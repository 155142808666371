<div class="analytics">
  <!-- Filters -->
  <app-analytics-filter class="m-5" [defaultParameters]="filterParameters" (filter)="doFilter($event)">
  </app-analytics-filter>

  <!-- Tabs selection -->
  <ul class="nav nav-tabs">
    <li class="nav-item" data-toggle="tooltip" data-placement="top"
      title="{{ 'pages.dashboard.analytics.activity' | translate }}">
      <a class="nav-link" [ngClass]="{ 'active' : activeTab === tabs.activityAnalytics }"
        (click)="switchTab(tabs.activityAnalytics)">
        {{ 'pages.dashboard.analytics.activity' | translate | titlecase }}
      </a>
    </li>
    <li class="nav-item" data-toggle="tooltip" data-placement="top"
      title="{{ 'pages.dashboard.analytics.actions' | translate }}">
      <a class="nav-link" [ngClass]="{ 'active' : activeTab === tabs.actionsAnalytics }"
        (click)="switchTab(tabs.actionsAnalytics)">
        {{ 'pages.dashboard.analytics.actions' | translate | titlecase }}
      </a>
    </li>
  </ul>

  <!-- Tabs -->
  <app-activity-analytics *ngIf="activeTab === tabs.activityAnalytics" [currentTab]="activeTab" [filterParameters]="filterParameters">
  </app-activity-analytics>
  <app-actions-analytics *ngIf="activeTab === tabs.actionsAnalytics" [currentTab]="activeTab" [filterParameters]="filterParameters">
  </app-actions-analytics>
</div>