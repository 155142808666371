<div>
    <h4>{{ 'pages.dashboard.user.serviceSettings' | translate }}</h4>
    <div class="preferences">

        <!-- notification form -->
        <form *ngIf="oktaAuthService.userAdmin && notificationPreferences" [formGroup]="preferencesForm">
            <h5>
                {{ 'pages.dashboard.user.notificationPreferences' | translate }}
            </h5>
            <div class="notifications">
                <div *ngFor="let cat of notificationCategories" class="custom-control custom-switch right me-3"
                    style="float: left" data-toggle="tooltip" data-placement="top"
                    title="Enable {{ 'pages.dashboard.user.' + cat + 'Notifications' | translate}}">
                    <input class="form-check-input" formControlName="{{cat}}" name="{{cat}}" type="checkbox"
                        id="{{cat}}" />
                    <label class="custom-control-label" for="{{cat}}">{{ 'pages.dashboard.user.' + cat + 'Notifications'
                        |
                        translate}}</label>

                </div>
            </div>
        </form>

        <!-- service settings form -->
        <form [formGroup]="serviceSettingsForm">
            <h5>
                {{ 'pages.dashboard.user.worldwatchSettings' | translate }}
            </h5>
            <div class="d-grid">
                <div class="custom-control custom-switch me-3 d-flex align-items-center" style="float: left"
                    data-toggle="tooltip" data-placement="top" title="email notification">
                    <span class="custom-control-label me-3">
                        {{ 'pages.dashboard.user.worldwatchSubscription' | translate }}
                    </span>
                    <div>
                        <label for="worldwatchEmailSubscription">{{'pages.generic.email' | translate}}</label>
                        <input class="form-check-input ms-2" formControlName="worldwatchEmailSubscription"
                            name="worldwatchEmailSubscription" type="checkbox" id="worldwatchEmailSubscription" />
                    </div>
                </div>
                <div class="advisories">
                    <label for="advisoryLevelSelect">{{ 'pages.dashboard.user.advisoriesLabel' | translate }}</label>
                    <select class="form-select" formControlName="advisoryLevel" id="advisoryLevelSelect">
                        @for(value of advisoriesValues; track value) {
                            <option [ngValue]=value>{{value}}</option>
                        }
                    </select>
                </div>
            </div>
        </form>

    </div>
</div>