<div class="container bg-body-tertiary p-3" style="max-width: 1800px">
  <div class="row justify-content-between">
    <div class="col-12 col-lg-8">
      @if(!hasAnsweredSurvey) {
      <app-feedback-reminder />
      }
      <app-daily-info [last24HData]="last24HData" />
      <app-insight-carrousel
        [insights]="insights"
        [period]="insightPeriod"
        (onPeriodChange)="onInsightPeriodChange($event)"
      />
      <app-card
        title="pages.cybersocxdr.home.incidentsAndAlertsPyramid"
        actionTitle="pages.cybersocxdr.home.viewAllIncidents"
        actionRoute="/xtended-soc/incidents"
      >
        <app-scatterpyramid [data]="pyramidIncidentData" />
      </app-card>
    </div>
    <div class="col-12 col-lg-4 mt-3 m-lg-0">
      <app-backlog-sanity-score [backlogStatus]="backlogStatus" />
      <app-4A3W-preview [actions]="actions" />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-incident-needing-action-preview
        [incidents]="lastIncidentsNeedingActions"
      />
    </div>
    <div class="col-12" *ngIf="incidentsRepartitionData">
      <app-card
        title="pages.cybersocxdr.home.handledIncidentsByCountry"
        actionTitle="pages.cybersocxdr.home.accesToSplitView"
        actionRoute="/xtended-soc/dashboard/splitView"
      >
        <div class="row">
          <app-worldmap
            titleY="Handled Incidents"
            suffix="incidents"
            [data]="incidentsRepartitionData"
          />
        </div>
      </app-card>
    </div>
  </div>
</div>
