<div id="#list-incidents" class="row">
  <div class="w-100">
    <div class="row px-2 tab-content border-0">
      @if(title || exportable || selectableColumns){
      <div
        class="d-flex gap-2 flex-wrap justify-content-between align-items-center mb-2"
      >
        <div class="d-flex gap-2 align-items-center">
          @if(title){
          <h3 class="m-0">{{ title | translate }}</h3>
          @if(badgeAnnotation){
          <span
            class="tag tag-sm bg-primary border border-primary"
            style="--bs-bg-opacity: 0.2"
          >
            {{ badgeAnnotation }}
          </span>
          } }
        </div>

        @if(exportable || selectableColumns) {
        <div class="d-flex gap-2">
          @if (exportable) {
          <button
            class="bg-transparent order-0 d-flex fw-bold align-items-center gap-2 w-100"
            style="white-space: nowrap"
            (click)="exportCSV($event)"
          >
            Export in CSV
            <svg
              aria-hidden="true"
              width="24"
              height="24"
              data-prefix="fas"
              data-icon="file-csv"
              class="svg-inline--fa fa-file-csv fa-2x"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M0 64C0 28.7 28.7 0 64 0h160v128c0 17.7 14.3 32 32 32h128v144H176c-35.3 0-64 28.7-64 64v144H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0l128 128zM200 352h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40h-16c-22.1 0-40-17.9-40-40v-80c0-22.1 17.9-40 40-40zm133.1 0H368c8.8 0 16 7.2 16 16s-7.2 16-16 16h-34.9c-7.2 0-13.1 5.9-13.1 13.1 0 5.2 3 9.9 7.8 12l37.4 16.6c16.3 7.2 26.8 23.4 26.8 41.2 0 24.9-20.2 45.1-45.1 45.1H304c-8.8 0-16-7.2-16-16s7.2-16 16-16h42.9c7.2 0 13.1-5.9 13.1-13.1 0-5.2-3-9.9-7.8-12l-37.4-16.6c-16.3-7.2-26.8-23.4-26.8-41.2 0-24.9 20.2-45.1 45.1-45.1zm98.9 0c8.8 0 16 7.2 16 16v31.6c0 23 5.5 45.6 16 66 10.5-20.3 16-42.9 16-66V368c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V368c0-8.8 7.2-16 16-16z"
              />
            </svg>
          </button>
          } @if(selectableColumns){
          <button
            disabled
            class="btn d-flex justify-content-between dropdown-toggle w-100"
            type="button"
            id="dropdownTable"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTable"
            aria-expanded="false"
            aria-controls="dropdownTable"
          >
            Table Options
          </button>
          <ul
            id="collapseTable"
            class="dropdown-menu"
            aria-labelledby="dropdownTable"
          >
            <li>
              <a class="dropdown-item" href="#">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="opened"
                    checked
                  />
                  <label class="form-check-label" for="opened">Priority</label>
                </div>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="closed"
                    checked
                  />
                  <label class="form-check-label" for="closed">Subject</label>
                </div>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="closed"
                    checked
                  />
                  <label class="form-check-label" for="closed">Owner</label>
                </div>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="closed"
                    checked
                  />
                  <label class="form-check-label" for="closed">Type</label>
                </div>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="closed"
                    checked
                  />
                  <label class="form-check-label" for="closed"
                    >Case number</label
                  >
                </div>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="closed"
                    checked
                  />
                  <label class="form-check-label" for="closed">Created</label>
                </div>
              </a>
            </li>
          </ul>
          }
        </div>
        }
      </div>
      }
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <caption class="d-none">
            {{
              title
            }}
          </caption>
          <thead>
            <tr>
              @if (!main) {
              <th scope="col" class="col-1">
                {{ 'pages.generic.flag' | translate }}
              </th>
              }
              <th scope="col" class="col-1">
                <button
                  class="d-flex gap-2 border-0 bg-transparent fw-bold align-items-center"
                  (click)="sortResults($event, 'priority')"
                >
                  <span class="mr-2">{{
                    'pages.support.dashboard.fieldTitles.priority' | translate
                  }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#000000"
                    class="svg-inline--fa fa-user"
                    viewBox="0 0 1000 1000"
                  >
                    <path
                      d="M950 300H825v250a75 75 0 0 1-75 75H600a75 75 0 0 1-75-75V300H400L631.09 68.09a62.336 62.336 0 0 1 87.82 0ZM600 700 368.91 931.91a62.337 62.337 0 0 1-87.82 0L50 700h125V450a75 75 0 0 1 75-75h150a75 75 0 0 1 75 75v250z"
                      style="fill-rule: evenodd"
                      fill="#000000"
                    ></path>
                  </svg>
                </button>
              </th>
              <th scope="col" class="col-4">
                {{ 'pages.support.dashboard.fieldTitles.subject' | translate }}
              </th>
              <th scope="col" class="col-1">
                {{
                  'pages.cybersocxdr.dashboard.fieldTitles.incidentOwner'
                    | translate
                }}
              </th>
              <th scope="col" class="col-2">
                {{ 'pages.support.dashboard.fieldTitles.type' | translate }}
              </th>
              <th scope="col" class="col-1">
                {{
                  'pages.support.dashboard.fieldTitles.reference' | translate
                }}
              </th>
              <th scope="col" class="col-1">
                <button
                  class="d-flex gap-2 border-0 bg-transparent fw-bold align-items-center"
                  (click)="sortResults($event, 'createdDate')"
                >
                  <span class="mr-2">{{
                    'pages.support.dashboard.fieldTitles.created' | translate
                  }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#000000"
                    class="svg-inline--fa fa-user"
                    viewBox="0 0 1000 1000"
                  >
                    <path
                      d="M950 300H825v250a75 75 0 0 1-75 75H600a75 75 0 0 1-75-75V300H400L631.09 68.09a62.336 62.336 0 0 1 87.82 0ZM600 700 368.91 931.91a62.337 62.337 0 0 1-87.82 0L50 700h125V450a75 75 0 0 1 75-75h150a75 75 0 0 1 75 75v250z"
                      style="fill-rule: evenodd"
                      fill="#000000"
                    ></path>
                  </svg>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cc of cases">
              @if(!main){
              <td class="align-middle">
                <button
                  type="button"
                  [ngClass]="[
                    'p-0',
                    'border-0',
                    'flag',
                    cc.isFlagged ? 'bg-primary' : 'bg-light'
                  ]"
                  style="width: 40px; height: 40px; border-radius: 100px"
                  (click)="flagCase($event, cc)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#000000"
                    class="svg-inline"
                    viewBox="0 0 1000 1000"
                  >
                    <path
                      d="M888 275H588a75 75 0 0 1-75-75 75 75 0 0 0-75-75H188v-12.5A37.5 37.5 0 0 0 150.5 75a37.5 37.5 0 0 0-37.5 37.5V925h75V525h225a75 75 0 0 1 75 75 75 75 0 0 0 75 75h325L700.5 475Z"
                      style="fill-rule: evenodd"
                      fill="#000000"
                    ></path>
                  </svg>
                </button>
              </td>
              }
              <th scope="row" class="align-middle">
                <span
                  class="py-1 px-2 rounded-pill"
                  [ngClass]="'P' + cc.severity | lowercase | removeWhiteSpace"
                  >{{ cc.severity | severity | translate }}</span
                >
              </th>
              <td class="align-middle">
                <a
                  [routerLink]="['/xtended-soc/incident', cc.id]"
                  class="text-decoration-none"
                  >{{ cc.subject | anonymise }}</a
                >
              </td>
              <td class="align-middle" *ngIf="cc.owner && cc.owner.length > 1">
                {{ cc.owner | anonymise }}
              </td>
              <td class="align-middle" *ngIf="!cc.owner && cc.owner.length < 1">
                N/A
              </td>
              <td class="align-middle">{{ cc.category }}</td>
              <td class="align-middle">{{ cc.id }}</td>
              <td class="align-middle">
                {{ cc.createdDate | date : 'dd/MM/yyyy HH:mm' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
